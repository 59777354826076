.checkout-index-index .payment-method {
    .fields.group-2 {
        display: flex;
        justify-content: space-between;
    }

    .field.month,
    .field.year {
        flex: 1;
        max-width: 48%;
    }

    .payment-method-content .actions-toolbar {
        margin-left: auto;
    }
}
