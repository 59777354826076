/**
* Baker Distributing
*/


// **********************************************
// [todo]
// This rainbow is incomplete.
// Consider dropping this and use the "color-library" which covers the full spectrum.
// **********************************************


// ==============================================
// Complete Color Palette
// ==============================================

$red-700            : #ea1336;
$red-800            : #c8102e;
$red-900            : #a60d26;

$blue-50            : #eef4fe;
$blue-100           : #ddeafc;
$blue-200           : #b6d4ff;
$blue-300           : #99c0f7;
$blue-400           : #6ca9ff;
$blue-500           : #5596f1;
$blue-600           : #3380ef;
$blue-700           : #136cea;
$blue-800           : #105cc8;
$blue-900           : #0d4ca6;

//$blue-50            : #f4f4f4;
//$blue-100           : #eceff1;
//$blue-200           : #cfd8dc;
//$blue-300           : #b0bec5;
//$blue-400           : #90a4ae;
//$blue-500           : #78909c;
//$blue-600           : #607d8b;
//$blue-700           : #546e7a;
//$blue-800           : #455a64;
//$blue-900           : #3a3838;

$gray-50            : #f4f4f4;
$gray-75            : #f6f6f6;
$gray-100           : #e9e9e9;
$gray-150           : #fafafa;
$gray-200           : #d3d3d3;
$gray-300           : #bebcbc;
$gray-350           : #c3c3c3;
$gray-400           : #a8a6a6;
$gray-500           : #929090;
$gray-600           : #8c8c8c;
$gray-650           : #656565;
$gray-700           : #7c7a7a;
$gray-800           : #504e4e;
$gray-900           : #3a3838;

//GRAY BLUE
$grayblue-50            : #f4f4f4;
$grayblue-100           : #eceff1;
$grayblue-200           : #cfd8dc;
$grayblue-300           : #b0bec5;
$grayblue-400           : #90a4ae;
$grayblue-500           : #78909c;
$grayblue-600           : #607d8b;
$grayblue-700           : #546e7a;
$grayblue-800           : #455a64;
$gray-850               : #3B3B3B;
$grayblue-900           : #3a3838;

//DARK BLUE
$materialblue-50            : #e3f2fd;
$materialblue-100           : #bbdefb;
$materialblue-200           : #90caf9;
$materialblue-300           : #64b5f6;
$materialblue-400           : #42a5f5;
$materialblue-500           : #2196f3;
$materialblue-600           : #1e88e5;
$materialblue-700           : #1976d2;
$materialblue-800           : #1565c0;
$materialblue-900           : #0d47a1;

//PEIRCE PHELPS BLUE
$peirceblue-50               : #e1e6ed;
$peirceblue-100              : #c0cee2;
$peirceblue-200              : #8ea9cf;
$peirceblue-300              : #6c96d2;
$peirceblue-400              : #457ecd;
$peirceblue-500              : #225cad;
$peirceblue-600              : #104595;
$peirceblue-700              : #09397c;
$peirceblue-800              : #012d6b; // website main color
$peirceblue-900              : #0a2159;

//MATERIAL GREEN
$materialgreen-50            : #e8f5e9;
$materialgreen-100           : #c8e6c9;
$materialgreen-200           : #a5d6a7;
$materialgreen-300           : #81c784;
$materialgreen-400           : #66bb6a;
$materialgreen-500           : #4caf50;
$materialgreen-600           : #43a047;
$materialgreen-700           : #388e3c;
$materialgreen-800           : #2e7d32;
$materialgreen-900           : #1b5e20;

$green-200          : #70D058;
$green-400          : #32bc61;
$green-500          : #0eb045;
$green-600          : #0ca93e;
$green-700          : #1F7E0C;
