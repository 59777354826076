// ==============================================
// Vars
// ==============================================

$bp-table-scroll-l: $bp-screen-l !default;
$bp-table-scroll-base: $bp-screen-m !default;
$table-scrollbar-margin-bottom: 10px !default;


// ==============================================
// Table Scroll Wrap
// ==============================================

.table-scroll-outer-wrap {
    position: relative;
}

@media (min-width: $bp-screen-m) {
    .table-scroll-outer-wrap .table-scroll-wrap--visible:after {
        right: 5px;
        top: $space-sl;
    }
}

@media (max-width: $bp-screen-m - 1) {
    .table-scroll-outer-wrap .table-scroll-wrap--visible:after {
        right: 5px;
        top: $space-sl;
    }
}

.table-scroll-wrap {
    margin-bottom: $gap;
    overflow-x: auto;
}

.table-scroll-wrap table {
    margin-bottom: $table-scrollbar-margin-bottom;
}

.table-scroll-wrap--visible{
    border-right: 16px solid $c-border-light;
}

.table-scroll-wrap--visible:after {
    @include triangle($direction: right, $color: $c-icon-base);
    top: 48%;
}

@media (min-width: $bp-screen-m) {
    .table-scroll-wrap--visible:after {
        right: $space-xl;
    }
}

@media (max-width: $bp-screen-m - 1) {
    .table-scroll-wrap--visible:after {
        right: $space-xxs;
    }
}

@media (max-width: $bp-table-scroll-base - 1px) {
    .table-scroll-wrap {
        overflow-x: auto;
    }

    .table-scroll-wrap td,
    .table-scroll-wrap th {
        white-space: nowrap;
    }
}

@media (max-width: $bp-table-scroll-l - 1px) {
    .table-scroll-wrap--l {
        overflow-x: auto;
    }

    .table-scroll-wrap--l td,
    .table-scroll-wrap--l th {
        white-space: nowrap;
    }
}
