// ==============================================
// Price Box
// ==============================================

.price-box .price {
    @include price();
    white-space: pre-wrap;
}

.price .pricing-permission-deny {
    color: $peirceblue-400;
    font-weight: $font-weight-normal;
}

// ==============================================
// Price Including/Excluding Tax
// ==============================================

// GravDept: [todo]
// Can possibly comment out if Baker doesn't show tax inline.

.price-including-tax,
.price-excluding-tax {
    display: block;
    @include price();
}

.price-including-tax .cart-tax-total,
.price-excluding-tax .cart-tax-total {
    position: relative;
    padding-right: $space-s;
    cursor: pointer;

    @include lib-icon-font(
        $icon-down,
        $_icon-font-size        : 26px,
        $_icon-font-line-height : 10px,
        $_icon-font-margin      : 3px 0 0 0,
        $_icon-font-position    : after
    );

    &::after {
        position: absolute;
        right: -$space-base;
        top: 3px;
    }

    &-expanded {
        @include lib-icon-font-symbol(
            $_icon-font-content  : $icon-up,
            $_icon-font-position : after
        );
    }
}

.price-including-tax .cart-tax-total-expanded,
.price-excluding-tax .cart-tax-total-expanded {
    @include lib-icon-font-symbol(
        $_icon-font-content  : $icon-up,
        $_icon-font-position : after
    );
}

.price-including-tax + .price-excluding-tax,
.weee[data-label] {
    display: block;
    font-size: $font-size-xl;

    &::before {
        content: attr(data-label) ': ';
        font-size: $font-size-xxs;
    }
}

.price-including-tax + .price-excluding-tax .price,
.weee[data-label] .price {
    font-size: $font-size-xxs;
}


// ==============================================
// Price Tier
// ==============================================

.price-tier_price .price-excluding-tax,
.price-tier_price .price-including-tax {
    display: inline;
}


// ==============================================
// Old Price
// ==============================================

.old-price,
.old.price {
    text-decoration: line-through;
}


// ==============================================
// Price restriction message
// ==============================================

.price-message {
    font-size: $font-size-s;
    white-space: normal;
}

.price-message.restriction-message {
    color: $c-red;
}

// ==============================================
// Price when there is a discount
// ==============================================

.price-discount {
    font-weight: 400;
    font-size: $font-size-base;
    color: $c-error;
    margin-bottom: $space-xs;
}

// ==============================================
// Preseason Pricing Tooltip
// ==============================================

.tooltip.tooltip--preseason_non_qualified {
    top: $space-xxs;
    left: $space-xxs;
}

.algolia-product-list .tooltip.tooltip--preseason_non_qualified {
    top: $space-xxxs;
    left: 0;
}

// PDP pricing
.catalog-product-view {
    .old-price {
        position: relative;
    }

    .old-price .tooltip {
        position: absolute;
        top: -$space-xs;
        left: 95%;
    }
}

@media (max-width: $bp-screen-xs - 1px) {
    .catalog-product-view {
        .old-price .tooltip:after {
            left: 300%;
        }
    }
}

// PDP Ahri Builder pricing
@media (min-width: $bp-screen-m) {
    .catalog-product-view {
        .ahri-table-content .old-price .tooltip {
            left: auto;
            top: -1px;
            right: 10px;
        }
    }
}

@media (max-width: $bp-screen-xs - 1px) {
    .catalog-product-view {
        .ahri-table-content .old-price .tooltip {
            top: -3px;
        }
    }
}

// Parts List
.parts .part-row,
.parts .supersede-row {
    .price {
        flex-direction: column;
        display: flex;
    }

    .old-price {
        align-self: flex-start;
        margin-top: $space-xs;
    }

    .old-price .tooltip {
        top: -$space-xxs;
    }
}

@media (max-width: $bp-screen-xs - 1px) {
    .parts .part-row,
    .parts .supersede-row {
        .price {
            padding-top: $space-xxs;
        }
    }
}

// Ahri Search Suggestions
.search-unit-item {
    .price {
        flex-direction: column;
        display: flex;
    }

    .old-price {
        align-self: flex-start;
        margin-top: $space-xs;
    }
}

// Accessories
.accessory-table .accessory-row .old-price {
    align-self: flex-start;

    .tooltip {
        top: -$space-xxs;
    }
}

// CMS Block pricing
.price.flex .old-price {
    align-items: center;
    display: flex;

    .tooltip {
        top: 0;
    }
}

// My List pricing
.product-price .inline-flex .old-price {
    align-items: center;
    display: flex;

    .tooltip {
        top: 0;
    }
}

// PLP product pricing.

.product-item-details {
    .price-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }
}
