// ==============================================
// Vars
// ==============================================

$sidebar-width: 200px;
$page-sidebar-width: 270px;
$bp-product-view-heading-stack: 700px;
$sidebar-divider-padding: 80px;

// ==============================================
// Column . Main
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .catalog-product-view .column.main {
        display: flex;
        flex-direction: column;
    }

}


// ==============================================
// Product View - Col 1
// ==============================================

.product-view_col1 {
    flex: 1 1 auto;
}

.product-view_col1 .product-attribute {
    display: inline-block;
    line-height: 2;
}

.product-view_col1 .product-attribute .mfg {
    margin-left: $space-l;
}

.product-view_col1 .page-title-wrapper .page-title {
    margin: $space-base 0 ($space-xxs + $space-xxxs);
}

.product-view_col1 .product.photo .photo.image {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
}

.product-view_col1 .placeholder .photo.container {
    max-width: 100%;
}

.product-view_col1 .notice {
    margin: $space-base 0;
    color: $c-text-muted;
    font-size: $font-size-xs;
}

.product-view_col1 .product.thumbs {
    margin: $space-l 0 $space-xxl;
}

.product-view_col1 .items.thumbs > li {
    display: inline-block;
    vertical-align: top;
}

.product-view_col1 .items.thumbs .active {
    display: block;
    line-height: 1;
}

.product-view_col2 .price-message > a {
    color: $c-red;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .product-view_col1 {
        order: -1;
    }

}


// ==============================================
// Product View
// ==============================================

.product-view {
    display: flex;
    flex-wrap: wrap;
    max-width: 1280px;
    margin: 0 auto;
}

// ----------------------------------------------

@media (min-width: $bp-product-view-heading-stack) {
    .product-view {
        flex-wrap: nowrap;
        margin-bottom: 20px;
    }
}

// ==============================================
// Product View - Inventory
// ==============================================

.product-view .inventory {
    height: 120px;
}

// ==============================================
// Product View - Social Links
// ==============================================

.product-social-links {
    border-bottom: 1px solid $gray-50;
    padding: $space-xl 0;
    margin: $space-s 0;
}

.product-social-links .menu_toggle {
    color: $c-text;
    font-weight: $font-weight-semibold;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .product-add-form {
        margin: 0;
    }

    .catalog-product-view {
        .product-info-visibility {
            min-height: 350px;
        }
    }
}


// ==============================================
// Product . Attribute . Overview
// ==============================================

.product.attribute.overview {
    margin-bottom: $space-base;
    padding-bottom: 10px;
    border-bottom: 1px solid $c-border;
}


// ==============================================
// Product Info Main
// ==============================================

.product-view_col2 {
    flex: 1 0 285px;
}

.product-attribute {
    display: block;
    width: 100%;
}

.product-attribute--inline {
    display: inline-block;
}

.product-attribute_value {
    display: inherit;
    word-break: break-all;
    margin-right: $space-base;
    &:last-child {
        margin-right: 0;
    }
}

.product-attribute_value.short_description,
.product-attribute_value.description {
    word-break: break-word;
}

.product-attribute_value.short_description {
    margin-top: $space-xl;
}

.product-view_col2 .product.alert {
    margin: $space-base 0;
}

// ----------------------------------------------

@media (min-width: $bp-screen-s) {

    .product-view_col2 {
        padding-left: $space-base;
    }

}


// ==============================================
// Product Details
// ==============================================

.product-details {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
}


// ==============================================
// Product Details Sidebar
// ==============================================

.catalog-product-view .content-wrapper {
    height: auto;
}

.product-details_sidebar {
    position: sticky;
    top: 16px;
    float: right;
    width: $sidebar-width;
    background: $c-white;
    box-shadow: $box-shadow-thin;
    padding: $space-base;
}

.product-details_sidebar--ie {
    position: absolute;
    right: 0;
    float: none;
}


@media (max-width: $bp-screen-m - 1px) {
    .product-details_sidebar {
        display: none;
    }
}

.product-details_sidebar a {
    display: block;
    padding: $space-xxs 0;
}


@media (min-width: $bp-screen-m) {
    .catalog-product-view {
        .product-view_col1 {
            display: flex;
            float: left;
            width: calc(100% - #{$page-sidebar-width});
        }

        .product-view_col2 {
            height: 100%;
            float: right;
            width: $page-sidebar-width;
        }

        .content-wrapper {
            float: left;
            width: calc(100% - #{$page-sidebar-width});
        }

        .product-info-sidebar {
            display: flex;
            flex-direction: column;
            position: sticky;
            top: 0;

            &:before {
                background: $c-background;
                content : "";
                height: calc(100% - 2 * #{$sidebar-divider-padding});
                position: absolute;
                left: -$space-xl;
                top: $sidebar-divider-padding;
                width: 1px;
            }
        }

        .product-info-sidebar h4 {
            font-weight: $font-weight-semibold;
        }

        .product-info-sidebar .product-info-main {
            margin-bottom: $space-base;
        }

        .product-attributes {
            padding: 0 $space-base;
        }

        .page-title > span {
            font-size: $font-size-xxl;
            font-weight: $font-weight-normal;
        }

        .std.description {
            display: flex;
            max-width: none;
            width: 100%;
        }

        .std.description > div {
            max-width: 45rem;
        }

        .std.description .product-attribute {
            padding-right: $space-l;
        }
    }
}


@media (max-width: $bp-screen-m - 1px) {

    .catalog-product-view {
        // Sidebar bottom section links block.
        .product-info-sidebar .product-details-links {
            display: none;
        }
    }

}

// PDP Sidebar bottom section links.

@media (min-width: $bp-screen-m) {
    .product-info-sidebar .product-details-links {
        margin-top: auto;
        padding-bottom: $space-base;
        line-height: 1.7;
    }

    .product-info-sidebar .product-details-links > li {
        @include flat-button;
        @include button-style (
            $background         : $c-background,
            $background--hover  : $c-lightgray,
            $background--active : $c-lightgray
        );

        margin: $space-xxs 0;
        width: 100%;

        > a {
            color: $c-text;
        }

        > a:hover {
            text-decoration: none;
        }
    }
}

// ==============================================
// Product Options Wrapper
// ==============================================

.product-options-wrapper .fieldset-product-options-inner .legend {
    display: inline-block;
    float: none;
    margin: 0 0 $space-xs;
    padding: 0;
    border: 0;
    font-weight: $font-weight-bold;
}

//  Date & Time custom option (Affect Time that goes only after Date)
.product-options-wrapper .fieldset-product-options-inner input.datetime-picker ~ select.datetime-picker {
    margin-top: $space-base;
}

.product-options-wrapper .fieldset-product-options-inner.required .legend,
.product-options-wrapper .fieldset-product-options-inner._required .legend {
    &::after {
        content: '*';
        margin: $form-field-label-asterisk__margin;
    }
}

.product-options-wrapper .field .note {
    display: block;
}

.product-options-wrapper .field .price-notice {
    @extend .abs-adjustment-incl-excl-tax;
}


// ==============================================
// Box To Cart
// ==============================================

.product-view_col2 .box-tocart .fieldset,
.product-options-bottom .box-tocart .fieldset {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.product-view_col2 .box-tocart .field.qty,
.product-options-bottom .box-tocart .field.qty {
    padding-right: 0.75 * $space-l;
    flex: 0 0 50px;
    margin-top: 0px;
}

.product-view_col2 .box-tocart .input-text.qty,
.product-options-bottom .box-tocart .input-text.qty {
    text-align: center;
    width: 50px;
}

.product-view_col2 .box-tocart .actions,
.product-options-bottom .box-tocart .actions {
    text-align: left;
    flex: 1 1 auto;
    margin-top: $space-xxl;
}

.product-view_col2 .box-tocart > .fieldset {
    display: flex;
    flex-wrap: wrap;

    .product-info-price {
        display: flex;
        flex-direction: column;
        height: 56px;
        order: 1;

        [title=Each] {
            margin-left: $space-xxxs;
        }

        .item-uom {
            align-items: flex-end;
            display: flex;
            line-height: $font-size-xxs;
            margin-right: $space-s;
        }

        [data-price-type=finalPrice] {
            display: flex;
            flex-direction: column;
        }
    }

    .product-info-price .price-label,
    .product-info-price .price-wrapper {
        color: $c-text;
    }

    .product-info-price .price-label {
        font-weight: $font-weight-semibold;
    }

    .product-info-price .price-wrapper {
        margin-top: auto;
        align-items: center;
    }

    .product-info-price .price-wrapper .price-box .price {
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        margin-top: auto;
    }

    .qty {
        order: 2;
    }

    .qty .input-text ~ label {
        color: $c-text;
        font-size: $font-size-base;
        top: -13px;
    }

    .actions {
        order: 3;
        width: 100%;
    }

    .actions .action.tocart {
        width: 100%;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .product-view_col2 {
        position: relative;
    }

    .product-view_col2 .box-tocart .actions {
        text-align: center;
        vertical-align: bottom;
    }

    .product-view_col2 .box-tocart,
    .product-options-bottom .box-tocart {
        display: table;
    }

    .product-view_col2 .box-tocart .field.qty,
    .product-options-bottom .box-tocart .field.qty {
        display: table-cell;
    }

    .product-view_col2 .box-tocart .actions,
    .product-options-bottom .box-tocart .actions {
        display: table-cell;
        text-align: center;
        vertical-align: bottom;
    }

}


// ==============================================
// Block Related
// ==============================================

.block.related .action.select {
    margin: 0 $space-xs;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .block.related .action.select {
        display: block;
        margin: $space-xs 0;
    }

}


// ==============================================
// PDP Sidebar
// ==============================================

.sidebar .product-items .product-item {
    margin-bottom: $space-l;
    position: relative;
}

.sidebar .product-items .product-item-info {
    position: relative;
    width: auto;
}

.sidebar .product-items .product-item-info .product-item-photo {
    left: 0;
    position: absolute;
    top: 0;
}

.sidebar .product-items .product-item-name {
    margin-top: 0;
}

.sidebar .product-items .product-item-details {
    margin: 0 0 0 85px;
}

.sidebar .product-items .product-item-actions {
    display: block;
    margin-top: $space-base;
}

.sidebar .product-items .price-box {
    display: block;
    margin: 7px 0;
}

.sidebar .product-items .text {
    margin-right: 8px;
}

.sidebar .product-items .counter {
    color: $c-primary;
    font-size: $font-size-xs;
    white-space: nowrap;
}

.sidebar .product-items .minilist .price {
    display: inline;
    padding: 0;
}

.sidebar .product-items .minilist .weee::before {
    display: inline-block;
}

.sidebar .action.delete {
    position: absolute;
    right: 0;
    top: 0;
}

.sidebar .subtitle {
    display: none;
}

// ----------------------------------------------
//  Product images only

.sidebar .product-items-images {
    @include lib-clearfix;
    margin-left: -$space-xs;
}

.sidebar .product-items-images .product-item {
    float: left;
    padding-left: $space-xs;
}

// ----------------------------------------------
// Product names only

.sidebar .product-items-names .product-item {
    margin-bottom: $space-base;
}

.sidebar .product-items-names .product-item-name {
    margin: 0;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .sidebar .product-items .product-item-info .product-item-photo {
        float: left;
        left: auto;
        margin: 0 $space-base $space-base 0;
        position: relative;
        top: auto;
    }

    .sidebar .product-items .product-item-details {
        margin: 0;
    }

    .sidebar .product-items .product-item-actions {
        clear: left;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-xxl) {

    .sidebar .product-items .product-item-info .product-item-photo {
        float: none;
        left: 0;
        margin: 0;
        position: absolute;
        top: 0;
    }

    .sidebar .product-items .product-item-details {
        margin-left: 85px;
    }

}

.additional-attributes th {
    white-space: normal;
}

// ==============================================
// Scene7 Image Viewer
// ==============================================

@media (max-width: $bp-screen-m - 1px) {
    .imageviewer\.app {
        margin: auto;
        min-width: 345px;
    }
}

// ==============================================
// Product Info Details
// ==============================================

@media (min-width: $bp-screen-l) {
    .catalog-product-view {
        .product.data.items {
            @include lib-data-tabs(
                $_tab-content-border: 1px solid $gray-75,
                $_tab-content-padding-bottom: 2 * $space-base,
                $_tab-content-padding-left: $space-base,
                $_tab-content-padding-right: $space-base,
                $_tab-content-padding-top: 2 * $space-base,
                $_tab-control-background-color: $gray-75,
                $_tab-control-background-color-active: $c-white,
                $_tab-control-border-bottom-color-active: $c-white,
                $_tab-control-border-color: $gray-75,
                $_tab-control-border-width: 1px,
                $_tab-control-color-active: $c-text,
                $_tab-control-color-hover: $c-text,
                $_tab-control-line-height: 24px,
                $_tab-control-padding-right: $tab-control__padding-right,
                $_tab-control-padding-left: $tab-control__padding-left
            );
        }

        .data.item.title {
            min-width: 120px;
        }
    }
}

@media (min-width: $bp-screen-m) and (max-width: $bp-screen-l - 1px) {
    .catalog-product-view {
        .product.data.items {
            @include lib-data-tabs(
                $_tab-content-border: 1px solid $gray-75,
                $_tab-content-padding-bottom: 2 * $space-base,
                $_tab-content-padding-left: $space-xs,
                $_tab-content-padding-right: $space-xs,
                $_tab-content-padding-top: 2 * $space-base,
                $_tab-control-background-color: $gray-75,
                $_tab-control-background-color-active: $c-white,
                $_tab-control-border-bottom-color-active: $c-white,
                $_tab-control-border-color: $gray-75,
                $_tab-control-border-width: 1px,
                $_tab-control-color-active: $c-text,
                $_tab-control-color-hover: $c-text,
                $_tab-control-line-height: 24px,
                $_tab-control-padding-right: $space-s,
                $_tab-control-padding-left: $space-s
            );
        }
    }
}

@media (min-width: $bp-screen-m) {
    .catalog-product-view {
        .product.data.items {
            z-index: 1;
        }

        .product.data.items > .data.item.title {
            height: 50px;
        }

        .data.item.title {
            border: 1px solid $gray-75;
            border-left-color: $gray-400;
            border-bottom: none;
            text-align: center;
            text-transform: capitalize;

            &.active {
                position: relative;
                background: $c-white;
            }

            h2 {
                line-height: 18px !important;
            }

            &:first-of-type {
                border-left-color: $gray-75;
            }
        }

        .data.item.content.attribute-product\.info\.description > div {
            display: inline-block;
            vertical-align: top;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .catalog-product-view {
        .product.data.items {
            @include lib-data-accordion(
                $_accordion-content-margin: 0,
                $_accordion-control-background-color: $c-background,
                $_accordion-control-background-color-active: $c-background,
                $_accordion-control-background-color-hover: $c-lightgray,
                $_accordion-control-border-bottom: 1px solid $c-gray,
                $_accordion-control-border-top: none,
                $_accordion-control-height: 50px,
                $_accordion-control-line-height: 34px,
                $_accordion-control-margin-bottom: 0,
                $_accordion-control-padding-left: 45px
            );
            z-index: 1;
            margin: 15px;

            .data.switch {
                height: 100%;
            }
        }

        .switch {
            font-family: inherit !important;
            border-bottom: 1px solid $gray-400 !important;
            border-left: 1px solid $gray-400 !important;
            border-right: 1px solid $gray-400 !important;
            font-size: 14px !important;
            color: $gray-800 !important;
        }

        .data.item.title {
            position: relative;

            &:first-of-type {
                border-top: 1px solid $gray-400;
            }

            &:before {
                position: absolute;
                top: 12px;
                left: 10px;
            }

            @include lib-icon-font(
                $_icon-font-content: $icon-add,
                $_icon-font-size: 25px,
                $_icon-font-line-height: 25px,
                $_icon-font-color: $c-darkgray
            );

            &.ui-state-active {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-minus
                );
            }

            h2 {
                margin-bottom: 0;
            }
        }

        .data.item.content {
            height: auto !important;
            border-bottom: 1px solid $gray-400 !important;
            border-left: 1px solid $gray-400 !important;
            border-right: 1px solid $gray-400 !important;
        }
    }
}
