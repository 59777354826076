@import 'slick/slick';
@import 'content-type/import';
@import 'component/import';

.cms-content-important {
    background-color: $c-white;
    color: $c-gray;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.1;
    margin: 0 0 35px -20px;
    padding: 20px;
}

.pagebuilder-full-width {
    float: left;
    width: 100%;
}

.pagebuilder-content-type {
    box-sizing: border-box;
    margin-bottom: 20px;
}

//
//  Responsive Classes
//  _____________________________________________

@media all and (min-width: $bp-screen-m) {
    .pagebuilder-mobile-only {
        display: none !important;
    }
}

@media all and (max-width: $bp-screen-m - 1px) {
    .pagebuilder-mobile-hidden {
        display: none !important;
    }
}

