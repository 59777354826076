// ==============================================
// Vars
// ==============================================

$header-icons-color:       $c-white;


// ==============================================
// Page Header
// ==============================================

.page-header {
    position: relative;
    z-index: 12;
    background-color: $c-header-background;
}

.secondary-nav-divider {
    margin-bottom: 0;
}

.customer-support-phone {
    font-weight: $font-weight-bold;
    margin-left: $space-xxs;
}

// IE11 styles
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .secondary-nav-divider {
        display: none;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .customer-support-phone {
        font-weight: $font-weight-bold;
        margin-left: 0;
    }

    .page-header {
        display: flex;
    }
}

@media (min-width: $bp-screen-m) {
    .form.minisearch {
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: $space-base;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .form.minisearch {
        max-width: none;
    }
}

.quote-dropdown-content,
.quicklist-dropdown-content {
    padding: 16px;
}

.quote-dropdown-content:before,
.quicklist-dropdown-content:before {
    @include triangle($direction: up, $size: 8px, $color: $c-border);
    top: -8px;
    right: 22px;
}

.quote-dropdown-content:after,
.quicklist-dropdown-content:after {
    @include triangle($direction: up, $size: 6px, $color: $c-white);
    top: -6px;
    right: 24px;
}

.header-quotes_item,
.header-quicklists_item {
    margin-bottom: 8px;
}

.header-quicklists_item > a {
    display: block;
    width: 100%;
}

@media (min-width: $bp-screen-m) {
    .customer-support-phone.icon-phone-outlined:before {
        content: '';
    }

    .header-links-signin > a,
    .header-links-quotes > a,
    .header-links-quicklists > a {
        color: $c-header-text-color;

        &:hover,
        &:focus,
        &:active {
            color: $c-header-text-color;
        }
    }

    .header-links-quicklists .dropdown-trigger:after {
        font-size: $font-size-xl;
        line-height: 19px;
        position: relative;
        right: $space-xxxs;
    }

    .secondary-nav {
        display: flex;
        font-size: $font-size-xs;
        margin: $space-l $space-xxxs $space-l;
        padding: 0;

        .shipping-method-switcher {
            margin-left: $space-xxl;
        }

        .header-shipping-method {
            order: 3;
        }

        .header-store {
            margin-left: auto;
            order: 2;
        }

        .header.links {
            display: flex;
            order: 1;
        }

        .header.links a:hover {
            font-weight: $font-weight-semibold;
            text-decoration: none;
        }

        .header-store_dropdown.dropdown-content {
            font-size: $font-size-base;
        }
    }

    .secondary-nav .header.links {
        .nav.item,
        .header-links-quicklists,
        .header-links-quotes {
            margin-left: $space-base;

            &:first-child {
                margin-left: 0;
            }
        }

        a {
            color: $c-text;
        }
    }

    .full-width {
        width: calc(100vw - #{$space-xs});
        position: relative;
        margin-left: -50vw;
        left: 50%;
        box-sizing: border-box;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .header.sign-in {
        display: none;
    }

    .secondary-nav,
    .secondary-nav-divider {
        display: none;
    }

    .secondary-nav-mobile {
        border-top: 1px solid $gray-200;
        padding: $space-xs 0;
    }

    .nav-sections .customer-support {
        padding: $space-base $space-base $space-xs;
    }

    .customer-support .customer-support-label {
        display: none;
    }

    .customer-navigation .header.links {
        display: none;
    }

    .header-links-quotes .dropdown-trigger:after,
    .header-links-quicklists .dropdown-trigger:after {
        content: '';
    }

    .header-links-quotes.dropdown,
    .header-links-quicklists.dropdown {
        display: block;
    }

    .nav-sections-item-content .shipping-method-switcher {
        margin: 0 $space-base $space-s;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .page-header {
        border-bottom: 1px solid $c-hr;
        margin-bottom: $space-base;
        padding: 0 60px 0 60px;

        .cms-index-index & {
            margin-bottom: 0;
        }

        .block-search input {
            font-size: $font-size-l;
        }
    }

    .page-header .switcher {
        display: inline-block;
    }

}


// ==============================================
// Header Content
// ==============================================

.header.content {
    @include lib-clearfix;

    .widget.block.block-banners,
    .widget.block.block-banners-inline {
        width: 100%;
    }
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .header.content {
        display: flex;
        flex-wrap: wrap;
        margin-top: $space-xs;
        padding-top: $space-xs;
        padding-bottom: $space-xs;
        z-index: $z-index-10;
        flex-grow: 1;

        .action.nav-toggle {
            order: 1;
        }

        .logo-wrapper {
            height: 27px;
            order: 2;
        }

        .header-store {
            order: 3;
        }

        .customer-navigation {
            order: 4;
        }

        .block-search {
            order: 5;
        }

        .header-store_wrapper {
            height: 36px;
        }
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .header.content {
        @include lib-clearfix;
        align-items: center;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-top: $space-xl;
        width: 100%;
    }

}


// ==============================================
// Header Panel
// ==============================================

@media (min-width: $bp-screen-m) {

    .customer-navigation {
        display: flex;
        flex-wrap: nowrap;
        margin-left: $space-l;
        order: 7;
    }

    .customer-navigation > .header.links,
    .customer-navigation > .header.sign-in {
        margin: auto;
        padding: 0;
        list-style: none;
        display: inline-flex;
    }

    .customer-navigation > .header.links {
        order: 20;
    }

    .customer-navigation > .header.sign-in {
        background: $gray-50;
        order: 10;
        padding: $space-xs $space-base 7px $space-base;
    }

    .customer-navigation > .header.links > li:not(.header-links-bakerpay) {
        margin-bottom: 3px;
        margin-left: 25px;
    }

    .header-links-signin {
        display: flex;
        flex-direction: column;
        line-height: 1.2;
    }

    .header-links-signin a {
        color: $c-text;
    }

    .header-links-signin .links-row1 a {
        text-decoration: underline;
    }

    .header-links-signin .links-row2 a {
        font-weight: $font-weight-semibold;
    }

    .customer-navigation > .header.links > li.welcome,
    .customer-navigation > .header.links > li > a,
    .customer-navigation > .header.sign-in > li a {
        line-height: 1.4;

        &:hover {
            text-decoration: none;
        }
    }

}

// ==============================================
// Header Links - Baker Pay
// ==============================================

.header-links-bakerpay {
    margin-left: 25px;
    position: relative;
}

.header-links-bakerpay svg {
    width: 74px;
    margin-bottom: 0px;
}

.header-links-bakerpay_mobile svg {
    height: 20px;
    margin-bottom: -6px;
}

.header-bakerpay_flag {
    left: 50%;
    transform: translateX(-50%);
    bottom: -12px;
}

.header-bakerpay-mobile_flag {
    margin-left: 8px;
}


// ==============================================
// Header Links - Quick Lists
// ==============================================

@media (min-width: $bp-screen-m) {

    .header-links_quicklists {
        @include lib-icon-font(
            $_icon-font-content: $icon-quicklists,
            $_icon-font-color: $header-icons-color,
            $_icon-font-size: 20px
        );

        &::before {
            margin-top: -5px;
        }
    }
}


// ==============================================
// Header Links - Quotes
// ==============================================

@media (min-width: $bp-screen-m) {

    .header-links_quotes {
        @include lib-icon-font(
            $_icon-font-content: $icon-quotes,
            $_icon-font-color: $header-icons-color,
            $_icon-font-size: 20px
        );

        &::before {
            margin-top: -5px;
        }
    }
}

// ==============================================
// Header shipping method pill button
// ==============================================

@media (min-width: $bp-screen-m) {
    .page-header .pill-button {
        min-width: 143px;
    }
}
