.MuiFormControl-root {
    .MuiOutlinedInput-input {
        font-size: $font-size-sl;
        padding: $space-s;
    }

    .MuiOutlinedInput-root {
        border-radius: 0;
    }

    .MuiFormLabel-root {
        top: -$space-xxsl;

        &.MuiFormLabel-filled,
        &.Mui-focused {
            top: 0;
        }
    }

    .MuiFormLabel-root.Mui-focused {
        border-color: $c-library-blue-11;
        color: $c-library-blue-11;
    }

    .MuiFormHelperText-contained {
        margin-left: 0;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $c-library-blue-11;
        border-width: 1px;
    }
}

.search-input.MuiTextField-root {
    @include lib-icon-font(
        $icon-search,
        $_icon-font-color: $c-icon-base,
        $_icon-font-position: before,
        $_icon-font-size: $font-size-xxxl
    );

    &:before {
        left: $space-xxs;
        position: absolute;
        top: $space-s;
        z-index: 1;
    }

    .MuiInputBase-input {
        padding-left: $space-xxl;
    }

    .MuiFormLabel-root {
        left: $space-base;

        &.Mui-focused,
        &.MuiFormLabel-filled {
            left: 0;
        }
    }
}
