/**
* Baker Distributing
*/

$mobile-content-margin: 15px;

// ==============================================
// Page Footer
// ==============================================

.page-footer {
    margin-top: auto;
}


// ==============================================
// Footer Top
// ==============================================

.footer-top {
    position: relative;
    background: $gray-100;
    font-size: $font-size-l;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .footer-contact_separator {
        display: none;
    }

    .footer-contact_item,
    .footer-contact_label {
        display: block;
    }

    .footer-contact_label {
        font-size: $font-size-xs;
    }

}

// ==============================================
// Back Top
// ==============================================

.back-top {
    display: inline-block;
    position: absolute;
    right: $space-l;
    bottom: 0;
    width: 48px;
    height: 48px;
    margin-bottom: calc(50px / -2);
    background: $c-white;
    border-top: 1px solid $c-border;
    border-radius: 50px;
    box-shadow: 0 1px 2px black(0.15);
    text-decoration: none;
    cursor: pointer;
    transition: $transition-all;
    z-index: $z-back-top;

    &:hover {
        transform: scale(1.1);
    }

    @include lib-icon-font(
        $_icon-font-content: $icon-up,
        $_icon-font-color:   $gray-700
    );

    &::before {
        padding: $mobile-content-margin 13px;
    }
}


// ==============================================
// Footer Content
// ==============================================

.footer.content {
    background: $gray-75;
    position: relative;
    border-top: 1px solid $c-border;
}


// ==============================================
// Footer Nav
// ==============================================

.footer-nav-wrapper {
    background: $gray-75;
}

.footer-nav {
    max-width: $bp-screen-l;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
    border-bottom: 1px solid $c-border;
}

.footer-nav_item li {
    display: flex;
}

.footer-nav_item li span {
    margin: 0 $space-xxxs;
}

.footer-nav_item li.c-red {
    .footer-nav_link,
    span {
        color: $c-red;
    }
}

.footer-nav_link {
    display: block;
    margin-bottom: $space-xxs;
}

a.footer-nav_link {
    color: $c-text;
}

.footer-nav_heading {
    color: $c-text;
    font-weight: $font-weight-bold;
    margin-bottom: $space-s;
}

.social-link {
    display: flex;
    flex-wrap: nowrap;
}

.social-link_item {
    margin-right: $space-xxxs;
}

.social-link_item [role=img] {
    max-width: none;
}

.social-link_item path {
    fill-rule: evenodd;
    fill: $gray-900;
    transition: $transition-all;
}

.social-link_item:hover path {
    fill: $c-black;
    transition: $transition-all;
}

.app-link {
    display: flex;
    flex-direction: column;
}

.app-link_item {
    margin-bottom: $space-xxs;
    max-width: none;
}

.app-link_item img {
    width: 140px;
    max-width: none;
}

.app-link_item.app-link_item--iphone img {
    margin-left: -1px;
    width: 143px;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .social-link_item [role=img] {
        width: 30px;
    }

    .footer-nav_item {
        flex: 0 0 auto;
        margin: $gap auto;
    }
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .footer-nav_item-title,
    .footer-nav_item {
        width: 100%;
    }

    .footer-nav_item-title {
        border-top: 1px solid $c-library-gray-6;
        padding: 0 $mobile-content-margin;

        &:first-child {
            border-top: none;
        }
    }

    .footer-nav_item-title:not(.footer-nav_item--social) {
        position: relative;

        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-color:   $gray-700
        );

        &:before {
            position: absolute;
            right: $space-s;
            top: $space-s;
        }

        &.ui-state-active {
            @include lib-icon-font-symbol($icon-up);
        }
    }

    .footer-nav_item {
        padding: $space-s $mobile-content-margin;

        &.ui-accordion-content-active {
            margin-top: -$space-base;
        }
    }

    .footer-nav_heading {
        margin: $space-s 0;
    }

    .footer-nav_item--social {
        border-top: 1px solid $c-library-gray-6;
        text-align: center;
    }

    .footer-nav_item--social .footer-nav_heading {
        margin: 0;
    }

    .social-link,
    .app-link {
        margin: $space-s auto 0;
    }

    .social-link {
        justify-content: center;
    }

    .social-link_item [role=img] {
        width: $font-size-xxxxl;
    }

    .app-link {
        flex-direction: row;
        justify-content: center;
    }

    .app-link_item img {
        height: 40px;
        width: auto;
    }

    .app-link_item--android {
        margin-right: $space-base;
    }

    .app-link_item.app-link_item--iphone img {
        width: auto;
    }
}

// ==============================================
// Footer Copyright
// ==============================================

.footer-copyright {
    background: $c-library-blue-11;
    color: $c-white;
    display: flex;
    flex-direction: column;
    padding: $space-xl $space-base $space-l;
}

.copyright-row {
    display: flex;
    justify-content: center;
    margin-bottom: $space-base;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.copyright-row a {
    color: $c-white;
}

.copyright-row-links {
    font-size: $font-size-s;
}

.copyright-row-links .vertical-delim {
    margin: 0 6px;
}

.copyright-row-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: $space-base;

    &:last-of-type {
        margin-right: 0;
    }
}

.copyright-row-logos {
    display: flex;
}

@media (min-width: $bp-screen-m) {
    .copyright-row {
        &.copyright-row-copy {
            order: 2;
        }

        &.copyright-row-links {
            margin-bottom: 0;
            order: 3;
        }

        &.copyright-row-logos {
            margin-bottom: $space-base;
        }

        .footer-logo-img {
            max-width: 100px;
        }
    }

    .footer-logo-img {
        &.logo-img-nate {
            width: 60px;
        }

        &.logo-img-ashrae {
            width: 80px;
        }
    }
}

@media (min-width: $bp-screen-s) and (max-width: $bp-screen-m - 1px) {
    .copyright-row .footer-logo-img {
        max-width: 90px;
    }

    .footer-logo-img {
        &.logo-img-nate {
            width: 55px;
        }

        &.logo-img-ashrae {
            width: 70px;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .copyright-row {
        &.copyright-row-copy {
            order: 3;
            margin-bottom: 0;
        }

        &.copyright-row-links {
            align-items: center;
            order: 2;
        }

        &.copyright-row-logos {
            order: 1;
            margin-bottom: $space-base;
        }
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .copyright-row-logos {
        justify-content: space-between;
    }

    .copyright-row .footer-logo-img {
        max-width: 75px;
    }

    .copyright-row-image {
        margin: auto $space-s auto auto;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    .footer-logo-img {
        &.logo-img-nate {
            width: 45px;
        }

        &.logo-img-ashrae {
            width: 60px;
        }
    }
}
