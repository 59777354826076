.small-wrapper {
    max-width: 480px;
}

.medium-wrapper {
    max-width: 600px;
}

.large-wrapper {
    max-width: 960px;
}

.wide-wrapper {
    max-width: $bp-screen-l;
}

.xl-wrapper {
    max-width: $bp-screen-xl;
}

// -----------------------------------
// App wrapper - div for react apps

.app-wrapper,
.app-wrapper > div,
.app-wrapper > div > div {
    height: 100%;
}
