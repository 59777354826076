/**
* Baker Distributing
*/

// ==============================================
//  Newsletter Subscribe
// ==============================================

.newsletter-subscribe {
    display: flex;
    border-bottom: 1px solid $c-border;
}

.newsletter-subscribe .media-promo,
.newsletter-subscribe .newsletter-subscribe-block {
    flex-grow: 1;
    width: 50%;
}

.newsletter-subscribe .media-promo {
    max-height: 350px;
    position: relative;

    .overlay {
        background-color: rgba(0, 0, 0, 0.7);
        height: 100%;
        left: 0;
        opacity: 0.6;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}

.newsletter-subscribe .media-promo video {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.newsletter-subscribe .newsletter-subscribe-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.newsletter-subscribe .newsletter-subscribe-block {
    h3,
    .newsletter-subscribe-description,
    .row {
        margin-bottom: $space-xl;
    }
}

.newsletter-subscribe .newsletter-subscribe-block h3 {
    font-size: $font-size-xxxl;
    font-weight: $font-weight-bold;
}

.newsletter-subscribe-block .newsletter-subscribe-description {
    max-width: 300px;
    text-align: center;
}

.newsletter-subscribe-block .newsletter-subscribe-notice {
    font-size: $font-size-xxs;
    text-align: center;

    a {
        color: $c-text;
        text-transform: uppercase;
    }
}

.newsletter-subscribe .newsletter-subscribe-block .row {
    align-items: end;
    display: flex;

    .row_item--pad {
        display: flex;
        flex-direction: column;
        margin-right: 0;
    }

    input[type=email],
    input[type=email]:focus {
        border-radius: 0;
        border-width: 1px;
        border-color: $c-border;
    }

    input[type=email] {
        height: 38px;
        padding: 0 $space-xs;
    }
}

@media (min-width: $bp-screen-m) {
    .newsletter-subscribe .newsletter-subscribe-block {
        background: $c-white;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .newsletter-subscribe {
        flex-wrap: wrap;
        position: relative;
    }

    .newsletter-subscribe .media-promo {
        height: 100%;
        position: absolute;
        width: 100%;
    }

    .newsletter-subscribe .newsletter-subscribe-block {
        padding: 15px;
        z-index: 1;

        h3,
        .newsletter-subscribe-description,
        .newsletter-subscribe-notice,
        .newsletter-subscribe-notice a {
            color: $c-white;
        }

        h3 {
            margin-top: $space-base;
        }
    }
}
