// ==============================================
// Shipping: Errors
// ==============================================

.pickup-date_error,
.delivery-date_error {
    position: absolute;
}

// ==============================================
// Shipping: Pick up
// ==============================================

.checkout-pickup_branch {
    margin-bottom: $space-base;
}

.checkout-pickup_branch address {
    font-weight: $font-weight-light;
}

.pickup-date input {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsLW9wYWNpdHk9IjAuNTQiIGQ9Ik0yMCAzaC0xVjFoLTJ2Mkg3VjFINXYySDRjLTEuMSAwLTIgLjktMiAydjE2YzAgMS4xLjkgMiAyIDJoMTZjMS4xIDAgMi0uOSAyLTJWNWMwLTEuMS0uOS0yLTItMnptMCAxOEg0VjhoMTZ2MTN6Ii8+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjwvc3ZnPgo=') no-repeat right;
    background-size: 20px;
}

.pickup-store-tooltip {
    position: absolute;
    margin-left: $space-xs;
}


// ==============================================
// Form Shipping Address
// ==============================================

.opc-wrapper .action-show-popup {
    margin: 0 0 $space-l;
}

.opc-wrapper .action-show-popup > span {
    &::before {
        content: '+';
        padding-right: $space-xs;
    }
}

.opc-wrapper .checkout-delivery_method .field-item-tbd {
    align-items: baseline;
    display: flex;
    margin-bottom: $space-base;

    &.single-delivery-option input[type=radio][checked=true] {
        display: none;
    }

    > b {
        margin-left: auto;
    }

    .tbd-method + b {
        display: none;
    }
}

.opc-wrapper .checkout-pickup .field-item.single-pickup-option.hidden {
    display: none;
}

.opc-wrapper .checkout-pickup .field-item.single-pickup-option input[type=radio][checked=pickup_pickup] {
    display: none;

    + label {
        display: none;
    }
}
.opc-wrapper .checkout-delivery,
.opc-wrapper .checkout-pickup {
    .field.required label::after {
        color: $c-red;
    }

    .message-info.info.tbd-method {
        margin-bottom: 0;
    }

    .input-text {
        &:not([value]) ~ label {
            font-weight: $font-weight-normal;
        }

        &.hasValue ~ label,
        &:not(:empty) ~ label {
            color: $c-text;
            font-weight: $font-weight-normal;
        }

        ~ label,
        &:focus ~ label {
            color: $c-text;
        }
    }

    .field.required label::after {
        margin: 0 0 0 2px;
    }

    .checkout-delivery_method .field {
        margin-bottom: $space-s;
    }

    .rates-subject-change-message {
        display: block;
    }

    .checkout-pickup_method .field {
        margin: 0 0 $space-base 0;
    }
}

// ==============================================
// Shipping Address Item
// ==============================================

.delivery-address_items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $space-xs;
}

.delivery-address_item {
    flex: 0 0 200px;
    word-wrap: break-word;
    margin: $space-base $space-l 0 28px;

}

.delivery-address_item:last-child {
    margin-right: 0;
}

.delivery-address_item .input-radio {
    margin-left: -26px;
    margin-right: 5px;
    position: absolute;
}

.delivery-address_item .input-radio::before {
    top: 4px;
}


// ==============================================
// Checkout Shipping Method
// ==============================================

.checkout-delivery_method {
    padding-top: $space-base;
    border-top: 1px solid $c-border;
}


// ==============================================
// Table Checkout Shipping Method
// ==============================================

.delivery-method_list thead th {
    display: none;
}

// ==============================================
// Shipping Method Actions
// ==============================================

.opc-wrapper .action-group {
    margin: 0;
}

.opc-wrapper .edit-address-link {
    display: block;
    float: left;
    margin: $space-s 5px 0 0;
}

@media (min-width: $bp-screen-s) {
    .opc-wrapper .action-group {
        min-height: 50px;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .opc-wrapper .action-show-popup {
        width: 100%;
    }

    .opc-wrapper .edit-address-link {
        @include lib-icon-font(
            $icon-settings,
            $_icon-font-size         : 24px,
            $_icon-font-line-height  : 24px,
            $_icon-font-text-hide    : true,
            $_icon-font-color        : $gray-900,
            $_icon-font-color-hover  : $gray-900,
            $_icon-font-color-active : $gray-900
        );
        margin: 0;
        right: 0;
        top: $space-xxs;
    }
}
