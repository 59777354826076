.__react_dropdownclick {
    width: auto;

    & > button {
        border-right: 0;
    }

    .dropdown-options {
        position: absolute;
        left: 0;
        top: 35px;
        z-index: 2;
        width: 100%;
        background: $c-white;
        border: 1px solid $c-gray-lighter;

        a {
            width: calc(100% - 20px);
            float: left;
            margin: 10px 10px 0 10px;
        }

        li > a {
            &:hover {
                text-decoration: underline;
            }
        }

        li:last-child a {
            margin-bottom: 20px;
        }
    }

    &.__react_dropdown_up {
        .dropdown-options {
            top: auto;
            bottom: 38px;
        }
    }

    .dropdown-trigger {
        padding: 7px;
        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 22px,
            $_icon-font-position: after,
            $_icon-font-display: block,
            $_icon-font-color-hover: initial
        );

        &::after {
            color: $c-primary-dark;
        }

        &:hover:after {
            color: $c-white;
        }
    }

    .showing-menu {
        &.dropdown-trigger::after {
            content: $icon-up;
        }
    }

    &.__react_dropdown_up {
        .dropdown-trigger::after {
            content: $icon-up;
        }

        .showing-menu {
            &.dropdown-trigger::after {
                content: $icon-down;
            }
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .__react_dropdownclick {
        width: auto;
    }

    .order-templates__view-header {
        .top-line {
            padding-left: $space-base;
        }
    }
}

.__react_dropdownclick .button--secondary {
    @include button-base;

    @include button-style (
        $background         : transparent,
        $background--hover  : $c-primary-dark,
        $background--active : $c-primary-dark,
        $color              : $c-primary-dark
    );

    line-height: 18px;
    border: 1px solid $c-primary-dark;
    background: $c-white;

    &:hover, &:active, &:focus {
        color: $c-white;

        &::after {
            color: $c-white;
        }
    }

    &:disabled {
        border-color: $c-gray;
    }

    &.dropdown-trigger {
        padding: $space-xs;
    }
}
