.algolia-product-list .grid .product-list-item {
    .product-grid-card {
        padding: $space-s;
    }

    .product-item-name {
        margin-bottom: $space-xxxs;
    }

    .product-badges-item-number {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .product-card__root {
        height: 100%;
    }

    .product-item-details {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .product-inventory-component {
        margin: $space-s 0;
    }

    .badge-group .badge {
        font-size: $font-size-xxs;
        margin-top: $space-xxxs;
        padding-bottom: 0;
    }

    .product-item-info {
        margin-bottom: $space-s;
    }
}

@media (min-width: $bp-screen-m) {
    .algolia-product-list .grid .product-list-item {
        .product-item-photo > img {
            max-height: 250px;
            max-width: 100%;
        }
    }
}

#productSuggestions #products-collection .product-image {
    width: 60px;
}

.product-list-grid-container {
    display: grid; /* grid */
    grid-template-columns: repeat(1, minmax(0, 1fr)); /* grid-cols-1 */
    gap: 1rem; /* gap-4 */
}

.algolia-product-list .product-list-grid-container {
    margin-bottom: 20px;
}

@media (min-width: $bp-screen-s) {
    .product-list-grid-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (min-width: $bp-screen-m) {
    .product-list-grid-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .algolia-sidebar-container {
        position: static !important;
        height: auto !important;
        padding: 0 !important;
        z-index: auto !important;
        background-color: transparent !important;
        transform: none !important;
        opacity: 1 !important;
    }
}

@media (min-width: $bp-screen-l) {
    .product-list-grid-container {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (min-width: $bp-screen-xl) {
    .product-list-grid-container {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}
