.card_management-index-index {
    .app-container .form-cc {
        margin-bottom: $space-l;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .card_management-index-index {
        .card_connect > .app-container {
            padding: $space-s calc($space-s/2);
        }

        .app-container .cards_item {
            margin: 0 calc($space-s/2) $space-base calc($space-s/2);
            flex: 1;
            max-width: 400px;
            min-width: 300px;
        }

        .app-container .cards {
            margin-left: 0;
        }
    }
}
