$tabs_border: 1px solid $c-border;

.pp-tab-panel {
    border: $tabs_border;
    position: relative;
    top: -2px;
}

.MuiTabs-root {
    .MuiTab-root {
        background-color: $c-background;
        border-left: $tabs_border;
        border-top: $tabs_border;
        bottom: 1px;
        font-weight: $font-weight-normal;
        position: relative;
        text-transform: none;

        &:last-of-type {
            border-right: $tabs_border;
        }

        &.Mui-selected {
            background-color: $c-white;
        }
    }

    .MuiTabs-scroller.MuiTabs-fixed {
        z-index: 1;
    }

    .MuiTabs-flexContainer {
        padding-top: 1px;
    }

    .MuiTabs-indicator {
        background-color: $c-white;
        height: 1px;
    }
}
