.MuiDialog-container {
    .MuiDialog-paper.MuiPaper-rounded,
    .MuiButtonBase-root {
        border-radius: 0;
    }

    .MuiDialogActions-root {
        padding: $space-base;
    }

    .MuiButton-containedPrimary {
        background-color: $c-library-blue-11;

        &:hover {
            background-color: $c-library-blue-10;
        }
    }

    [role=dialog] {
        width: 500px;
    }

    button.MuiButton-containedSecondary {
        margin: inherit;
    }

    .MuiDialogActions-spacing > .MuiButtonBase-root {
        width: 105px;

        &:not(:first-child) {
            margin-left: $space-xxsl;
        }

        &:first-child {
            margin-left: auto;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
