// ==============================================
// Var
// ==============================================

$minicart-padding: $space-base;

$minicart-quantity-height : 24px;

$header-cart-dropdown_background: $gray-50;

$minicart-mobile_width: 400px;
$minicart-desktop_width: 420px;

// ==============================================
// Minicart
// ==============================================

.block-minicart a:not(.button) {
    color: $c-link;

    &:hover {
        color: darken($c-primary, 10%);
    }
}

.block-minicart .items-total {
    float: left;
}

.block-minicart .items-total .count {
    font-weight: $font-weight-bold;
}

.block-minicart .subtotal {
    margin-bottom: $space-base;
    text-align: right;
}

.block-minicart .amount {
    display: inline-block;
}

.block-minicart .amount .price-wrapper:first-child .price {
    font-weight: $font-weight-bold;
}

.block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    padding: $space-xxl 0 $space-l;
    text-align: center;
}

.block-minicart .text.empty {
    text-align: center;
}

.minicart-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $space-xxsl;

    .minicart-actions_viewcart {
        order: 1;
        flex: 1;
        margin-right: $space-xxsl;
        max-width: 50%;
    }

    .save-active-cart-app {
        order: 2;
        flex: 1;
        margin-left: $space-xxsl;
        max-width: 50%;

        .button {
            width: 100%;
        }
    }

    .minicart-actions_checkout {
        order: 3;
        width: 100%;
    }

    .minicart-actions_extra {
        order: 4;
        width: 100%;

        [data-role=create-approval-request] {
            width: 100%;
        }
    }
}

.minicart-actions > * {
    margin-bottom: $space-xs;
}

.minicart-actions_extra:after {
    @include clearfix;
}

@media (min-width: $bp-screen-m) {
    .minicart-actions_viewcart {
        width: 46%;
    }

    .minicart-actions_checkout,
    .minicart-actions_extra {
        width: 50%;
        float: right;
    }
}


@media (max-width: $bp-screen-m - 1px) {
    .minicart-actions_extra {
        width: 100%;
    }
    .minicart-actions_extra > * {
        margin-bottom: $space-xxs;
    }
}

.block-minicart .block-content > .actions > .primary {
    margin: 0 $space-base $space-base;
}

.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
    margin: 15px 0 0;
    text-align: center;
}


// ==============================================
// Minicart Wrapper
// ==============================================

.minicart-wrapper {
    @include lib-dropdown(
        $_toggle-selector                     : '.action.showcart',
        $_options-selector                    : '.block-minicart',
        $_dropdown-toggle-icon-content        : $icon-cart,
        $_dropdown-toggle-active-icon-content : $icon-cart,
        $_dropdown-list-item-padding          : false,
        $_dropdown-list-item-hover            : false,
        $_dropdown-list-pointer               : false,
        $_icon-font-position                  : before,
        $_icon-font                           : $font-family-icon-outlined,
        $_icon-font-size                      : $font-size-xxxl,
        $_icon-font-line-height               : $font-size-xxxl,
        $_icon-font-color                     : $c-text,
        $_icon-font-color-hover               : $c-header-text-color,
        $_icon-font-color-active              : $c-header-text-color
    );
}

.customer-navigation .minicart-wrapper {
    display: block;
}

.minicart-wrapper .block-minicart {
    padding: $minicart-padding;
    right: 0;
    width: $minicart-desktop_width;
    z-index: 110;
}

.minicart-wrapper .simple-table {
    margin-bottom: $space-base;
}

.minicart-wrapper .block-minicart .block-title {
    display: none;
}

.minicart-wrapper .action.showcart {
    @extend .dropdown-trigger;
}

.minicart-wrapper .action.showcart .loading-mask:before {
    display: none;
}

.minicart-wrapper .action.showcart .counter.qty._block-content-loading {
    .loader {
        margin: 0;
    }

    .loading-mask {
        background: $header-cart-dropdown_background;
    }

    img {
        display: block;
        width: 18px;
    }
}

.minicart-wrapper .action.showcart:after {
    bottom: $space-xxs;
    color: $c-text;
    font-size: $font-size-xxl;
    line-height: 35px;
    position: absolute;
    right: $space-s;
}

.minicart-wrapper .action.showcart,
.minicart-wrapper .action.showcart.active {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    color: $c-text;
    line-height: 1.2;
    min-height: 29px;
}

.minicart-wrapper .action.showcart .text {
    order: 2;
    font-weight: $font-weight-bold;
    font-size: $font-size-s;
    margin-top: auto;
}

.minicart-wrapper .action.showcart .counter {
    background: transparent;
}

.minicart-wrapper .action.showcart .counter.qty {
    background: transparent;
    order: 1;
    padding: 0;
}

.minicart-wrapper .action.showcart .counter.qty .counter-number {
    display: none;
}

.minicart-wrapper .action.showcart .counter.qty .loader > img {
    max-width: $minicart-quantity-height;
}

.minicart-wrapper .action.showcart .counter-label {
    background: transparent;
    color: $c-text;
    font-size: $font-size-xs;
}

.minicart-wrapper .action.showcart .counter-label > span {
    text-transform: capitalize;
}

.minicart-wrapper .minicart-widgets {
    margin-top: $space-base;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .minicart-wrapper {
        @include lib-dropdown(
            $_icon-font                           : $font-family-icon-outlined,
            $_toggle-selector                     : '.action.showcart',
            $_options-selector                    : '.block-minicart',
            $_dropdown-toggle-icon-content        : $icon-cart,
            $_dropdown-toggle-active-icon-content : $icon-cart,
            $_dropdown-list-item-padding          : false,
            $_dropdown-list-item-hover            : false,
            $_dropdown-list-pointer               : false,
            $_icon-font-position                  : before,
            $_icon-font-size                      : 32px,
            $_icon-font-line-height               : 28px,
            $_icon-font-color                     : $c-library-blue-11,
            $_icon-font-color-hover               : $c-library-blue-11,
            $_icon-font-color-active              : $c-library-blue-11
        );
    }

    .customer-navigation .minicart-wrapper {
        display: block;
    }

    .minicart-wrapper .block-minicart {
        width: $minicart-mobile_width;
        max-width: 95vw;
    }

    .minicart-wrapper .action.showcart:after,
    .minicart-wrapper .active.action.showcart:after {
        content: '';
    }

    .minicart-wrapper .action.showcart {
        padding-top: 5px;

        .text {
            display: none;
        }

        .counter.qty {
            background: $c-library-blue-11;
            border-radius: 50%;
            display: block;
            padding: 2px 5px;
            position: absolute;
            right: 8px;
            top: -3px;

            &.empty {
                display: none;
            }
        }

        .counter.qty .counter-label {
            color: $c-white;
            font-size: 9px;
            display: block;
        }

        .counter.qty .counter-label > span {
            display: none;
        }
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .minicart-wrapper {
        margin-left: $space-l;
        order: 30;
    }

    .minicart-wrapper .action.showcart:before {
        position: absolute;
        left: $space-s;
        top: $space-s;
    }

    .minicart-wrapper .active.action.showcart:after {
        bottom: $space-s;
    }

    .customer-navigation .minicart-wrapper {
        background: $header-cart-dropdown_background;
        display: block;
        height: 50px;
        padding: 11px 32px 6px 46px;
    }

    .minicart-wrapper .block-minicart {
        margin-top: $space-xs;
        right: 0;
        width: $minicart-desktop_width;

        &:before {
            @include triangle($direction: up, $size: 8px, $color: $c-border);
            top: -8px;
            right: 14px;
        }

        &:after {
            @include triangle($direction: up, $size: 6px, $color: $c-white);
            top: -6px;
            right: 16px;
        }
    }
}


// ==============================================
// Minicart Items
// ==============================================

.minicart-items-wrapper {
    overflow-x: auto;
    border-left: 0;
    border-right: 0;
    width: 100%;
}


.minicart-item {
    border-top: 1px solid $c-border;
    padding: $space-l 0;
}

.minicart-item-wrapper {
    @include lib-clearfix;
    position: relative;
}

.minicart-item_price {
    float: left;
}

.minicart-item_action {
    float: right;
    margin-left: $space-xs;
}

.minicart-item_qty {
    position: relative;
    top: 3px;
    padding-right: $space-xl;
}

.minicart-item-wrapper > .minicart-item_photo {
    position: absolute;
    left: 0;
    width: auto;
}

.minicart-item .minicart-item_name a {
    color: $c-link;

    &:hover {
        color: $c-link-hover;
    }
}

.minicart-item .minicart-item_details {
    padding-left: 88px;
}

.minicart-item .minicart-item_details .price {
    font-weight: $font-weight-bold;
}

.minicart-item .minicart-item_details .price-including-tax,
.minicart-item .minicart-item_details .price-excluding-tax {
    margin: $space-xs 0 0;
}

.minicart-item .minicart-item_details .weee[data-label] {
    font-size: $font-size-xxs;
}

.minicart-item .minicart-item_details .weee[data-label] .label {
    display: none;
}

.minicart-item .input-text.qty {
    text-align: center;
    height: 25px;
    width: 40px;
}

.minicart-item .update-cart-item {
    background: transparent;
    border: none;
    @include lib-icon-font(
                    $icon-update,
            $_icon-font-size         : 28px,
            $_icon-font-line-height  : 28px,
            $_icon-font-text-hide    : true,
            $_icon-font-color        : $gray-700,
            $_icon-font-color-hover  : $gray-700,
            $_icon-font-color-active : $gray-700
    );

    position: absolute;
    right: 0;
    top: 0;
}

.minicart-item .action.delete {
    @include lib-icon-font(
                    $icon-trash,
            $_icon-font-size         : 28px,
            $_icon-font-line-height  : 28px,
            $_icon-font-text-hide    : true,
            $_icon-font-color        : $gray-700,
            $_icon-font-color-hover  : $gray-700,
            $_icon-font-color-active : $gray-700
    );
}

.minicart-item .action.delete[disabled="disabled"],
.minicart-item .update-cart-item[disabled="disabled"] {
    &:before {
        opacity: 0.5;
    }
}
