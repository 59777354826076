// ----------------------------------------------
// Mobile
// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .invoice-index-index .app-wrapper .content-wrapper,
    .invoice-view-index .app-wrapper .content-wrapper {
        padding: $space-base;
    }
}

.invoice-view-printaction {
    .widget.block.block-static-block,
    .widget.block.block-banners {
        display: none;
    }
}
