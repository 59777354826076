// ==============================================
// Totals
// ==============================================

.totals-table {
    letter-spacing: $letter-spacing;
    width: 100%;
}

.totals-table .shipping-totals-tooltip {
    position: relative;
    right: 0;
    top: 3px;

    .material-icons {
        font-family: $font-family-icon-outlined;
    }
}

.totals-table .totals.shipping .mark {
    display: flex;
    align-items: center;
}

.totals-table .totals.shipping .amount {
    vertical-align: bottom;
}

// ==============================================
// Totals th
// ==============================================

.totals-table th {
    padding-right: $space-s;
    white-space: normal;
}

.totals-table td,
.totals-table th {
    padding-bottom: 0;
    line-height: $font-size-xxl;
}

// ==============================================
// Totals td
// ==============================================

.totals-table td {
     text-align: right;
    padding-bottom: $space-xxxs;
}

.totals-table .grand-total-before > td {
    border-bottom: $space-xxxs solid $c-border;
    line-height: $space-xs;
    font-size: $space-xs;
}

.totals-table .grand.totals {
    padding-top: $space-base;

    td.amount,
    th.mark {
        padding-top: $space-s;
    }
}
