$offset-base: 3px;
$offset-s: 3px;

$c-input-focus-base: $c-library-green-2;
$c-switch-background-base: $c-green;
$c-switch-background-hover: $c-library-green-5;

$outline-focus-base: 3px solid $c-input-focus-base;
$input-height-s: 26px;
$input-height-xs: 18px;

$switch-box-shadow: inset 0 2px 6px 0 rgba(0, 0, 0, 0.3);
$switch-height-base: $input-height-s;
$switch-height-s: $input-height-xs;
$switch-width-base: 50px;
$switch-width-s: 30px;

$knob-size-base: calc($switch-height-base - (2 * $offset-base));
$knob-size-s: calc($switch-height-s - (2 * $offset-s));

.trigger {
    background: transparent;
    border: none;
    display: grid;
    align-items: center;
    gap: $space-s;
    grid-template-columns: auto $switch-width-base auto;
    justify-content: start;
    margin-bottom: $space-s;
    cursor: pointer;
    user-select: none;
}

// Modifier: Center

.trigger_center {
    composes: trigger;
    margin-left: auto;
    margin-right: auto;
}

// Modifier: Disabled

.trigger_disabled {
    opacity: 0.6 !important;
    cursor: not-allowed !important;
}

// Modifier: Size

.trigger_s {
    composes: trigger;
    grid-template-columns: auto $space-s auto;
}

// ==============================================
// Label
// ==============================================

.trigger-label:first-child {
    text-align: right;
}

.trigger-label:last-child {
    text-align: left;
}

// Modifier: Small

.trigger-label_s {
    font-size: $font-size-s;
    line-height: $switch-height-s;
}

.trigger-label.inactive:not(:hover) {
    opacity: 0.6;
}

// ==============================================
// Switch
// ==============================================

.trigger-switch {
    position: relative;
    width: $switch-width-base;
    height: $switch-height-base;
    border-radius: $switch-height-base;
    background: $c-switch-background-base;
    box-shadow: $switch-box-shadow;
}

// Modifier: Disabled

.trigger-switch_disabled {
    background: $c-background;
}

// Modifier: Small

.trigger-switch_s {
    width: $switch-width-s;
    height: $switch-height-s;
    border-radius: $switch-height-s;
}

// Parent Modifier: Focus

.trigger:focus .trigger-switch {
    box-shadow: $switch-box-shadow;
    outline: $outline-focus-base;
}

// Parent Modifier: Hover

.trigger:hover:not(.trigger_disabled) .trigger-switch {
    background: $c-switch-background-hover;
}

// ==============================================
// Knob
// ==============================================

.trigger-knob {
    position: absolute;
    top: $offset-base;
    left: $offset-base;
    width: $knob-size-base;
    height: $knob-size-base;
    border-radius: $knob-size-base;
    background: #FFF;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    text-indent: -9999px;
    transition: all 150ms cubic-bezier(0.165, 0.84, 0.44, 1); /* easeOutQuart */
}

// Modifier: Position Right

.trigger-knob_right {
    transform: translateX(calc($switch-width-base - $offset-base - $knob-size-base - $offset-base));
}

// Parent Modifier: Small

.trigger-knob_s {
    top: var(--offset-s);
    left: var(--offset-s);
    width: var(--knob-size-s);
    height: var(--knob-size-s);
    border-radius: var(--knob-size-s);
}

// Modifier: Position Right + Small

.trigger-knob_right.trigger-knob_s {
    transform: translateX(calc($switch-width-s - $offset-s - $knob-size-s - $offset-s));
}
