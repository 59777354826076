/**
* Baker Distributing
*/


// ==============================================
// Menu Top
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .menu-top {
        padding: $space-xs $space-base;
    }

    .menu-top .greet.welcome {
        font-weight: $font-weight-bold;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .menu-top {
        position: absolute;
        top: 98px;
        right: $layout-indent__width;
    }

    .menu-top .greet.welcome {
        display: none;
    }

}
