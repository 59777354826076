// ------------------------------------
// Checkout Payment Discount Form
// ------------------------------------

.opc-payment-additional.discount-code {
    margin-top: $space-xxl;
}

.discount-code .form-discount {
    display: flex;
    align-items: end;

    .payment-option-inner {
        flex: 1;
    }
}

// Discount Code Input

.discount-code .payment-option-inner {
    .input-text {
        border: 1px solid;
        padding-left: $space-s;
    }

    .field {
        margin-bottom: 0;
    }
}

// Apply Promo Link

._active > .dropdown-trigger {
    @include lib-icon-font(
        $_icon-font-content: $icon-up,
        $_icon-font-size: 22px,
        $_icon-font-position: after,
        $_icon-font-display: block
    );
}

.opc-payment-additional.discount-code .action.action-toggle {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.opc-payment-additional.discount-code .field.dropdown-trigger {
    margin-bottom: $space-l;
}

// Action Buttons

.column:not(.sidebar-main) form .actions-toolbar {
    margin-left: $space-base;
}

.form-discount .actions-toolbar > .primary,
.form-discount .actions-toolbar > .secondary {
    margin-bottom: 0;
}
