//
//  Slick slider styles
//  _____________________________________________

.slick-slider {
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-touch-action: pan-y;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    box-sizing: border-box;
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;
}
.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    [dir='rtl'] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev {
    @include lib-icon-font(
            $_icon-font-color          : $c-white,
            $_icon-font-content        : 'keyboard_arrow_left',
            $_icon-font-vertical-align : middle,
            $_icon-font-margin         : 0 2px 0 0
        );
    left: 2rem;
    z-index: 1;
}

.slick-next {
    @include lib-icon-font(
            $_icon-font-color          : $c-white,
            $_icon-font-content        : 'keyboard_arrow_right',
            $_icon-font-vertical-align : middle,
            $_icon-font-margin         : 0 0 0 2px
        );
    right: 2rem;
    z-index: 1;
}

.arrow-wrapper.slick-arrow {
    max-width: $bp-screen-l;
    position: absolute;
}

.slick-prev,
.slick-next {
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    background: $c-gray-background;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    font-size: 0;
    line-height: 0;
    outline: none;
    padding: 14px $space-s;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    &:hover:before {
        color: $gray-600;
    }

    &:before,
    &:after {
        color: $c-white;
        opacity: 1;
    }

    &:active,
    &:focus,
    &:not(.primary) {
        box-shadow: none;

        &:active {
            box-shadow: none;
        }
    }
}

.slick-dots {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
    outline: none;

    li {
        cursor: pointer;
        display: inline-block;
        flex-grow: 1;
        margin: 0;
        padding: 0;
        position: relative;
        text-transform: none;
        vertical-align: middle;

        button {
            border: 0;
            border-bottom: 2px solid transparent;
            box-shadow: none;
            cursor: pointer;
            font-weight: $font-weight-normal;
            color: $c-text;
            display: block;
            padding: 0 0 5px;
            transition: .3s;
            width: 100%;

            &:hover {
                border-color: $c-white;
            }

            &:active,
            &:focus,
            &:not(.primary) {
                box-shadow: none;
            }
        }
    }
}

div[data-content-type='slider'] {
    .slick-dots {
        bottom: -40px;
        padding-inline-start: 0;
    }
}

.product-items.widget-product-carousel {
    padding: $space-xxl;

    &:not(.slick-initialized) {
        display: flex;
    }

    .slick-prev,
    .slick-next {
        background: transparent;

        &:before {
            color: $c-gray-dark;
        }

        &:hover:before {
            color: $c-gray;
        }

        &.slick-disabled:before,
        &.slick-disabled:hover:before {
            color: $c-gray-lighter;
        }
    }

    .slick-track,
    .slick-slide,
    .slick-slide > div,
    .slick-slide .product-item {
        display: flex;
        height: inherit;
    }

    .slick-slide > div {
        width: 100%;
    }

    .slick-prev {
        left: $space-xxs;
    }

    .slick-next {
        right: 0;
    }

    .slick-dots {
        justify-content: center;
    }

    .slick-dots li {
        flex: unset;
        margin: $space-xs;
    }

    .slick-dots li button {
        padding: $space-xs;
    }

    .product-item-info {
        border: 2px solid $gray-150 ;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: $space-xs;
        width: 100%;

        .product-item-details,
        .product-item-inner {
            display: flex;
            flex: 1;
            flex-direction: column;
        }

        .product-item-name {
            padding-top: $space-s;
        }

        .block-requisition-list {
            margin: $space-base;
        }

        .block-requisition-list .menu.menu--short {
            margin-top: $space-xs;
        }

        .badge-group {
            min-height: 27px;
        }

        .product-image-photo {
            width: 100%;
        }

        .centered-image {
            text-align: center;
        }

        .product-item-inner {
            padding-top: $space-xl;
        }

        .product-price-wrapper {
            height: $space-base;
        }
    }
}

//  _____________________________________________
// Mobile

@media only screen and (max-width: $bp-hero - 1px) {

    .slick-dots {
        background: $c-gray-background;

        li {
            border-bottom: none;

            button {
                background: $c-gray-background;
                box-shadow: none;
                padding: $space-s $space-xs;
            }

            &.slick-active {
                button {
                    background: $c-white;
                }
            }
        }
    }

    .arrow-wrapper button {
        &.slick-prev,
        &.slick-next {
            display: none;
        }
    }
}

//  _____________________________________________
// Desktop

@media only screen and (min-width: $bp-hero) {
    div[data-content-type='slider'] {
        .slick-dots {
            background: $c-gray-background;
            padding: 0;

            li {
                border-bottom: 2px solid $c-gray-background;
                margin: 0;
                width: auto;
                height: auto;

                &:hover {
                    border-color: $c-white;
                }

                &.slick-active,
                &.slick-active:hover {
                    border-bottom-color: $c-white;
                }
            }

            button {
                color: $c-text;
                background: transparent;
                line-height: $space-base;
                padding: $space-xl;
                border: none;
                width: 100%;
                height: auto;
                font-size: inherit;

                &:before {
                    content: '';
                }
            }

            .slick-active button {
                background: $c-white;
            }
        }
    }
}

// ===================================================
// Product Item My List
// ===================================================

.product-items.widget-product-carousel .product-item {
    .block-requisition-list {
        margin-top: $space-base;
    }

    .my-lists-dropdown {
        margin: auto;
        max-width: 300px;
        width: 100%;
    }

    .my-lists-dropdown-content {
        z-index: 2;
    }
}

@media (max-width: $bp-screen-xl - 1) {
    .product-items.widget-product-carousel .product-item {
        .block-requisition-list .my-lists-dropdown-content {
            min-width: 250px;
            left: -$space-l;
        }
    }
}
