// ==============================================
// Message
// ==============================================

.message.info {
    @include lib-message-icon(info);
}

.message.error {
    @include lib-message-icon(error);
}

.message.warning {
    @include lib-message-icon(warning);
}

.message.notice {
    @include lib-message-icon(notice);
}

.message.success {
    @include lib-message-icon(success);
}

.message.error,
.message.warning,
.message.notice,
.message.success {
    > p {
        margin-bottom: 0;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .content-wrapper > .message {
        margin: $space-base;
    }
}

.message ul {
    list-style: disc outside none;
    padding-left: $space-base;
}
