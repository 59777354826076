.warranty-form-field {
    max-width: 100%;
    width: 450px;
}

.warranty-form-action {
    .MuiButtonBase-root {
        border-radius: 0;
    }

    .MuiButton-containedPrimary {
        background-color: $c-library-blue-11;

        &:hover {
            background-color: $c-library-blue-10;
        }
    }
}

.warranty-details {
    .product-details a {
        color: $c-text;
    }

    th.MuiTableCell-root {
        background-color: $gray-50;
        font-weight: $font-weight-semibold;
    }
}

.warranty-service-history {
    .service-history-summary label {
        display: inline-block;
        font-weight: $font-weight-semibold;
        margin-right: $space-xxs;
        min-width: 90px;
    }
}

.warranty-service-part {
    margin-bottom: $space-s;

    &:last-of-type {
        margin-bottom: 0;
    }
}

@media (min-width: $bp-screen-m) {
    .warranty-details {
        .product-details {
            margin: 0 0 0 $space-base;
        }

        .product-image {
            margin: 0;
            width: 125px;
        }

        .entitlement-table th.MuiTableCell-root {
            width: 25%;
        }

        .product-documents-app,
        .parts-app {
            padding: $space-base;
        }
    }

    .warranty-details-section {
        padding: 0 $space-base $space-base;
    }

    .warranty-service-history {
        margin: $space-base;
    }
}

@media (min-width: $bp-table-linearize) {
    .warranty-details .table--linearize {
         label {
            display: none;
        }
    }
}

@media (max-width: $bp-table-linearize - 1) {
    .warranty-details .table--linearize {
        label {
            font-weight: $font-weight-semibold;
            margin-right: $space-s;
            min-width: 90px;
            width: 30%;
        }

        label,
        span {
            display: inline-block;
        }

        td {
            align-items: center;
            display: flex;
            margin-top: $space-xs;
        }

        th {
            display: none;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .warranty-details {
        .product-details {
            margin: 0;
        }

        .unit-information {
            flex-wrap: wrap;
        }

        .product-image {
            margin: auto;
            width: 200px;
        }

        .entitlement-table th.MuiTableCell-root {
            width: 50%;
        }

        .MuiAccordionDetails-root {
            display: block;
        }
    }

    .warranty-details-section {
        padding: 0 $space-xs $space-xs;
    }

    .warranty-service-history {
        margin: $space-xs;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .warranty-details {
        .entitlement-table th.MuiTableCell-root {
            display: block;
            width: 100%;
        }

        .MuiTableCell-root,
        .MuiTableRow-root {
            display: block;
        }
    }
}

// ==============================================
// Warranty Center page custom styles effect fix
// ==============================================

.cms-warranty-center {
    .dropdown,
    .minicart-wrapper {
        &.active:after {
            content: "";
        }
    }

    .dropdown,
    .dropdown-trigger {
        &.active {
            background-color: transparent;
        }
    }

    .action.showcart,
    .account-dropdown-wrap.dropdown {
        &.active {
            background-color: $gray-50;
        }
    }
}
