// ==============================================
// Payment Methods
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .payment-methods {
        margin: 0 -15px;
    }

    .manage-payment-method .box > h2 {
        padding: $space-s $space-base 0;
    }
}

.payment-method .table-caption{
    display: none;
}


// ==============================================
// Payment Method
// ==============================================

.payment-group:not(.payment-group-vault) .step-title {
    display: none;
}

// ==============================================
// Payment Method Title
// ==============================================

.payment-method-title {
    border: 1px solid $c-border;
    padding: $space-xs $space-base;
    margin-bottom: $space-base;
    background: $c-background;
}

.payment-method-title .payment-icon {
    display: inline-block;
    margin-right: $space-xs;
    vertical-align: middle;
}

.payment-method-title .action-help {
    display: inline-block;
    margin-left: $space-xs;
}


// ==============================================
// Payment Method Content
// ==============================================

.payment-method-content {
    display: none;
    width: 100%;
    max-width: 600px;
    padding-left: 10px;
    margin-bottom: $space-base;
}

@media (max-width: $bp-screen-m - 1px) {
    .payment-method-content {
        padding-left: 0;
    }
}

._active .payment-method-content {
    display: inline-block;
}

.payment-method-content .fieldset:not(:last-child) {
    margin: 0 0 $space-s;
}

.fieldset.cardconnect {
    .field.widget > .control {
        margin-top: 0;
    }

    > .legend {
        margin-bottom: $space-xxs;
    }
}

// ==============================================
// Payment Method CardConnect fields
// ==============================================

.fieldset.cardconnect {
    .input-text {
        border: 1px solid;
        padding-left: $space-s;
    }

    .select {
        border: 1px solid;
        padding: $space-xs $space-xxl $space-xs $space-s;
    }

    .control {
        margin-top: 0;
    }

    .field.cvv {
        display: flex;
        align-items: flex-start;

        > label {
            line-height: 38px;
            margin-right: $space-base;
            margin-left: auto;
        }
    }
}

@media (min-width: $bp-screen-m) {
    .fieldset.cardconnect {
        .field.cvv {
            > .control {
                width: 48%;
            }
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .fieldset.cardconnect {
        .field.cvv {
            > .control {
                width: 47%;
            }
        }
    }
}

// ==============================================
// Payment Method - Summary
// ==============================================

.payment-card {
    display: inline-flex;
}

.payment-card_info {
    padding-top: 6px;
}

// ==============================================
// Billing Address
// ==============================================

.payment-method-content .billing-address-details {
    padding: 16px;
    border: 1px solid $c-border;
    margin-bottom: $space-xl;
    position: relative;
    left: 0;

    &::before {
        @include triangle($direction: up, $size: 12px, $color: $c-border);
        left: 6px;
        top: -12px;
    }

    &::after {
        @include triangle($direction: up, $size: 10px, $color: $c-white);
        left: 8px;
        top: -10px;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .payment-method-content .billing-address-details {
        margin-left: 0;
    }
}

.payment-method-content .field {
    margin: 0 0 $space-base 0;
}

.field-select-billing .select[name=billing_address_id] {
    border: 1px solid;
    padding-right: 30px;
    padding-left: $space-s;

    ~ label {
        top: -14px;
    }
}

// ==============================================
// Payment Method - Vault
// ==============================================

.payment-group .step-title-vault {
    margin-bottom: $space-s;
    padding-top: $space-xs;
    font-size: $font-size-xl;
    width: 100%;
}

.payment-group.payment-group-vault {
    display: flex;
    flex-wrap: wrap;
}

.payment-group.payment-group-vault > .payment-method {
    margin: 0 $space-s $space-base 0;
}

.payment-group-vault .payment-method-title.field.choice {
    border-radius: 10px;
    width: 200px;
    margin-bottom: 0;

    > input.radio {
        display: none;
    }

    > .label {
        display: flex;
        flex-wrap: wrap;
        padding-left: 40px;
    }
}

.payment-group-vault .payment-method-title.choice > .label {
    &:before {
        left: 10px;
    }

    > .payment-vault-cc-image {
        display: flex;
        margin-bottom: $space-xxsl;
        width: 100%;

        > img {
            margin-left: auto;
            margin-right: 0;
        }
    }

    > .payment-vault-label {
        display: flex;
        flex-wrap: wrap;

        > span {
            display: block;
            width: 50%;
        }

        > span.label-row {
            width: 100%;
        }
    }
}

// Vault Actions

.payment-group.payment-group-vault {
    position: relative;
}

.payment-group-vault .payment-method-content .actions-toolbar {
    bottom: $space-xs;
    left: 0;
    position: absolute;
}

.payment-group-vault .payment-group-bottom {
    width: 100%;
    height: 0;

    &.group-selected {
        height: $space-xxxl;
    }
}

// Vault Billing Address

.payment-group-vault .payment-method-content {
    margin-top: 11px;
    margin-bottom: 0;
    padding: 0;

    .checkout-billing-address.m-b-xl {
        margin-bottom: 0 !important;
    }
}

.payment-group-vault .payment-method-content .billing-address-details {
    margin-bottom: 0;
}

@media (max-width: $bp-screen-m - 1px) {
    .payment-group-vault .payment-method-content .billing-address-details {
        margin-left: 0;
    }
}

// Billing Same As Shipping

.billing-address-same-as-shipping-block.field {
    margin-left: 10px;
    margin-bottom: $space-xs;
}
