#txt2chat {
    z-index: 12;
}

#chat-wrapper #chat {
    z-index: 12;
}

.text2chat-phone {
    align-items: center;
    display: flex;
}

.text2chat-phone .text2chat-icon {
    color: $peirceblue-400;
    cursor: pointer;
    font-size: 28px;
    margin-left: $space-s;
}

.text2chat-phone a,
.text2chat-phone .text2chat-icon {
    &:active {
        color: $c-library-blue-11;
    }
}

.text2chat-tooltip {
    line-height: 0.9;

    @include lib-tooltip(
        $_tooltip-position: bottom,
        $_tooltip-selector-content: '.tooltip-content',
        $_tooltip-selector-toggle : '.tooltip-toggle',
        $_tooltip-cursor: pointer,
        $_tooltip-min-width: 195px,
        $_tooltip-line-height: 0.9,
        $_tooltip-font-weight: $font-weight-heavy,
        $_tooltip-border-radius: 6px,
        $_tooltip-background: $c-library-gray-6,
        $_tooltip-color: $c-white,
        $_tooltip-font-family: $font-family-base,
        $_tooltip-font-size: $font-size-xs,
        $_tooltip-padding: 8px 12px
    );
}
