.accessory-table {
    .accessory-filter-wrapper {
        padding: $space-l 0;
    }

    .accessory-filter-wrapper .MuiOutlinedInput-root {
        border-radius: 0;
    }

    .MuiOutlinedInput-input {
        padding: $space-xs $space-s;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $blue-700;
    }

    .MuiFormLabel-root:not(.Mui-focused):not(.MuiFormLabel-filled) {
        font-size: $font-size-base;
        top: -$space-xs;
    }

    .MuiFormLabel-root.Mui-focused {
        color: $blue-700;
    }

    .show-more-button {
        display: flex;
        margin-top: $space-base;
    }

    .show-more-button .MuiButton-containedPrimary {
        background-color: $c-library-blue-11;
        border-radius: 0;
        margin: auto;

        &:hover {
            background-color: $c-library-blue-10;
        }
    }

    .accessory-row .actions-primary .field.qty {
        display: none;
    }

    .sticky-header-hidden {
        display: none;
    }

    .stock.available .status::before,
    .stock.unavailable .status::before {
        content: '';
    }

    .product-inventory,
    .stock {
        margin-top: 0;
    }

    .price {
        display: flex;
        flex-direction: column;
        font-weight: $font-weight-normal;

        .old-price {
            margin-top: $space-xs;
        }
    }

    .accessory-column.column-product-actions .price-message > a {
        color: $c-red;
        margin-right: $space-xxs;
    }

    .badge {
        font-size: $font-size-xxs;
    }
}

// Desktop

@media (min-width: $bp-screen-m) {
    .accessory-table {
        .accessory-filter {
            min-width: 400px;
        }

        .sticky-header-visible {
            overflow: hidden;
            position: fixed;
            top: 0;
            z-index: 1;
            animation: slideDown 200ms ease-in;
        }

        .table th {
            background: $c-background;
            color: $c-text;
            font-weight: $font-weight-normal;
            padding: $space-s $space-xs $space-s $space-xs;
        }

        .table td {
            padding: $space-xxs $space-xs $space-xxs $space-xs;

            &.column-product-brand {
                text-transform: uppercase;
            }

            &.column-product-actions {
                padding-left: $space-xxs;
                padding-right: $space-s;
            }

            > label {
                display: none;
            }
        }

        .table thead {
            border: 1px solid $c-border-light;
        }

        .table tbody tr {
            border: 1px solid $c-border-light;
        }

        .table tbody td {
            border: none;
        }

        .action.primary.tocart {
            background: $c-library-green-6;
            font-size: $font-size-s;
            font-weight: $font-weight-semibold;
            text-transform: none;
        }
    }

    .accessory-row .product-image {
        max-width: 60px;
    }

    .accessory-column {
        font-size: $font-size-s;
        min-width: 100px;

        &.column-product-image,
        &.column-product-brand {
            min-width: 80px;
        }

        &.column-product-availability {
            font-size: $font-size-xs;
        }

        &.column-product-availability.logged-in {
            width: 210px;
        }

        &.column-product-name {
            width: 320px;
        }

        .price {
            font-size: $font-size-s;

            .price-message {
                font-size: $font-size-xxs;
                margin-top: $space-xs;
            }
        }
    }
}

// Mobile

@media (max-width: $bp-screen-m - 1) {
    .accessory-table {
        .accessory-filter {
            width: 100%;
        }

        .table {
            margin-bottom: $space-base;
        }

        .table thead {
            display: none;
        }

        .table tr, td {
            display: flex;
            flex-wrap: wrap;
        }

        .table tbody tr {
            border-bottom: 1px solid $c-border-light;
            margin-bottom: $space-s;

            &:last-child {
                border-bottom: none;
                margin-bottom: $space-xs;
            }
        }

        .table tbody td {
            border: none;
        }

        .table td > label {
            margin-right: $space-xxs;
        }

        .accessory-column {
            padding: $space-s 0 0;
            width: 100%;
        }

        .accessory-column.column-product {
            &-image {
                order: 1;
                padding: $space-s $space-xs 0 0;
                width: 30%;
            }

            &-name {
                order: 2;
                padding: $space-s 0 0 $space-xs;
                width: 70%;
            }

            &-item {
                order: 3;
                padding-top: $space-base;
            }

            &-brand {
                order: 4;
            }

            &-availability {
                order: 5;
            }

            &-price {
                order: 6;
                padding-top: $space-base;
            }

            &-actions {
                order: 7;
                padding-bottom: $space-l;
            }
        }

        .column-product-availability {
            display: none;

            &.logged-in {
                display: flex;
                flex-wrap: nowrap;
            }
        }

        .column-product-image {
            display: block;

            .product-image {
                max-width: 80px;
            }
        }

        .column-product-brand > span {
            text-transform: uppercase;
        }

        .column-product-price .price-box {
            margin: auto;
        }

        .accessory-column .actions-primary {
            margin: auto;
            width: 100%;

            .action.tocart.primary {
                background: $c-library-green-6;
                font-weight: $font-weight-semibold;
                text-transform: none;
                width: 100%;
            }
        }

        .price {
            align-items: center;
            font-size: $font-size-base;

            .price-message {
                font-size: $font-size-xs;
                margin-top: $space-xs;
            }
        }
    }
}
