// PDP UOM Selector

.item-uom-list-wrapper {
    margin: 0 $space-base 0 $space-s;

    .item-uom-list {
        height: 35px;
        margin: 0;
        padding: 0 18px 0 0;
    }
}

// PLP UOM Selector

.product-item-details,
.product-list-item {
    .item-uom-list {
        height: unset;
        margin-left: 0;
        padding: 0 $space-sl $space-xxxs 0;
        width: auto;
    }

    .uom-divider {
        margin: 0 $space-xxs 0 0;
    }

    .price {
        margin-right: $space-xxs;
    }
}
