.flyout-modal {
    height: 100vh;
    padding: 0;
    width: 100vw;

    .MuiDialog-scrollPaper {
        align-items: stretch;
        justify-content: flex-end;
    }

    .MuiDialog-paper {
        margin: 0;
    }

    .MuiDialog-paperWidthSm {
        max-width: unset;
    }

    .MuiDialog-container [role=dialog] {
        max-height: unset;
        width: 80%;
    }

    .MuiDialogTitle-root {
        border-bottom: 1px solid $c-border-light;
        padding: $space-base;
    }

    .modal-header {
        display: flex;
    }

    .modal-header .action-close {
        margin-right: 0;
        margin-left: auto;
        padding: $space-base;
        line-height: 1;
    }

    .modal-title {
        margin-bottom: 0;
    }

    .MuiDialogContent-root {
        padding: $space-base;
    }
}
