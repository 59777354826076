$table-border: 1px solid $c-gray-lighter;
$required-border: 2px solid $c-red;

.catalog-product-view .MuiPaper-rounded {
    border-radius: 0;
}

.table--linearize tbody .ahri-table-cell_accessory {
    display: flex;
    flex-direction: column;
}

.std.ahri-list {
    max-width: 100%;

    h2 {
        font-size: $font-size-xl;
    }
}

.ahri-table-cell_accessory-item {
    @include lib-icon-font(
        $_icon-font-content: $icon-expand,
        $_icon-font-size: $font-size-sl,
        $_icon-font-line-height: $font-size-sl,
        $_icon-font-vertical-align: middle
    );

    &:before {
        bottom: 1px;
        position: relative;
        right: 1px;
    }
}

// ==============================================
// Checkout
// ==============================================

.checkout-index-index .ahri-number {
    margin-top: $space-l;
    padding: $space-base;
    background: $gray-75;
}

// ==============================================
// Catalog
// ==============================================

.ahriTable {
    .ahri-loading {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;

        .simple-loading {
            margin: $space-xs;
        }
    }

    .ahri-required-filters-intro {
        margin: $space-xl 0 0;
    }

    .ahri-required-filters-summary .attention {
        color: $c-red;
    }

    .matches-found-summary {
        margin-bottom: $space-base;
    }

    .required-filters .required-filter-row {
        display: flex;
        margin: $space-base 0;

        > div {
            display: flex;
        }

        > div p {
            margin-left: 0;
        }

        .selection-required .MuiOutlinedInput-input {
            border: $required-border;
            border-radius: 0;
            color: $c-red;
        }

        .selection-required .MuiFormHelperText-contained,
        .selection-required .MuiSelect-iconOutlined {
            color: $c-red;
        }

        .selection-required .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline,
        .selection-required .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline,
        .selection-required .MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
            border: none;
        }

        .MuiOutlinedInput-root {
            border-radius: 0;
        }

        .Mui-focused.label {
            color: $c-text;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: $c-border;
        }

        .MuiPaper-root.MuiMenu-paper {
            border-radius: 0;
        }

        .MuiFormControl-root.no-selection .MuiSelect-icon {
            display: none;
        }
    }

    .ahri-table-cell {
        &.ahri-table-cell-ahri_certified_ref span,
        &.ahri-table-cell-availability span {
            text-transform: none;
        }

        span {
            text-transform: capitalize;
        }
    }

    .ahri-table-cell-ahri_certified_ref a {
        color: $c-text;
    }

    .table--linearize tbody tr {
        &.ahri-item-id {
            background: $c-gray-background;
        }

        &.ahri-item-action .ahriTable_row-componentAction {
            text-align: center;
        }
    }

    .ahri-item-action .ahriTable_row-componentAction .action.primary,
    .action.primary.ahri-row-view-system {
        background: $c-green;
        font-weight: $font-weight-semibold;
        line-height: 1.7;
        text-transform: none;
    }

    .system-price {
        display: flex;
        flex-direction: column;
        font-size: $font-size-s;
        font-weight: $font-weight-bold;
    }

    .ahri-table-toolbar {
        align-items: center;
        display: flex;

        .ahri-toolbar-applied-filters {
            display: flex;
            flex-grow: 1;
            flex-wrap: wrap;
        }

        .ahriTable_columnContainer,
        .filters-container {
            display: flex;
        }

        .ahriTable_columnContainer .field-item {
            display: flex;
            line-height: $space-base;
            padding: $space-xxs 0 $space-xs $space-xxxs;

            [type='checkbox'] {
                opacity: 0;
            }
        }

        .filters-container {
            flex-wrap: wrap;
        }

        .filter-actions,
        .filters-selected {
            width: 100%;
        }

        .filters-selected .filter-cell-delete > .delete-icon {
            cursor: pointer;
            font-size: 0;
        }

        .filters-selected .filter-cell-delete > .delete-icon.disabled {
            cursor: unset;

            &:before {
                color: $gray-500;
            }
        }

        .filter-cell.filter-cell-value {
            padding: 0 0 $space-xxs 0;
        }
    }

    .ahri-toolbar-applied-filters .applied-filter {
        display: flex;
        align-items: center;
    }

    .remove-all-filters {
        text-decoration: underline;
    }

    .ahri-toolbar-applied-filters .remove-icon {
        cursor: pointer;
        font-size: 0;
        margin-left: (-$space-xxs);

        @include lib-icon-font(
            $icon-close,
            $_icon-font-size: $font-size-xxxl,
            $_icon-font-line-height: $font-size-xxxl,
            $_icon-font-text-hide: true,
            $_icon-font-color: $gray-700,
            $_icon-font-color-hover: $gray-700,
            $_icon-font-color-active: $gray-700
        );

        &.disabled {
            display: none;
        }

        &:hover:before {
            color: $c-darkgray;
        }
    }

    .remove-all-filters {
        font-size: $font-size-l;
        line-height: $font-size-xxxl;
    }

    .remove-all-filters:hover {
        color: $c-red;
        cursor: pointer;
    }

    .applied-filter {
        color: $c-darkgray;
        margin-right: $space-s;
    }

    .applied-filter label {
        line-height: $font-size-xxxl;
    }

    .ahri-table-toolbar .ahriTable_infoContainer,
    .ahri-table-toolbar .ahri-toolbar-filters {
        .toggle-area {
            position: relative;
        }

        .toggle-area-closed .toggle-content-wrapper {
            display: none;
        }

        .toggle-area-opened .toggle-content-wrapper {
            display: block;
            position: absolute;
            right: 0;
            top: 120%;
            z-index: 12;
        }

        .toggle-area-dropdown h3 {
            font-weight: bold;
            font-size: $font-size-base;
            margin-bottom: $space-xl;
        }

        .toggle-area-trigger {
            cursor: pointer;
            font-weight: bold;
            text-transform: uppercase;
        }

        .toggle-area-trigger > span:first-child {
            margin-right: $space-xxs;
        }

        .toggle-area-trigger:after {
            transform: rotate(0);
            transition: all .3s ease;
        }
    }

    .ahri-table-toolbar .ahriTable_infoContainer {
        .toggle-area-dropdown {
            padding: $space-base;
        }
    }

    .ahri-table-toolbar .ahri-toolbar-filters {
        .toggle-area-dropdown {
            padding: $space-xl $space-s $space-s;
        }

        .toggle-area-dropdown table {
            width: 100%;
        }

        .filter-action-add,
        .filter-action-apply {
            width: 100%;
        }

        .filter-action-add  .action.secondary.add-filter {
            background: $c-white;
            border: none;
            color: $c-gray-dark;
            font-weight: $font-weight-bold;
            margin-bottom: $space-base;
            text-transform: uppercase;

            &:before {
                position: relative;
                bottom: 2px;
            }

            &:hover {
                color: $c-darkgray;
            }

            &:disabled {
                color: $c-gray-lighter;
            }
        }

        .filter-action-add .add-filter {
            @include lib-icon-font(
                $_icon-font-content: $icon-expand,
                $_icon-font-size: $font-size-xxxl,
                $_icon-font-line-height: $font-size-xxxl,
                $_icon-font-vertical-align: middle
            );
        }

        .action.primary.apply-filter.update-not-required {
            background: $gray-600;
        }
    }

    .package-details {
        .label {
            color: $c-text;
            font-size: $font-size-base;
            margin-right: $space-xxs;
        }
    }

    .package-details-row {
        padding: 5px 0;
    }

    .product-inventory .stock .status:before {
        content: '';
    }

    .show-more-button {
        display: flex;
        margin-top: $space-xxl;
    }
}

@keyframes slideDown {
    0% {
        transform: translateY(-50%);
    }
    100% {
        transform: translateY(0%);
    }
}

// Ahri tab search by 'Ahri number'

.ahri-search-form .form-action {
    > button.MuiButton-containedPrimary {
        margin: 0;
    }
}

@media (min-width: $bp-screen-m) {
    .ahriTable {
        .ahri-table-wrapper {
            min-height: 400px;
        }

        .sticky-header-hidden,
        .table-scroll-actions-hidden {
            display: none;
        }

        .sticky-header-visible {
            overflow: hidden;
            position: sticky;
            top: 0;
            z-index: 1;
            animation: slideDown 200ms ease-in;
        }

        .table-scroll-actions {
            display: flex;
            height: 0;
            top: 46px;
            width: 100%;
            z-index: 1;
        }

        .slick-arrow {
            position: relative;

            &.slick-prev {
                left: -16px;
                top: 0;
            }

            &.slick-next {
                margin-left: auto;
                right: -16px;
                top: 0;
            }
        }

        .ahri-table-toolbar {
            padding-bottom: $space-xl;
        }

        .ahri-required-filters-summary {
            margin-top: $space-base;
        }

        .required-filters .required-filter-row {
            > div {
                flex: 1;
                margin-right: $space-xl;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .filters-selected .filter-cell-delete > .delete-icon {
            @include lib-icon-font(
                    $icon-trash,
                $_icon-font-size: $font-size-xxxl,
                $_icon-font-line-height: $font-size-xxxl,
                $_icon-font-text-hide: true,
                $_icon-font-color: $gray-700,
                $_icon-font-color-hover: $gray-700,
                $_icon-font-color-active: $gray-700
            );
        }

        .ahriTable_columnContainer > div {
            margin-right: 50px;
        }

        .ahri-toolbar-filters {
            margin: 0 30px 0 auto;
        }

        .ahri-toolbar-filters .filter-actions {
            display: flex;
            flex-wrap: wrap;
            padding: $space-xs 0 $space-xs;
            margin-top: $space-base;
        }

        .ahri-item-mobile,
        .ahri-content-mobile {
            display: none;
        }

        .table--linearize {
            border: $table-border;
            border-top: none;
        }

        .ahri-table-toolbar .filter-cell {
            padding: 0 $space-xxs $space-xxs 0;
        }

        .filter-cell.filter-cell-delete {
            padding: 0 0 $space-xxs $space-xxs;
        }

        .ahri-table-toolbar .ahriTable_infoContainer,
        .ahri-table-toolbar .ahri-toolbar-filters {
            .toggle-area-trigger {
                @include lib-icon-font(
                    $icon-pointer-down,
                    $_icon-font-position: after,
                    $_icon-font-color: $c-icon-base,
                    $_icon-font-display: flex
                );
            }

            .toggle-area-opened .toggle-area-trigger:after {
                transform: rotate(180deg);
            }
        }

        th {
            background: $c-white;
            z-index: 1;
        }

        td {
            text-align: center;

            &.ahri-table-cell-ahri_certified_ref {
                text-align: left;
                max-width: 400px;
                white-space: normal;
            }
        }

        td.ahri-table-cell-ahri_certified_ref .product-image-name {
            display: flex;
            width: 100%;

            .product-image {
                height: 60px;
                width: 60px;
                margin-right: $space-xs;
                margin-top: $space-xs;
            }
        }

        .ahriTable_systemSection {
            background: $c-gray-background;
        }

        .table--linearize tbody tr {
            border: $table-border;

            &:first-child {
                border-top: none;
            }

            &.ahri-item-units {
                border-bottom: none;
                border-top: none;

                label {
                    display: none;
                }
            }

            &.ahri-item-id {
                border-bottom: none;
            }

            &.ahri-item-id td {
                padding: $space-xxs $space-xs $space-xxs $space-base;
                text-align: left;
            }
        }

        .ahri-table-cell-ahri_certified_ref {
            padding-left: $space-base;
        }

        .table--linearize tbody tr.ahri-item-units .ahriTable_componentAction {
            border-left: $table-border;
            border-right: $table-border;
        }

        .table--linearize thead tr.ahriTable_head-sections,
        .table--linearize thead tr.ahriTable_head-columns {
            border-bottom: none;

            .ahriTable_componentAction > div {
                border-right: $table-border;
                margin-right: -1px;
                width: calc(100% + 1px);

                @-moz-document url-prefix() {
                    margin-right: 0;
                }

            }
        }

        .ahriTable_head-columns th,
        .ahriTable_head-sections th {
            height: 100%;
            padding: 0;

            &.ahriTable_componentSection {
                background: $c-white;
            }

            &.ahri-table-cell-ahri_certified_ref > div {
                padding-left: $space-base;
            }

            > div {
                border-top: $table-border;
                display: inline-block;
                padding:  $space-s $space-xs;
                width: 100%;
            }
        }

        .ahriTable_head-columns th > div {
            border-bottom: $table-border;
        }

        .table-duplicate .ahriTable_head-columns th > div {
            border-bottom: none;
            border-top: none;
        }

        .ahriTable_head-columns th {
            text-align: center;

            &.ahri-table-cell-ahri_certified_ref {
                text-align: left;
            }
        }

        .ahriTable_head-sections th {
            border-top: none;
            font-weight: bold;
            text-align: center;
        }

        .table--linearize thead tr.ahriTable_head-sections .ahriTable_componentSection,
        .table--linearize thead tr.ahriTable_head-sections .ahriTable_systemSection {
            .ahriTable_componentAction {
                border-right: $table-border;
            }
        }

        .ahri-table-cell-ahri_certified_ref {
            width: 22%;

            > a,
            > span {
                display: block;
                width: 100%;
            }
        }

        .ahriTable_componentAction {
            width: 170px;
        }

        td.ahriTable_componentAction {
            text-align: center;
        }

        .system-price {
            margin-top: $space-xxs;
        }

        .system-price .old-price {
            margin-top: -$space-xxs;
        }

        .ahri-table-toolbar .ahri-toolbar-filters {
            .toggle-area-dropdown {
                min-width: 430px;
            }
        }

        .ahri-table-cell {
            min-width: 40px;

            &-ahri_certified_ref {
                min-width: 270px;
            }

            &-eer,
            &-item_height,
            &-item_length,
            &-item_weight,
            &-item_width {
                min-width: 45px;
            }

            &-voltage {
                min-width: 85px;
            }

            &-Airflow {
                min-width: 85px;
            }

            &-availability {
                font-size: $font-size-s;
                min-width: 215px;
            }
        }

        .ahri-table-cell-availability .product-inventory {
            text-align: left;
        }

        .package-details {
            column-count: 2;
        }
    }

    .ahri-cell-copy {
        background: $c-white;
        position: absolute;
        z-index: 1;
    }

    .ahri-item-id .ahri-cell-copy {
        background: $gray-200;
    }

    .ahri-cell-original {
        opacity: 0;
    }

    .ahri-index-search {
        .ahriTable .ahri-table-cell-ahri_certified_ref {
            padding: 0;

            .ahri-cell-original,
            .ahri-cell-copy {
                padding: $space-s $space-base;
            }

            .ahri-cell-copy {
                border-left: $table-border;
                border-right: $table-border;
                left: 0;
            }
        }

        .ahriTable_head-sections th > div.ahri-cell-copy {
            border-left: $table-border;
            border-right: $table-border;
            left: 0;
        }

        .table-duplicate .ahri-table-cell-ahri_certified_ref {
            border-top: $table-border;
        }

        .table-duplicate .ahriTable_systemSection,
        .table-duplicate .ahriTable_head-columns th > div {
            border-bottom: $table-border;
        }

        .table-header-duplicate-wrapper,
        .table-sidescroll-wrapper {
            padding-right: $space-base;

            &:before {
                background: $c-gray-background;
                content: '';
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                width: $space-base;
            }

            &:after {
                border-bottom: 7px solid transparent;
                border-left: 7px solid $c-white;
                border-right: none;
                border-top: 7px solid transparent;
                content: '';
                height: 0;
                position: absolute;
                right: $space-xxs;
                top: $space-s;
                width: 0;
            }
        }

        .table-header-duplicate-wrapper {
            position: sticky;
            top: 0;
            z-index: 1;
        }
    }
}

@media (max-width: $bp-screen-m - 1) {
    .ahriTable {
        .sticky-header-visible,
        .sticky-header-hidden,
        .table-scroll-actions-hidden,
        .table-scroll-actions {
            display: none;
        }

        .ahri-item-desktop {
            display: none;
        }

        .required-filters {
            margin: $space-l (-$space-l);
        }

        .ahri-required-filters-intro {
            padding: 0 $space-l;
        }

        .ahri-required-filters-summary {
            padding: $space-base $space-l;
        }

        .required-filters .required-filter-row {
            border-bottom: 1px solid $c-border;
            flex-wrap: wrap;
            padding: 0 $space-l $space-s;
            width: 100%;

            &:last-of-type {
                border-bottom: none;
            }

            > div {
                min-width: 250px;
                margin-top: $space-base;
                width: 100%;
            }

            > .label {
                font-size: $font-size-xl;
                font-weight: normal;
                padding: 0 $space-s;
            }

            .label {
                left: -$space-s;
                position: relative;
                top: $space-xxs;
            }
        }

        .table--linearize thead {
            display: none;
        }

        .table--linearize {
            tr,
            th,
            td {
                display: block;
            }

            tbody td {
                padding: 0;
                border: 0;
            }
        }

        .table--linearize tbody tr {
            &.ahri-item-units {
                border-bottom: none;
                display: flex;
                flex-wrap: wrap;
            }

            &.ahri-item-units label {
                margin-right: 4px;
            }

            &.ahri-item-id {
                border-top: $table-border;
                font-weight: bold;
                margin-top: $space-base;
                padding: $space-xxs $space-xxs $space-xxs $space-xs;

                &:first-child {
                    border-top: none;
                }
            }

            &.ahri-item-id td {
                line-height: $font-size-l;
                position: relative;
                top: 1px;
            }

            &.ahri-item-action {
                border-bottom: none;
                padding-top: 0;
            }

            &.ahri-item-action .ahriTable_row-componentAction {
                display: block;
                width: 100%;
            }

            &.ahri-item-action .ahriTable_row-componentAction .action.primary {
                width: 100%;
            }

            .ahri-table-cell {
                line-height: 1.6;
            }

            .ahri-table-cell.ahri-table-cell-availability {
                display: flex;
                width: 100%;

                .product-inventory {
                    margin-top: 0;
                }

                .stock {
                    margin-top: 1px;
                }
            }

            .ahri-table-cell-ahri_certified_ref {
                display: flex;
                flex-wrap: wrap;
                padding: $space-xs 0;
            }

            .fullwidth {
                width: 100%;
            }

            .ahri-table-cell-ahri_certified_ref span {
                margin-top: $space-xs;
            }

            .ahriTable_componentSection,
            .ahriTable_systemSection {
                width: 50%;
            }

            .ahriTable_componentAction {
                display: none;
            }

            .system-price {
                display: block;
                margin-top: $space-s;

                > span {
                    margin-left: $space-xxs;
                }
            }
        }

        .ahri-table-toolbar {
            flex-wrap: wrap;

            .ahri-toolbar-applied-filters {
                order: 3;
            }

            .ahri-toolbar-filters {
                order: 1;
            }

            .ahriTable_infoContainer {
                order: 2;
            }
        }

        .filter-row {
            padding: $space-base $space-s $space-l;
            border-bottom: 1px solid $c-gray-lighter;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .filters-selected tbody {
            margin: 0 (-$space-s);
        }

        .ahri-table-toolbar .filter-cell {
            padding: 0 0 $space-s 0;

            &.filter-cell-name {
                order: 2;
            }

            &.filter-cell-condition {
                order: 3;
            }

            &.filter-cell-value {
                order: 4;
            }

            &.filter-cell-delete {
                order: 1;
            }
        }

        .filters-selected .filter-cell-delete {
            display: flex;
        }

        .filters-selected .filter-cell-delete > .delete-icon {
            margin-left: auto;
            @include lib-icon-font(
                $icon-trash,
                $_icon-font-size: $font-size-xxxll,
                $_icon-font-line-height: $font-size-xxxll,
                $_icon-font-text-hide: true,
                $_icon-font-color: $gray-700,
                $_icon-font-color-hover: $gray-700,
                $_icon-font-color-active: $gray-700
            );
        }

        .filters-selected .filter-cell-delete > .label {
            color: $c-text;
            font-size: $font-size-xl;
            font-weight: $font-weight-normal;
            line-height: $font-size-xxxll;
        }

        .ahriTable_columnContainer > div {
            width: 50%;
        }

        .ahri-toolbar-applied-filters {
            margin: $space-base 0 0;
            width: 100%;
        }

        .applied-filter,
        .remove-all-filters {
            margin-bottom: $space-s;
        }

        .remove-all-filters {
            width: 100%;
        }

        .ahri-toolbar-filters,
        .ahriTable_infoContainer {
            width: 50%;
        }

        .ahri-toolbar-filters .filter-actions {
            margin: $space-xl (-$space-s);
            padding: 0 $space-s;
            border-bottom: 1px solid $c-gray-lighter;
            width: calc(100% + (2 * #{$space-s}));

            .filter-action-add,
            .filter-action-apply {
                text-align: center;
                width: 100%;
            }

            .filter-action-apply {
                padding-bottom: $space-xxxl;
            }

            .filter-action-apply button.apply-filter {
                width: 100%;
            }
        }

        .filters-container {
            padding-bottom: $space-xxxl;
            margin-bottom: $space-xxxl;
        }

        .ahri-table-toolbar .ahriTable_infoContainer,
        .ahri-table-toolbar .ahri-toolbar-filters {
            .toggle-area-opened .toggle-content-wrapper {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                overflow: auto;
            }

            .toggle-content-wrapper-title {
                display: block;
                font-size: $font-size-xxxl;
                margin-bottom: $space-base;
            }

            .ahriTable_close {
                text-align: right;
                width: 100%;

                @include lib-icon-font(
                    $_icon-font-content: $icon-clear,
                    $_icon-font-size: $font-size-xxxll,
                    $_icon-font-line-height: $font-size-xxxll,
                    $_icon-font-color: $c-darkgray,
                    $_icon-font-position: after
                );
            }

            .ahriTable_close span {
                font-size: $font-size-xs;
                text-decoration: underline;
            }

            .toggle-area-trigger {
                text-align: center;
            }
        }

        .filters-selected {
            table,
            tbody,
            tr,
            td {
                display: block;
            }

            thead {
                display: none;
            }

            tr {
                display: flex;
                flex-wrap: wrap;
            }

            td {
                width: 100%;
            }
        }

        td.ahri-table-cell-ahri_certified_ref .product-image-name {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            > a,
            > span {
                width: 100%;
            }

            .product-image {
                margin: $space-xs auto;
            }
        }

        .matches-found-summary .product-mfg {
            display: none;
        }
    }

    .ahri-index-search {
        .ahri-cell-copy {
            display: none;
        }

        .ahriTable .table--linearize tbody tr .ahri-table-cell-ahri_certified_ref {
            .ahri-cell-copy {
                display: none;
            }
        }

        .ahriTable .table--linearize td.ahri-item-blank-columns {
            display: none;
        }
    }
}

// ==============================================
// AHRI Details Page
// ==============================================

.ahri-details_table {
    width: 100%;
    margin-bottom: 0;

    .order-total {
        font-weight: $font-weight-bold;
    }
}

.ahri-details_actions-title {
    font-weight: $font-weight-bold;
}

.ahri-details_summary,
.ahri-details_tocart,
.ahri-details_quickorder {
    margin-bottom: 0;
    width: 100%;
}

.ahri-details_tocart .action.tocart {
    font-size: $font-size-xl;

    &.secondary > span {
        color: $gray-300;
    }
}

.ahri-details_tocart {
    .tocart {
        width: 100%;
    }
}

.ahri-details_quickorder {
    display: flex;

    .menu > button {
        width: 100%;
    }

    button {
        background: $c-white;
    }
}

.ahri-details-view .block-requisition-list {
    margin: auto;

    .menu .actions-group {
        position: static;
    }

    .dropdown-options {
        border: 1px solid $c-border;
        box-shadow: $box-shadow-base;
        width: 100%;
    }
}

.ahri-details-view .requisition-list {
    display: flex;
    justify-content: center;
}

$my-lists-dropdown_width: 300px;
.ahri-details_actions .my-lists-dropdown-trigger {
    text-align: center;
    width: $my-lists-dropdown_width;
}

// ----------------------------------------------
// Mobile

@media (max-width: $bp-screen-m - 1px) {

    .ahri-details h2 {
        margin-left: $space-base;
    }
}

// ----------------------------------------------
// Linearized tabled

@media (max-width: $bp-table-linearize - 1px) {
    .table--linearize tbody .ahri-table-cell_accessory--empty {
        display: none;
    }

    .table--linearize tbody tr.ahri-item-mobile.ahri-item-action {
        border-top: none;
    }
}

// ----------------------------------------------
// Desktop

@media (min-width: $bp-screen-m) {

    [data-sidescroll="scroll"] #ahri-scroll2-content.gradient:after {
        content: " ";
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        width: 20%;
        background: linear-gradient(to right, transparent, white);
    }

    .ahri-details-view .page-title-wrapper {
        padding: 0 45px;
    }

    .ahri-details {
        display: flex;
        justify-content: center;
    }

    .ahri-details_content {
        flex: 0 1 960px;
    }

    .ahri-details_sidebar {
        flex: 1 0 320px;
        max-width: 400px;
        margin-left: 40px;
    }

    .ahri-details_actions {
        position: sticky;
        top: $space-xxl;
    }

    .ahri-details_info {
        tbody {
            display: block;
            column-count: 2;
        }

        tr {
            display: inline-block;
            padding: 2px 0;
            width: 100%;
        }

        th, td {
            padding: 0 10px 0 0;
        }

        th:after {
            content: ':';
        }
    }
}

// ----------------------------------------------
// Ahri Related Products
// ----------------------------------------------

$related-item-selection_color: $peirceblue-800;

.ahri-details-view .ahri-related-content {
    .ahri-related-empty,
    .ahri-loading {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: $space-xl;
    }

    .ahri-loading {
        > span:first-child {
            margin-right: $space-base;
        }

        .simple-loading {
            margin: 0;
        }
    }

    .ahri-details_group,
    .ahri-related-info,
    .ahri-related-details,
    .pricing-data {
        display: flex;
    }

    .ahri-details_group {
        border-top: 1px solid $c-border;
        padding: $space-s 0;

        &:first-of-type {
            border-top: none;
            padding: 0 0 $space-s;
        }

        &:last-of-type {
            padding: $space-s 0 0;
        }
    }

    .show-more-row {
        border-top: 1px solid $c-border;
        padding: $space-s 0;
    }

    .ahri-related-info {
        flex-wrap: wrap;
    }

    .ahri-related-actions {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        min-width: 200px;

        .ahriDetails_inventory {
            margin-top: auto;
        }
    }

    .col--name,
    .ahri-related-details {
        width: 100%;
    }

    .ahri-related-info .col--name a {
        color: $c-text;
    }

    .col--product-attributes {
        padding: $space-s $space-xs;
    }

    .product-attribute_value {
        margin-bottom: 0;

        .label {
            margin-right: $space-xxs;
        }

        .value {
            color: $c-text;
        }
    }

    .pricing-data {
        align-items: baseline;
        justify-content: flex-start;
        margin-bottom: $space-xxs;

        .price {
            font-size: $font-size-l;
            font-weight: $font-weight-bold;
        }

        .label {
            display: none;
        }
    }

    .ahri-related-select {
        display: flex;
        justify-content: flex-end;
        margin-bottom: $space-xs;
        padding: 0 $space-xs $space-xxs;

        .ahri-related-item-selection {
            border: 1px solid $related-item-selection_color;
            padding-left: $space-s;
        }

        .ahri-related-item-selected {
            border: none;
        }

        .MuiFormControlLabel-label {
            color: $related-item-selection_color;
            font-size: $font-size-s;
            font-weight: $font-weight-semibold;
        }

        .MuiSvgIcon-root {
            color: $related-item-selection_color;
        }

        .field.qty {
            margin-bottom: 0;
            padding-top: $space-xxs;
        }

        .field.qty .control {
            margin-top: 0;
        }

        .field.qty label {
            margin-right: $space-xxs;
            position: static;
        }
    }
}

@media (min-width: $bp-screen-m) {
    .ahri-details-view .ahri-related-content {
        .ahri-related-info {
            max-width: 70%;
            padding-right: $space-l;
        }

        .ahri-related-info .col--name h3 {
            font-size: $font-size-l;
            margin-bottom: 0;
        }

        .ahri-related-actions {
            padding: 0 $space-xs $space-s;
            width: 30%;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .ahri-details-view .ahri-related-content {
        .ahri-details_group {
            flex-wrap: wrap;
            padding-bottom: $space-base;
        }

        .ahri-related-info .col--name h3 {
            font-size: $font-size-l;
        }

        .ahri-related-actions {
            width: 100%;

            .pricing-data {
                order: 1;
                margin-top: $space-xs;
            }

            .ahriDetails_inventory {
                margin-bottom: $space-xs;
                order: 2;
            }

            .ahri-related-select {
                order: 3;
            }

            .ahri-related-select .MuiFormControlLabel-labelPlacementStart {
                margin-left: 0;
                margin-right: -$space-xs;
            }
        }
    }
}

// ---------------------------------------------
// Units Section
// ---------------------------------------------

.ahri-unit-group,
.ahri-related-details {
    .col--image {
        width: 94px;

        img {
            max-width: 78px;
            max-height: 78px;
        }
    }
}

.ahri-unit-group {
    .col--name {
        a {
            color: $c-text;
            font-size: $font-size-l;
        }

        .product-attribute {
            margin-top: $space-xs;
        }
    }

    .col--inventory {
        width: 30%;
        min-width: 200px;
    }

    .product-inventory {
        margin-top: 0;
    }

    h2 {
        margin-bottom: $space-xs;
    }

    .unit-item {
        .MuiFormControlLabel-label.MuiTypography-body1 {
            font-size: $font-size-sl;
        }

        .MuiRadio-colorSecondary {
            padding: 7px 9px 9px;
        }

        .MuiSvgIcon-root {
            font-size: $font-size-xl;
        }
    }

    .show-more-row {
        td {
            border-bottom: none;
        }
    }
}

@media (min-width: $bp-screen-m) {

    .ahri-unit-group {
        .unit-item {
            border-top: 1px solid $c-border;

            &:first-of-type {
                border-top: none;
            }
        }

        h2 {
            margin: $space-xs 0 $space-s;
        }

        .ahri-table-cell_accessory--empty,
        .ahri-table-cell_accessory,
        .col {
            border-bottom: none;
            border-top: none;
        }

        .table--linearize {
            margin-bottom: 0;
        }
    }

}

// Table linearize, Mobile View

@media (max-width: $bp-screen-m - 1px) {

    .ahri-unit-group {
        .table--linearize {
            margin-bottom: 0;
        }

        .table--linearize tbody tr {
            &.unit-item {
                border-top: 1px solid $c-border;

                .col--name {
                    margin-top: $space-base;
                }
            }

            td {
                border-bottom: none;
                border-top: none;
            }

            &:first-child,
            &.item-accessories {
                border-top: none;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

// Table linearize

@media (max-width: $bp-table-linearize - 1px) {

    .ahri-unit-group {
        .col--inventory {
            width: 100%;
            margin-top: $space-s;
        }
    }

}

@media (max-width: $bp-screen-m - 1px) {
    .ahri-unit-group {
        .col.image {
            margin-bottom: $space-s;
        }

        .product-attribute {
            padding-top: $space-xs;
        }
    }
}

// Ahri Unit View Substitutes

@media (min-width: $bp-screen-m) {
    .ahri-table-cell {
        .ahri-unit-view-substitutes {
            margin: $space-xs 0 $space-xxxs;
            width: 100%;
        }

        .ahri-unit-view-substitutes .view-substitutes.button {
            font-size: $font-size-s;
            padding: $space-xxsl $space-s;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .ahri-table-cell {
        .ahri-unit-view-substitutes {
            margin: $space-xs;
            text-align: center;
            width: 100%;
        }
    }
}
