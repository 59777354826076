// ==============================================
// Flex Center
// ==============================================

// Used on a parent to visually center the child element(s).

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

// ==============================================
// Flex Ghost
// See: https://codepen.io/brendanfalkowski/pen/pebayb
// ==============================================

// Used as a direct child of a `display: flex` parent with `flex-wrap: wrap`,
// add as many instances of this element as (n-1) items fit along the main axis.
// This prevents an uneven number of items on the last row/column from being
// distributed unlike other rows/columns.

.flex-ghost {
    height:         0 !important;
    margin-top:     0 !important;
    margin-bottom:  0 !important;
    padding-top:    0 !important;
    padding-bottom: 0 !important;
    border:         0 !important;
}


// ==============================================
// Flex Spacer
// ==============================================

// Used as a direct child of a `display: flex` parent, this element expands to fill
// the available space along the main axis.

.flex-spacer {
    flex: 1;
}

.flex-none {
    flex: none !important;
}

.flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-items-center {
    align-items: center;
}

.flex-items-baseline {
    align-items: baseline;
}

.flex-left {
    justify-content: flex-start;
}

.flex-center {
    justify-content: center;
}

.flex-right {
    justify-content: flex-end;
}

@mixin flex($n) {
    .flex-#{$n} {
        flex: $n;
    }
}

@include flex(1);
@include flex(2);
@include flex(3);
@include flex(4);
@include flex(5);
