// ==============================================
// Block Banners
// Widget with dynamic banners rotator block
// ==============================================

.block-banners        .banner-item,
.block-banners-inline .banner-item {
    display: block;
}

.block-banners        img,
.block-banners-inline img {
    display: block;
    margin: 0 auto;
}
