@mixin my-lists-minimal-template {
    .my-lists-selection {
        display: flex;
        flex-direction: column;
    }
    .my-lists-search {
        display: none;
    }

    .my-lists-selection {
        display: flex;
    }

    .my-lists-actions {
        max-height: 24px;
        padding: 0;

        .my-list-create {
            padding: $space-xxxs;
            height: 24px;
            min-width: 100%;
        }

        .my-list-view-all {
            display: none;
        }
    }

    .my-lists-selection .my-lists-list {
        max-height: 130px;
        width: 100%;
    }

    .my-lists-dropdown-content {
        min-width: 200px;
    }
}
