// ==============================================
// Account Dropdown - Wrap
// ==============================================

.account-link {
    align-items: flex-end;
    background: $gray-50;
    color: $c-header-text-color;
    flex-grow: 1;
    order: 10;
    padding: 11px $space-xxl 5px $space-base;
    position: relative;
}

.account-link a {
    color: $c-white;
    text-decoration: none;
}

.account-link .dropdown-trigger > span {
    font-weight: $font-weight-bold;
}

@media (max-width: $bp-screen-m - 1px) {

    .account-link {
        display: none;
    }
}

.account-link p {
    color: $c-header-text-color;
    margin: 0;
    font-size: $font-size-xs;
    line-height: $font-size-xs;
}

// ==============================================
// Account Dropdown
// ==============================================

.authorization-link {;
    border-top: 1px solid $c-border-light;
}

.account-dropdown .authorization-link {;
    padding: $space-xs $space-base;
}

.account-dropdown .authorization-link a {
    color: $c-text;
}

.account-dropdown:before {
    @include triangle($direction: up, $size: 8px, $color: $c-border);
    top: -8px;
    right: 22px;
}

.account-dropdown:after {
    @include triangle($direction: up, $size: 6px, $color: $c-white);
    top: -6px;
    right: 24px;
}

@media (max-width: $bp-screen-m - 1px) {
    .authorization-link {;
        border-top: none;
    }

    .account-dropdown {
        width: 100%;
    }
}

@media (min-width: $bp-screen-m) {
    .account-dropdown-erp {
        width: 402px;
    }

    .account-dropdown_links a {
        display: block;
        color: $c-text;
        padding: $space-xs $space-base;
    }

    .account-dropdown_links a:hover {
        background: $peirceblue-400;
        color: $c-white;
        text-decoration: none;
    }

    .account-dropdown_company {
        border-left: 1px solid $c-border;
    }

    .account-dropdown_myaccount {
        width: 100%;
    }

    .account-dropdown_links .nav.item.current a {
        font-weight: $font-weight-bold;
    }
}



// ==============================================
// Account Dropdown Links
// ==============================================


@media (min-width: $bp-screen-m) {
    .account-dropdown-erp .account-dropdown_links {
        width: 200px;
    }

    .account-dropdown ul li.authorization-link-row > a {
        &:before {
            content: '';
            margin-right: 0;
        }

        &.icon-login-outlined > span {
            height: auto;
            position: relative;
            width: auto;
        }
    }

    .account-dropdown-container.dropdown-trigger:after {
        bottom: $space-s;
        font-size: $font-size-xxl;
        line-height: 22px;
        position: absolute;
        right: 9px;
    }

    .account-dropdown.dropdown-content {
        margin-top: $space-s;
        right: -$space-s;
    }
}
