.algolia-search.block-search .block-content {
    padding-bottom: 0;
}

.algolia-search .form.minisearch {
    margin-bottom: 0;
}

.algolia-search .truncate.grow, .algolia-search .truncate.grow .text-sm {
    color: rgba(0,0,0,.87) !important;
}

#productSuggestions #products-collection .products-list {
    border-top: 1px solid #e9e9e9;
}

.algolia-button {
    background: $c-library-blue-11;
    border: none;
    width: 70px;
    color: white;
}

.algolia-button > svg {
    transform: translateY(3px)
}

.algolia-search-bar #product-card__root .flex {
    height: 62px;
}

.algolia-search-bar .truncate.grow {
    flex-grow: 1;
}

.algolia-search.block-search #productSuggestions #product-card__root .fieldset {
    align-items: center;
}

.algolia-search.block-search #productSuggestions #product-card__root .actions {
    margin-top: 0 !important;
}

// DMI Searchbar
#stocking-list-typeahead .algolia-search-bar {
    input {
        border: 1px solid $gray-200;
        padding-left: 8px;
        height: 100%;
    }

    #autoSuggest {
        border-top: none;
    }
}

.form.minisearch form [name="search-input"] {
    border-radius: 0;
}
