.developer-hint {
    display: flex;
    align-items: center;
    margin: $space-xl 0;
    padding: $space-xs $space-s;
    border: 1px solid $c-library-violet-2;
    border-radius: 5px;
    background: $c-library-violet-0;
}

@media print {
    .developer-hint {
        display: none;
    }
}

.developer-hint-label {
    width: 50px;
    color: $c-library-violet-7;
    font-size: $font-size-xxs;
    font-weight: bold;
    line-height: $line-height-xxs;
}

.developer-hint-content {
    flex: 1;
}

.developer-hint-content > *:last-child {
    margin-bottom: 0;
}
