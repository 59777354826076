$catalog-product-item-info-width: 256px;
$product-list_margin-right: 30px;
$product-list-sidebar_margin-right: $space-l;
$product-list-main-column_width: calc(
    100% - #{$catalog-product-item-info-width} - #{$product-list_margin-right} - #{$product-list_sidebar_margin_right}
);

@media (min-width: $bp-columns) {
    .page-products .catalog-app__main {
        .sidebar-main {
            width: $catalog-product-item-info-width;
            margin-right: $product-list-sidebar_margin-right;
        }

        .column.main {
            margin-right: $product-list_margin-right;
            width: $product-list-main-column_width;
        }
    }
}

.algolia-product-list .list .item {
    padding-right: 0;
}

// Product Inventory

.product-inventory.product-inventory-component {
    padding: $space-s;

    .inventory-label {
        display: flex;
        margin-bottom: $space-xxsl;
    }
}

// Overflow on body tag prevent sticky header for product list view.
body.search-index-index,
body.catalog-category-view {
    overflow: unset !important;
}
