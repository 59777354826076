// ==============================================
// My Lists Dropdown Trigger
// ==============================================

.my-lists-dropdown-trigger .dropdown-trigger::after {
    line-height: 21px;
}

.catalog-product-view .product-info-sidebar .my-lists-dropdown-trigger > a {
    color: $c-text;
}

// ==============================================
// My Lists Dropdown Content
// ==============================================

$my-lists-list_max-height: 380px;
$my-lists-dropdown_width: 300px;

.my-lists-dropdown-content {
    margin-top: 5px;
    min-width: $my-lists-dropdown_width;
    position: absolute;
    z-index: 1;
}

.algolia-product-list .my-lists-dropdown-content {
    right: -$space-xxl;
    z-index: 3;
}

@media (min-width: $bp-screen-m) {
    .catalog-product-view .product-info-sidebar .my-lists-dropdown-content {
        right: 0;
    }
}

@media (max-width: $bp-screen-m - 1) {
    .quickorder-index-index .quickorder-result .my-lists-dropdown-content {
        left: -100px;
    }
}

.slick-slide .my-lists-dropdown-content {
    bottom: $space-xxl;
}

.my-lists-selection {
    .my-lists-search {
        padding: $space-base $space-base $space-xs;
    }

    .my-lists-list {
        max-height: $my-lists-list_max-height;
        overflow-y: scroll;
        padding: $space-xs 0 $space-base;
    }

    .my-lists-item {
        padding: $space-xxs $space-base;

        &.MuiListItem-button:hover {
            background-color: $c-library-orange-1;
        }

        .MuiListItemText-root {
            margin: 0;
            padding: 0;
        }

        .simple-loading {
            height: $font-size-xl;
            width: $font-size-xl;
        }
    }

    .my-lists-item-more {
        justify-content: center;
        width: 100%;
    }
}

.my-lists-dropdown .my-lists-item .MuiListItemText-primary {
    font-size: $font-size-sl;
}

// ==============================================
// My Lists Dropdown Content Actions
// ==============================================

.my-lists-actions {
    border-top: 1px solid $c-border;
    display: flex;
    padding: $space-base;
    justify-content: space-between;

    .button {
        flex: 1;
        max-width: 49%;
    }
}

// ==============================================
// Create My Lists Form
// ==============================================

.create-my-list-form {
    .create-my-list-description {
        textarea.input-text {
            font-size: $font-size-sl;
        }

        textarea.input-text:not([value]) ~ label {
            font-size: $font-size-sxl;
            top: $space-xxsl;
        }

        textarea.input-text:focus,
        textarea.input-text:hover {
            border-color: $c-primary-dark;
        }

        textarea.input-text:focus ~ label,
        textarea.input-text.hasValue ~ label {
            font-size: $font-size-s;
            left: $space-xxsl;
            padding: $space-xxs;
            top: -$space-s;
            width: auto;
        }

        textarea.input-text:focus ~ label {
            color: $c-primary-dark;
        }
    }

    .simple-loading {
        margin-left: $space-l;
    }
}

// ==============================================
// Header My Lists
// ==============================================

.header-links-quicklists .quicklist-dropdown-content {
    padding: 0;

    > form {
        margin: $space-l $space-base $space-xs;
        width: auto;

        > .field {
            margin-bottom: 0;
        }

        > .field > .control {
            margin-top: 0;
        }

        .input-text {
            border: 1px solid $c-border;
            height: auto;
            padding: $space-xs;

            ~ label {
                background-color: $c-white;
                padding: 0 3px;
                left: $space-xs;
            }
        }

        .input-text:focus,
        .input-text.hasValue {
            border-color: $c-black;

            ~ label {
                left: 4px;
                top: -4px;
                color: $c-primary-dark;
            }
        }
    }

    .header-quicklists_item {
        padding: $space-xxs $space-base;
        margin-bottom: 0;

        &:hover {
            background-color: $c-library-orange-1;
        }
    }

    .header-quicklists_item .item-name {
        font-size: $font-size-sl;
    }

    .header-quicklists_item a:hover {
        font-weight: $font-weight-normal;
    }

    .dropdown-footer {
        margin: $space-s 0 0;
        padding: $space-base;
        display: flex;

        > .button {
            flex: 1;
        }

        .my-list-view-all {
            margin-right: $space-xxs;
        }

        .my-list-create {
            margin-left: $space-xxs;
            color: $c-primary;

            &:hover {
                font-weight: $font-weight-normal;
            }
        }
    }
}

// -----------------------------------------------------
// My List details page 'Move To' and 'Copy To' actions
// -----------------------------------------------------

.sales-order-view .my-lists-dropdown-content,
.requisition_list-requisition-view .my-lists-dropdown-content {
    left: -10px;
    padding: $space-xs 0 $space-xxs;

    .my-lists-item:hover {
        background-color: $c-library-orange-1;
    }

    .my-lists-item .item-name {
        padding: 0;
        color: $c-text;
        font-size: $font-size-sl;

        &:hover {
            font-weight: $font-weight-normal;
        }
    }

    .dropdown-footer:hover {
        background-color: inherit;
    }

    .dropdown-footer {
        margin: $space-s 0 0;
        padding: $space-base;
        display: flex;

        > .button {
            flex: 1;
        }
    }
}

@media (max-width: $bp-screen-m - 1) {
    .sales-order-view .my-lists-dropdown-content,
    .requisition_list-requisition-view .my-lists-dropdown-content {
        left: -$space-base;
        max-width: 200px;
        min-width: 150px;
    }
}
