// ==============================================
// OPC Block Summary
// ==============================================


.opc-block-summary h2 {
    padding: $space-base $space-base 0 $space-base;
}

// ----------------------------------------------


@media (max-width: $bp-screen-m - 1px) {
    .opc-block-summary h2 {
        display: none;
    }
}

.opc-block-summary .not-calculated {
    font-style: italic;
}

.opc-block-summary .opc-table-totals {
    background: transparent;
}

// ----------------------------------------------


@media (min-width: $bp-screen-m) {
    .opc-block-summary .opc-table-totals th {
        white-space: normal
    }
}


// ==============================================
// OPC Estimate Wrapper
// ==============================================

.opc-estimated-wrapper {
    display: none;
}


.opc-totals-table {
    padding: $space-base;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .opc-totals-table {
        border: 1px solid $c-border-light;
        margin: 0 $space-base;
        background: $c-white;
    }
}


// ==============================================
// OPC action
// ==============================================

.opc-summary-action {
    padding: $space-base;
    border-bottom: 1px solid $c-border-light;

    .flat-button:not(:hover) {
        background: $c-white;
    }
}

// ---------------------------------------------

.checkout-container .terms-of-sale {
    margin-bottom: $space-s;

    > span {
        color: $c-text-muted-dark;
        margin-right: 2px;
    }
}
