.accordion-details.MuiAccordionDetails-root {
    padding: $space-base 0 0;
}

.MuiAccordionSummary-root.accordion-summary {
    background: $gray-100;

    &:hover {
        background: $gray-200;
    }
}

.MuiTypography-root.summary-content {
    font-size: $font-size-base;
}
