//
//  Default appearance styles
//  _____________________________________________

[data-content-type='products'] {
    .product-item-name.carousel-name {
        height: 95px !important;
    }
}

.widget-product-grid {
    .product-item {
        max-width: 100%;
        min-width: 50px;

        .price-box {
            word-wrap: break-word;

            .price {
                white-space: normal;
            }
        }

        .product-price-wrapper {
            height: $space-base;
        }

        .product-item-actions {
            .actions-primary {
                display: inline-block;
                width: 100%;
            }

            .actions-secondary {
                display: inline-block;
                width: auto;
            }

            .tocart {
                max-width: 100%;
                white-space: normal;
            }
        }
    }
}

.pagebuilder-column {
    [data-content-type='products'] {
        .block.widget {
            .products-grid {
                .widget-product-grid.product-items {
                    .product-item {
                        min-width: 200px;
                        width: 200px;
                    }
                }
            }
        }
    }
}

.block.widget .products-grid .product-items.widget-product-grid,
.product-items.widget-product-carousel {
    .product-item {
        padding: $space-xs;

        .price-box .price {
            white-space: normal;
        }
    }

    [data-role=tocart-form] {
        display: flex;
    }

    .product-inventory__availability {
        color: $c-red;
    }
}

// ------------------------------------
// Product Grid My Lists Link
// ------------------------------------

.block.widget .products-grid .product-items {
    .block-requisition-list {
        margin-top: $space-base;
    }

    .my-lists-dropdown-content {
        z-index: 2;
    }
}

.block.widget .products-grid .product-items .block-requisition-list {
    .menu_content {
        bottom: $space-xxl;
        top: auto;
    }
}

@media (min-width: $bp-screen-l) {
    .block.widget .products-grid .product-items {
        .my-lists-dropdown-content {
            left: -$space-l;
        }
    }
}

@media (max-width: $bp-screen-l - 1) {
    .block.widget .products-grid .product-items {
        .product-item:nth-child(4n) {
            .block-requisition-list .my-lists-dropdown-content {
                right: -18px;
            }
        }
    }
}

@media (max-width: $bp-screen-s - 1) {
    .block.widget .products-grid .product-items {
        .product-item:nth-of-type(2n) {
            .block-requisition-list .my-lists-dropdown-content {
                right: -18px;
            }
        }
    }
}
