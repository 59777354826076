/**
* Baker Distributing
*/

// ----------------------------------------------
// Events

.events {
    display: flex;
    flex-wrap: nowrap;
}

.events-filter {
    display: block;
    flex: 0 0 250px;
    width: 250px;
    padding: $space-base;
    margin-top: -$space-xxl;
    margin-bottom: -$space-xxl;
    margin-left: -$layout-indent__width;
    margin-right: $layout-indent__width;
    background: white;
}

@media (max-width: $bp-screen-m - 1px) {
    .events-filter {
        display: none;
        flex: 1 1 auto;
        max-width: 100%;
        padding: 0;
        margin: 0;
    }
}

.events-filter_trigger {
    position: absolute;
    right: $layout-indent__width;
    margin-top: -48px;
}

@media (max-width: $bp-screen-m - 1px) {
    .events-filter_trigger {
        right: $layout__width-xs-indent;
    }
}

.events-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: $font-size-xl;
    letter-spacing: $letter-spacing;
    padding: 22px 0;
}

.events-calendar {
    flex: 1 1 auto;
    width: auto;
}


// ----------------------------------------------
// Calendar

.calendar {
    display: block;
    position: relative;
    width: 100%;
    background: $c-white;
}

.calendar-header {
    border-bottom: 1px solid $c-border-light;
}



// ----------------------------------------------
// Calendar Grid

.calendar-row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.calendar-row_middle {
    align-items: center;
}

.calendar-col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}


.calendar-col_center {
    justify-content: center;
    text-align: center;
}

.calendar-days {
    text-transform: uppercase;
    font-weight: 400;
    color: $c-white;
    padding: $space-xxxs;
    background: $c-darkgray;
}

.calendar-cell {
    height: 120px;
    border-right: 1px solid $c-border-light;
    overflow: hidden;
    background: $c-white;
}

calendar-cell:hover {
    background: lighten($c-lightestgray, 3%);
    transition: $transition-background;
}

.calendar-body .calendar-row {
    border-bottom: 1px solid $c-border-light;
}

.calendar-body .calendar-row:last-child {
    border-bottom: none;
}

.calendar-body .calendar-cell:last-child {
    border-right: none;
}

.calendar-body .calendar-day_number {
    background: $gray-100;
    padding: $space-xxxs $space-xs;
    font-weight: $font-weight-bold;
    transition: background-color 0.25s ease-out;
}

.calendar-body .calendar-cell:hover .calendar-day_number {
    background: darken($c-lightestgray, 5%);
    transition: $transition-background;
}

.calendar-body .calendar-cell--today .calendar-day_number {
    background: lighten($c-orange, 20%);
    transition: $transition-background;
}

.calendar-body .calendar-cell--today:hover .calendar-day_number {
    background: lighten($c-orange, 10%);
    transition: $transition-background;
}

.calendar-body .calendar-cell--disabled {
    color: $c-darkgray;
}
.calendar-body .calendar-cell--disabled .calendar-day_number {
    background: $c-lightestgray;
}


.calendar-body .calendar-col {
    flex-grow: 0;
    flex-basis: calc((100%/7) - 1px); //fix for IE 11
    width: calc((100%/7) - 1px); //fix for IE 11
    box-sizing: content-box;
}



// ----------------------------------------------
// Calendar Events

.calendar-all_events a,
.calendar-all_events a:hover,
.calendar-events > a:not(.calendar-events_show-all),
.calendar-events > a:not(.calendar-events_show-all):hover{
    color: $c-text;
    text-decoration: none;
}

.calendar-events {
    padding: $space-xxxs;
}

.calendar-event {
    background: $blue-100;
    padding: $space-xxxs;
    margin: 0 0 $space-xxxs 0;
    width: 100%;
    font-size: $font-size-xs;
    overflow: hidden;
    white-space: nowrap;
    display: inline-flex;
    box-sizing: border-box;
    transition: $transition-background;
}

.calendar-all_events a:hover .calendar-event,
.calendar-events > a:not(.calendar-events_show-all):hover .calendar-event {
    background: darken($blue-100, 5%);
    transition: $transition-background;
}

.calendar-event_time {
    font-weight: $font-weight-bold;
}

.calendar-all {
    position: absolute;
    max-width: 300px;
    z-index: $z-index-9;
    margin-top: -118px;
    background: $c-white;
    border: 1px solid $c-border;
    padding-bottom: $space-xxxs;
    animation: scale-up-topleft 0.1s both;
}

.calendar-all_events {
    max-height: 200px;
    overflow-y: auto;
}

.calendar-all_title {
    background: $c-lightgray;
    padding: $space-xxxs;
    font-weight: $font-weight-bold;
}

.calendar-all_close {
    @include lib-button-icon(
                    $icon-close,
            $_icon-font-color       : $gray-700,
            $_icon-font-size        : 18px,
            $_icon-font-line-height : 24px,
            $_icon-font-text-hide   : true
    );
    position: absolute;
    right: 0;
    top: 0;
    padding-right: $space-xs;
    cursor: pointer;

    &:hover {
        &::before {
            color: darken($c-link, 10%);
        }
    }
}

// ----------------------------------------------
// List of events by month

.events-list {
    display: block;
    flex: 1 1 auto;
    width: auto;
}

@media (max-width: 800px) {
    .events-list {
        border: none;
    }
}

.events-list_header {
    display: flex;
    justify-content: space-between;
    background: $c-white;
}

.events-list .events-list_header {
    border: 1px solid $c-border-light;
}

@media (max-width: 800px) {

    .events-list_header {
        margin-top: -$space-xxl;
        background: transparent;
    }
}

@media (max-width: 800px) {
    .events-list_header-controls {
        order: 2;
    }
}

@media (max-width: 800px) {
    .events-list_today,
    .events-list_header-actions{
        display: none;
    }

    .events-list_header-title {
        order: 1;
    }
}

.events-list_date {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: $space-xxs $space-xs;
    background: lighten($c-lightgray, 5%);
}

.events-list_month-day,
.events-list_weekday {
    font-weight: $font-weight-semibold;
}

.events-list_event {
    display: flex;
    transition: $transition-background;
}


.events-list a,
.events-list a:hover {
    text-decoration: none;
}

@media (min-width: 801px) {
    .events-list a,
    .events-list a:hover {
        color: $c-text;
    }

    .events-list a:hover .events-list_event {
        background: $blue-50;
        transition: $transition-background;
    }
}


.events-list a .events-list_time > div,
.events-list a:hover .events-list_time > div,
.events-list a:active .events-list_time > div {
    color: $c-text;
}

.events-list_time > div {
    font-weight: $font-weight-semibold
}



// ----------------------------------------------
// Event View

.event-view {
    display: flex;
    flex-wrap: wrap;
}

.event-view_main {
    flex: 1 1 70%;
    order: 1;
}

@media (max-width: $bp-screen-m - 1px) {
    .event-view_main {
        order: 2;
        flex-basis: 100%;
    }
}

.event-view_sidebar {
    flex: 1 1 30%;
    order: 2;
}

@media (max-width: $bp-screen-m - 1px) {
    .event-view_sidebar {
        order: 1;
        flex-basis: 100%;
    }
}

.event-view_day {
    @include lib-button-icon(
                    $icon-calendar,
            $_icon-font-color       : $gray-700,
            $_icon-font-color-hover : $gray-700,
            $_icon-font-size        : 22px,
            $_icon-font-line-height : 24px,
            $_icon-font-text-hide   : true
    );

    &:before {
        padding-right: $space-xs;
    }

    display: block;
}

.event-view_time {
    @include lib-button-icon(
                    $icon-time,
            $_icon-font-color       : $gray-700,
            $_icon-font-color-hover : $gray-700,
            $_icon-font-size        : 22px,
            $_icon-font-line-height : 24px,
            $_icon-font-text-hide   : true
    );

    &:before {
        padding-right: $space-xs;
    }

    display: block;
}

.event-view_time-zone {
    display: contents;
}

.event-view_share-email {
    @include lib-button-icon(
                    $icon-envelope,
            $_icon-font-color       : $gray-700,
            $_icon-font-color-hover : $gray-700,
            $_icon-font-vertical-align : bottom,
            $_icon-font-margin      : 0 $space-xs 0 0,
            $_icon-font-size        : 24px,
            $_icon-font-line-height : 24px,
            $_icon-font-text-hide   : true
    );
}

.event-view_image img {
    display: initial;
}

.event-view_organizer {

}

.organizer-email {
    @include lib-button-icon(
                    $icon-envelope,
            $_icon-font-color       : $gray-700,
            $_icon-font-color-hover : $gray-700,
            $_icon-font-vertical-align : bottom,
            $_icon-font-margin      : 0 $space-xs 0 0,
            $_icon-font-size        : 16px,
            $_icon-font-line-height : 22px,
            $_icon-font-text-hide   : true
    );
}

.icon-account-circle-outlined {
    @include lib-button-icon(
        $icon-user-circle,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-add-shopping-cart-outlined {
    @include lib-button-icon(
        $icon-add-shopping-cart,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-assignment-outlined {
    @include lib-button-icon(
        $icon-assignment,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-bookmarks-outlined {
    @include lib-button-icon(
        $icon-bookmarks,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-email-outlined {
    @include lib-button-icon(
        $icon-email-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-events-outlined {
    @include lib-button-icon(
        $icon-event,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-find-replace-outlined {
    @include lib-button-icon(
            $icon-find-replace,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-hvac-outlined {
    @include lib-button-icon(
        $icon-hvac,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-shield-good-outlined {
    @include lib-button-icon(
        $icon-shield-good,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-handyman-outlined {
    @include lib-button-icon(
        $icon-handyman,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-help-outlined {
    @include lib-button-icon(
        $icon-help-outlined,
        $_icon-font: $font-family-icon,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-location-outlined {
    @include lib-button-icon(
        $icon-location,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-shipping-outlined {
    @include lib-button-icon(
        $icon-local-shipping,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-login-outlined {
    @include lib-button-icon(
        $icon-login,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-loyalty-outlined {
    @include lib-button-icon(
        $icon-loyalty,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-phone-outlined {
    @include lib-button-icon(
        $icon-call-outlined,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}

.icon-storefront-outlined {
    @include lib-button-icon(
        $icon-storefront,
        $_icon-font: $font-family-icon-outlined,
        $_icon-font-color       : $gray-900,
        $_icon-font-color-hover : $c-text,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 $space-xs 0 0,
        $_icon-font-size        : $font-size-xxl,
        $_icon-font-line-height : $font-size-xxl,
        $_icon-font-text-hide   : true
    );
}
.organizer-phone {
    @include lib-button-icon(
                    $icon-phone,
            $_icon-font-color       : $gray-700,
            $_icon-font-color-hover : $gray-700,
            $_icon-font-vertical-align : bottom,
            $_icon-font-margin      : 0 $space-xs 0 0,
            $_icon-font-size        : 16px,
            $_icon-font-line-height : 22px,
            $_icon-font-text-hide   : true
    );
}

.suggestion-item,
.suggestion-item--active {
    font-size: $font-size-xs;
    padding: $space-xxs $space-xxs;
    background: $c-white;
    border-bottom: 1px solid $c-border;
}

.suggestion-item--active {
    background: $c-lightestgray;
}
