.flyout-modal .catalog-app__sidebar {
    margin-left: 0;

    .algolia-sidebar-container {
        --tw-translate-x: 0;
        opacity: 1;
        padding: 0;
        position: relative;
    }

    .block-content.inventory-content {
        padding: $space-xxs;

        > hr {
            display: none;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .flyout-modal .catalog-app__sidebar {
        .sidebar-main .filter hr {
            display: block;
        }

        #layered-filter-block > hr {
            display: none;
        }
    }
}
