// ==============================================
// Catalog Widgets
// ==============================================

.block-product-link.widget,
.block-category-link.widget {
    display: block;
    margin-bottom: $space-l;
}

.block-product-link-inline.widget {
    margin: 0;
}

.block.widget .product-item-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: auto;

    .product-item-inner {
        margin-top: auto;
        padding-top: $space-xs;
    }

    .badge-group {
        min-height: 27px;
    }

    .centered-image {
        text-align: center;
    }

    .product-item-details {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .block-requisition-list .menu.menu--short {
        margin-top: $space-xs;
    }
}

.block.widget .pager {
    padding: 0;
}

.block.widget .pager .toolbar-amount {
    float: none;
    font-size: $font-size-xs;
}

.block.widget .pager .pages-item-previous {
    padding-left: 0;
}

.block.widget .pager .pages-item-next {
    position: relative;
}

.block.widget .pager .items {
    white-space: nowrap;
}

// ----------------------------------------------
@media (max-width: $bp-screen-s - 1) {
    .block.widget .products-grid {
        margin: 0 $space-base;

        .product-items {
            margin-left: 0;
        }
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        width: calc((100% - #{$space-base}) / 2);
        flex: 1 0 calc((100% - #{$space-base}) / 2);
        margin-left: $space-base;

        &:nth-child(2n + 1) {
            margin-left: 0;
        }
    }
}

@media (min-width: $bp-screen-s) {
    .block.widget .products-grid {
        margin: 0 $space-base;

        .product-items {
            margin-left: 0;
        }
    }

    .block.widget .products-grid .product-item,
    .page-layout-1column .block.widget .products-grid .product-item,
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: calc((100% - #{2 * $space-base}) / 3);
        flex: 1 0 calc((100% - #{2 * $space-base}) / 3);
        margin-left: $space-base;

        &:nth-child(3n + 1) {
            margin-left: 0;
        }
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .block.widget .products-grid {
        margin: 0 $space-xxl;
    }

    .sidebar .block.widget .products-grid .product-item {
        margin-left: 0;
        width: 100%;
    }

    .sidebar .block.widget .products-grid .product-item .actions-secondary {
        display: block;
        padding: 10px 0;
    }

    .page-layout-1column .block.widget .products-grid .product-item {
        width: calc((100% - #{3 * $space-base}) / 4);
        flex: 1 0 calc((100% - #{3 * $space-base}) / 4);

        &:nth-child(3n + 1) {
            margin-left: $space-base;
        }

        &:nth-child(4n + 1) {
            margin-left: 0;
        }
    }

    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 100% / 2;
    }

}

@media (min-width: $bp-screen-l) {
    .block.widget .products-grid {
        max-width: 1800px;
        margin: auto;
        padding: $space-xxl;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .sidebar .block.widget .pager .item:not(.pages-item-next):not(.pages-item-previous) {
        display: none;
    }

    .sidebar .block.widget .pager .pages-item-next {
        padding: 0;
    }

    .sidebar .block.widget .pager .pages-item-next .action {
        margin: 0;
    }

}
