// ==============================================
// Vars
// ==============================================


body._has-modal {
    height: 100%;
    overflow: hidden;
    width: 100%;
}


// ==============================================
// Modal
// ==============================================

.modal {
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s ease;
    z-index: $z-modal;
    overflow: auto;
}

.modal.modal--show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease;
    z-index: 1000000 !important;
}

.modal.modal--show .modal-inner-wrap {
    transform: translate(0, -50%);
    top: 50%;
}

.modal .modal-inner-wrap {
    background-color: $c-white;
    box-shadow: $box-shadow-base;
    opacity: 1;
    pointer-events: auto;
    width: 75%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translate(0, -200%);
    transition: transform 0.2s ease;
}

.modal .modal-content {
    padding: 24px;
}

// @media (max-width: $bp-screen-xs - 1px) {
//     padding: 16px;
// }

.modal .modal-header,
.modal .modal-footer {
    flex-grow: 0;
    flex-shrink: 0;
}

.modal .modal-header {
    padding: 24px 24px 0 24px;
}

.modal .modal-footer {
    margin-top: auto;
    padding: 8px;
    text-align: right;
    border-top: 1px solid $c-white;
}

// ==============================================
// Modifier - Scroll
// ==============================================


.modal--scroll .modal-inner-wrap {
    height: 80%;

    .ie11 &,
    .ie10 &,
    .ie9 & {
        max-height: none;
    }
}

.modal--scroll .modal-content {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.modal .modal-footer,
.modal--scroll .modal-footer {
    border-top: 1px solid $c-border;
}

.modal .modal-header,
.modal--scroll .modal-header {
    border-bottom: 1px solid $c-border;
}


// ==============================================
// Modal Title
// ==============================================

.modal-title {
    @include h2;
    padding-right: 30px;
}

// ==============================================
// Action Close
// ==============================================

.flyout-modal .action-close,
.modal .action-close {
    @include lib-button-reset();
    @include lib-button-icon(
                    $icon-close,
            $_icon-font-color       : $gray-700,
            $_icon-font-size        : 24px,
            $_icon-font-line-height : 24px,
            $_icon-font-text-hide   : true
    );
    position: absolute;
    right: 0;
    top: 0;
    padding: 22px;

    &:hover {
        &::before {
            color: darken($c-link, 10%);
        }
    }
}


// ==============================================
// Overlay
// ==============================================

.modals-overlay {
    background-color: $c-overlay;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $z-modal-backdrop;
}

// ==============================================
// Modal - size modifiers
// ==============================================

@media (min-width: $bp-screen-s) {

    .modal .modal-inner-wrap {
        width: 500px;
    }
}

@media (min-width: $bp-screen-m) {

    .modal.modal--m .modal-inner-wrap {
        width: 700px;
    }

    .modal.modal--l .modal-inner-wrap {
        width: 900px;
    }

}

// ==============================================
// Modal - slide size
// ==============================================

//  Mobile transform to modal-slide
@media (max-width: $bp-screen-m - 1px) {
    .modal--responsive {
        left: 120px;
        width: auto;
    }

    .modal--responsive.modal--show .modal-inner-wrap {
        transform: translate(0, 0);
    }

    .modal--responsive .modal-inner-wrap {
        margin: 0;
        max-height: none;
    }

    .modal--responsive .page-main-actions {
        margin-top: auto;
        padding: 8px;
        text-align: right;
    }

    .modal--responsive .modal-inner-wrap {
        height: 100%;
        margin: 0;
        overflow-y: auto;
        position: static;
        transform: translate(100%, 0);
        transition: transform 0.3s ease-in-out;
        width: auto;
    }
}

// ==============================================
// Modifier Slide - turn modal into a slider
// ==============================================


.modal--slide {
    left: 300px;
    width: auto;
}

.modal--slide.modal--show .modal-inner-wrap {
    transform: translate(0, 0);
}

.modal--slide .modal-inner-wrap {
    margin: 0;
    max-height: none;
}

.modal--slide .page-main-actions {
    margin-top: auto;
    padding: 8px;
    text-align: right;
}

.modal--slide .modal-inner-wrap {
    height: 100%;
    margin: 0;
    overflow-y: auto;
    position: static;
    transform: translate(100%, 0);
    transition: transform 0.3s ease-in-out;
    width: auto;
}

@media (max-width: $bp-screen-l - 1px) {

    .modal--slide {
        left: 120px;
    }
}

@media (max-width: $bp-screen-s - 1px) {

    .modal--slide {
        left: 50px;
    }
}
