//
//  Common
//  _____________________________________________

.navigation-wrapper {
    display: flex;
    justify-content: space-between;
    z-index: 1005;
    background-color: $c-header-background;
}

.panel.header .links,
.panel.header .switcher {
    display: none;
}

.nav-sections {
    order: 1;
}

.nav-toggle {
    @include lib-icon-font(
        $icon-menu,
        $_icon-font-size: 36px,
        $_icon-font-line-height: 40px,
        $_icon-font-color: $c-library-blue-11,
        $_icon-font-color-hover: $c-library-blue-10
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    left: $space-base;
    z-index: 15;
}

.nav-drawer_title {
    @include h2();
}

.nav-mobile-account-links {
    display: none;
}

//
//  Mobile
//  _____________________________________________

@media (max-width: $bp-screen-m - 1px) {
    .navigation .level0 > .dropdown-content {
        margin-top: 0;
    }

    .navigation .level0.active,
    .navigation .level0.active a {
        font-weight: $font-weight-bold;
    }

    .customer-navigation .nav.item.current a {
        font-weight: $font-weight-bold;
    }

    .shop.dropdown {
        display: block;
    }

    .shop.dropdown .dropdown-trigger:after {
        content: '';
    }

    .shop-wrapper:before,
    .shop-wrapper:after,
    .homeowner-wrapper:before,
    .homeowner-wrapper:after {
        border: none;
    }

    .nav-sections {
        background: $navigation__background;
    }

    .navigation {
        border-top: 1px solid $c-border;
        padding: $space-s 0;
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
        height: 100%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        width: calc(100% - #{$active-nav-indent});
    }

    .nav-sections .switcher {
        border-top: 1px solid $c-border;
        font-size: $font-size-l;
        margin: 0;
        padding: 0.8rem 3.5rem 0.8rem 2rem;
        @include bold;

        &:last-child {
            border-bottom: 1px solid $c-border;
        }
    }

    .nav-sections .switcher .label {
        display: block;
        margin-bottom: $space-xs;
    }

    .nav-sections .switcher-trigger strong {
        position: relative;
        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: 28px,
            $_icon-font-position: after,
            $_icon-font-display: block
        );

        &::after {
            position: absolute;
            right: -40px;
            top: -16px;
        }
    }

    .nav-sections .switcher-trigger.active strong {
        @include lib-icon-font-symbol(
            $_icon-font-content: $icon-up,
            $_icon-font-position: after
        );
    }

    .nav-sections .switcher-dropdown {
        padding: $space-base 0;
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;
    }

    .nav-before-open .page-wrapper {
        transition: left 0.3s;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: relative;
    }

    .nav-before-open body {
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .nav-open .page-wrapper {
        left: calc(100% - #{$active-nav-indent});
    }

    .nav-open .nav-sections {
        overflow-x: hidden;
        box-shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);
        left: 0;
        z-index: 99;
    }

    .nav-open .nav-toggle {
        &::after {
            background: $c-overlay;
            content: '';
            display: block;
            height: 100%;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 1;
        }
    }

    .nav.item.current strong {
        display: block;
        padding: $space-xs $space-base;
    }

    .secondary-nav-mobile .nav.item.current strong {
        color: $c-library-gray-5;
        padding: $space-xs $space-base $space-xs 44px;
    }

    .shop-items_all,
    .shop-items_subnav,
    .homeowner-items_subnav,
    .nav-sections-item-title {
        display: none;
    }

    .shop-items_title,
    .homeowner-items_title {
        position: relative;
        @include lib-icon-font(
            $_icon-font-content: $icon-pointer-right,
            $_icon-font-size: 24px,
            $_icon-font-position: after,
            $_icon-font-display: block
        );

        &::after {
            position: absolute;
            right: 10px;
            top: 6px;
            color: $c-text-muted;
            pointer-events: none;
        }
    }

    .nav-sections-items {
        position: relative;
    }

    .nav-drawer {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        right: -100%;
        background: $navigation__background;
        transition: left .3s;
    }

    .nav-drawer .shop-items_subnav,
    .nav-drawer .homeowner-items_subnav {
        display: block;
    }

    .nav-drawer_control {
        background: $c-library-blue-11;
        color: $c-white;
        font-weight: $font-weight-semibold;
        padding: $space-base;
        letter-spacing: 0.04em;
        cursor: pointer;
        @include lib-icon-font(
            $_icon-font-content: $icon-keyboard-arrow-left,
            $_icon-font-size: 30px,
            $_icon-font-display: block,
            $_icon-font-margin: -3px -7px 0 -7px
        );

        &.nav-root:before {
            content: '';
        }
    }

    .nav-drawer_control .close-drawer {
        position: absolute;
        right: $space-base;
    }

    .nav-drawer_title {
        color: $c-library-gray-6;
        margin: $navigation__indent-side;
    }

    .nav-sections-item-content,
    .nav-drawer_content {
        @include lib-main-navigation(
            $_nav-level0-item-padding: $space-xxs $space-base,
            $_nav-level0-item-margin: 0,
            $_nav-level0-item-color: $c-text,
            $_nav-level0-item-color-active: $c-library-gray-5
        );

        .nav.item.current a,
        ol li a.active {
            font-weight: $font-weight-bold;
            color: $c-library-gray-5;
        }

        ul li a.icon-loyalty-outlined,
        ul li.authorization-link-row a,
        ul li.shop a {
            &:before {
                margin-right: $space-xxs;
            }
        }

        ul li.authorization-link-row a > span {
            bottom: 1px;
            position: relative;
        }

        .shop-items_title:not(.shop),
        .homeowner-items_title:not(.shop) {
            &:after {
                top: 10px;
            }

            > a {
                padding: $space-s $space-base;
            }
        }
    }

    .nav-sections-item-content,
    .nav-drawer {
        height: 100vh;
        display: flex;
        flex-direction: column;

        .mobile-app-stuff,
        .nav-drawer-bottom-block {
            margin-top: auto;
        }
    }

    .nav-sections-item-content ul li a {
        font-weight: $font-weight-bold;
    }

    .nav-sections-item-content .nav-mobile-account-links ul li a,
    .nav-sections-item-content ul.authorization-link.logged-in li a,
    .nav-sections-item-content ul.authorization-link.logged-in li .text-delimiter {
        font-weight: $font-weight-normal;
    }

    .nav-sections-item-content ul.authorization-link.logged-out li a,
    .nav-sections-item-content ul.authorization-link.logged-out li .text-delimiter {
        font-weight: $font-weight-bold;
    }

    .navigation .level0.highlighted.active > .level-top {
        color: $c-text;
    }

    .nav-drawer_content .shop-items_content {
        display: none !important;
    }

    .nav-mobile-account-links {
        display: block;
    }

    .mobile-account-links-subtitle {
        display: block;
        font-weight: $font-weight-semibold;
        margin: 0 0 $space-xxs 28px;
        padding: $space-xxs $space-base;
    }

    .nav-mobile-account-links ul li a {
        margin-left: 28px;
    }

    .nav-sections-item-content ul.authorization-link {
        margin: $space-xs 0 $space-s;
    }

    .nav-sections-item-content .authorization-link-row {
        align-items: baseline;
        display: flex;

        a {
            text-decoration: underline;
        }

        a.sign-out-link {
            text-decoration: none;
        }

        .text-delimiter {
            margin: 0 -7px 0 -10px;
            z-index: 1;
        }
    }

    .nav-drawer a,
    .nav-sections-items a {
        display: block;
        background-position: center left;
        transition: background 0.8s;
        background-color: $c-white;

        &:hover {
            background: $c-white radial-gradient(circle, transparent 1%, $gray-50 1%) center/15000%;
        }

        &:active {
            background-color: $c-white;
            background-size: 100%;
            transition: background 0s;
        }
    }

    .mobile-app-stuff-wrapper {
        border-top: 1px solid $c-border;
        display: flex;
        flex-direction: column;
        padding: $space-l 15px;
    }

    .mobile-app-stuff-wrapper h4 {
        font-weight: $font-weight-bold;
        margin-bottom: 0;
    }

    .icon-empty-space {
        margin-left: 28px;
    }
}

//
// Desktop
// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .navigation .level0.highlighted a,
    .navigation .level0.highlighted:hover a {
        color: $c-red;
    }

    .navigation .level0.active {
        font-weight: $font-weight-normal;
    }

    .navigation .level0.active > a {
        font-weight: $font-weight-semibold;
    }

    .customer-navigation .nav.item.current a {
        font-weight: $font-weight-normal;
    }

    .navigation-wrapper {
        margin: 0 -2px;
        padding: $space-xxl 0 30px 0;
    }

    .navigation .level0.highlighted.active > .level-top {
        color: $c-red;
    }

    .nav-toggle {
        display: none;
    }

    .nav-sections {
        background: $navigation__background;
        flex-shrink: 0;
        flex-basis: auto;
        width: 100%;
    }

    .nav-sections-item-title {
        display: none;
    }

    .nav-sections-item-content {
        display: block !important;
    }

    .nav-sections-item-content > *:not(.menu-top) {
        display: none;
    }

    .nav-sections-item-content > .navigation {
        display: flex;
        width: 100%;
    }

    @include lib-main-navigation-desktop();

    .panel.header .links,
    .panel.header .switcher {
        display: inline-block;
    }

    .shop-wrapper .shop-items_subnav,
    .homeowner-wrapper .homeowner-items_subnav {
        columns: 2;
        column-rule: 1px solid $c-border;
        column-gap: 0;
        margin-bottom: $space-base;
    }

    .homeowner-wrapper .homeowner-items_subnav {
        margin-top: $space-base;
    }

    .shop-wrapper .shop-items_subnav li,
    .homeowner-wrapper .homeowner-items_subnav li {
        break-inside: avoid;
        page-break-inside: avoid;

        &:hover {
            background: $peirceblue-400;
        }
    }

    .shop-wrapper .shop-items_subnav li a,
    .homeowner-wrapper .homeowner-items_subnav li a {
        display: block;
        padding: $space-xs $space-base;
        color: $gray-800;
    }

    .shop-wrapper .shop-items_subnav li:hover a,
    .homeowner-wrapper .homeowner-items_subnav li:hover a {
        color: $c-white;
        text-decoration: none;
    }

    .shop-nav {
        @include lib-data-tabs(
            $_title-selector : '.shop-items_title',
            $_content-selector : '.shop-items_content',
            $_tab-control-padding-right: $space-xs,
            $_tab-control-padding-left: $space-xs,
            $_tab-control-padding-top: 0,
            $_tab-control-padding-bottom: 0,
            $_tab-control-height: 60px,
            $_tab-content-margin-top: 60px,
            $_tab-control-font-size: $font-size-sl,
            $_tab-control-color: $c-text
        );
    }

    // Hide active tab highlight, we're highlighting title link.
    .shop-nav > .shop-items_title:not(.disabled):active::after,
    .shop-nav > .shop-items_title.active::after,
    .shop-nav > .shop-items_title.active:focus::after,
    .shop-nav > .shop-items_title.active:hover::after,
    .homeowner-nav > .homeowner-items_title:not(.disabled):active::after,
    .homeowner-nav > .homeowner-items_title.active::after,
    .homeowner-nav > .homeowner-items_title.active:focus::after,
    .homeowner-nav > .homeowner-items_title.active:hover::after {
        background: transparent;
        height: 0;
    }

    .shop-nav > .shop-items_title {
        display: flex;
        justify-content: center;
        text-align: center;
        text-transform: none;
        width: calc((100% - 45px) / 7);

        // Shrink short categories width to expand thermostats category width.
        &[aria-controls=ductless],
        &[aria-controls=parts],
        &[aria-controls=supplies] {
            width: calc((100% - 45px) / 7 - 20px);
        }

        &[aria-controls=thermostats-controls-zoning] {
            width: calc((100% - 45px) / 7 + 60px);
        }
    }

    .homeowner-nav > .homeowner-items_title {
        display: flex;
        justify-content: center;
        text-align: center;
        text-transform: none;
        width: calc((100% - 45px) / 7);
    }

    // Adjustment for first category tab title to be better aligned with other page elements.
    .shop-items_title:first-of-type {
        margin-left: 25px;
    }

    .shop-nav > .shop-items_title > a,
    .homeowner-nav > .homeowner-items_title > a {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 60px;
        justify-content: center;
        position: relative;
    }

    // Dropdown navigation active category title highlight.
    .shop-nav > .shop-items_title.active > a:after,
    .homeowner-nav > .homeowner-items_title.active > a:after {
        background: $peirceblue-400;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        top: calc(60px - 2px);
        width: 100%;
    }

    .navigation ul {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .navigation .level0:hover > a {
        font-weight: $font-weight-semibold;
    }

    .navigation .level0 > .level-top:before {
        content: '';
        margin-right: 0;
    }

    .dropdown.level0 {
        position: static;
    }

    .navigation .dropdown-content {
        border-right: none;
        box-sizing: border-box;
        left: 50%;
        margin-left: -50vw;
        margin-top: 0;
        position: absolute;
        top: 60px;
        width: calc(100% + 123px);
    }

    .shop-items_subnav,
    .homeowner-items_subnav {
        max-width: 1000px;
        padding: 0 53px;
    }

    .shop-items_all {
        background: $gray-50;
        border-top: 1px solid $c-border;
        padding: $space-base 68px;
        text-transform: capitalize;
    }
}

// ==============================================
// Mobile navigation icons
// ==============================================

@media (max-width: $bp-screen-m - 1px) {
    .nav-sections-item-content {
        .customer-support-phone {
            padding-left: 26px;
            position: relative;

            &.icon-phone-outlined:before {
                left: 0;
                position: absolute;
            }
        }

        .authorization-link .authorization-link-row .icon-account-circle-outlined,
        .navigation .level0.ui-menu-item .level-top,
        .secondary-nav-mobile .header.links .nav.item > a {
            padding-left: 44px;
            position: relative;

            &:before {
                left: $space-base;
                margin-right: 0;
                position: absolute;
            }
        }
    }
}
