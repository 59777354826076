/**
* Baker Distributing
* Copyright Baker Distributing. All rights reserved.
*/


// ==============================================
// Vars
// ==============================================

$step-background: $c-lightgray;
$step-size:       24px;

$step-number-background:        $c-gray;
$step-number-background-active: $c-primary;
$step-number-icon-complete:     $icon-edit;
$step-number-outline-width:     5px;


// ==============================================
// Steps
// ==============================================

.steps {
    counter-reset: i;
    font-size: 0;
    width: $step-size;
    margin: 0 $space-s;
}


// ==============================================
// Steps - Step
// ==============================================

// Faux border
.step {
    position: relative;
    vertical-align: top;
    width: 1px;
    margin-bottom: 0;
    background: $step-background;
    min-height: 60px;
}

// ----------------------------------------------
// Modifier: Complete

.step--complete {
    cursor: pointer;
}


// ==============================================
// Steps - Number
// ==============================================

.step > span {
    display: inline-block;
    width: 100%;
    padding-top: $step-size;
    font-size: 0;
    word-wrap: break-word;

    // Circle
    &::before {
        content: '';
        position: absolute;
        top: $step-number-outline-width;
        left: calc($step-size / -2);
        width: $step-size;
        height: $step-size;
        border-radius: $step-size;
        background: $step-number-background;
        outline: $step-number-outline-width solid $c-white;
    }

    // Circle content
    &::after {
        content: counter(i);
        counter-increment: i;
        position: absolute;
        top: $step-number-outline-width;
        left: -12px;
        width: $step-size;
        height: $step-size;
        color: $c-white;
        font-size: 14px;
        line-height: $step-size;
        text-align: center;
    }
}

// ----------------------------------------------
// Modifier: Active

.step--active > span {
    // Circle
    &::before {
        background: $step-number-background-active;
    }
}

// ----------------------------------------------
// Modifier: Complete

.step--complete > span {
    // Circle content
    &::after {
        content: $step-number-icon-complete;
        font-family: $font-family-icon;
        font-size: $font-size-xl;
        line-height: $step-size;
    }
}
