.selection-required {
    .MuiOutlinedInput-input {
        border: 2px solid $red-700;
        border-radius: 0;
        color: $red-700;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
    .MuiFormControl-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $red-800;
    }

    .MuiSelect-icon {
        color: $red-700;
    }

    &.MuiFormControl-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $red-900;
    }
}
