/**
* Baker Distributing
*/

$num-of-slide: 3;

.slider {
    width: 100%;
    height: 353px;
    position: relative;
    margin-bottom: $space-xxl;
    margin-top: -$space-base;
    z-index: 1;
    overflow: hidden;
}

.slider input {
    display: none;
}

.slider-wrapper {
    width: 100%;
    overflow: hidden;
    transition: all 500ms ease-out;
    -webkit-overflow-scrolling: touch;
    scroll-snap-points-x: repeat(percentage(calc(1/$num-of-slide)));
    scroll-snap-type: mandatory;
}

.slider-inner {
    transition: all 400ms cubic-bezier(0.77, 0, 0.175, 1);
    width: percentage($num-of-slide);
    line-height: 0;
    overflow: hidden;
}

.slider-inner > div {
    width: percentage(calc(1/$num-of-slide));
    height: 350px;
    float: left;
    position: relative;
    max-width: unset;
    margin: 0;
}

.slide img {
    width: 100%;
}

.slider-prev-next-control {
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 1440px;
    transform: translate(-50%, -50%);
}

@media (max-width: $bp-screen-s - 1px) {
    .slider-prev-next-control {
        display: none;
    }
}

.slider-prev-next-control label {
    display: none;
    width: 48px;
    height: 48px;
    background: $c-white;
    border-radius: 50px;
    box-shadow: 0 1px 2px black(0.15);
    text-decoration: none;
    cursor: pointer;
}

.slider-nav-mask,
.slider-nav-control
{
    position: absolute;
    width: 100%;
}

.slider-nav-mask {
    height: 30px;
    z-index: $z-index-1;
    background: $c-lightestgray;
    opacity: 0.7;
    margin-top: -30px;
}

@media (max-width: $bp-screen-m - 1px) {
    .slider-nav-mask {
        height: 50px;
        margin-top: -48px;
    }
}

.slider-nav-control {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: $z-index-2;
    margin-top: -30px;
}

@media (max-width: $bp-screen-m - 1px) {
    .slider-nav-control {
        margin-top: -48px;
    }
}

.slider-nav-control label {
    height: 33px;
    flex: 1 1 275px;
    max-width: 275px;
    background: transparent;
    border-bottom: 3px solid $c-white;
    padding: $space-xxs $space-base;
    transition: background, border-bottom-color 0.3s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    line-height: 20px;
    font-size: $font-size-s;

    &:hover {
        background: $c-lightestgray;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .slider-nav-control label {
        white-space: normal;
        overflow-x: hidden;
        text-overflow: ellipsis;
        height: 50px;
        padding: $space-xxs $space-xs;
    }
}

%bind-prev-next-button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    line-height: 36px;
    color: $c-icon-base;
    font-family: "Material Icons";
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    padding-top: 6px;
    transition: color $transition-base;

    &:hover {
        color: $c-icon-active;
    }
}

%bind-next-button {
    @extend %bind-prev-next-button;
    content: "\e315";
    padding-left: 12px;
}

%bind-next-label {
    display: block;
    float: right;
    margin-right: $space-base;
}

%bind-prev-label {
    display: block;
    float: left;
    margin-left: $space-base;
}

%bind-prev-button {
    @extend %bind-prev-next-button;
    content: '\e314';
    padding-left: 12px;

}

%bind-background-active-dot {
    background: $c-white;
    border-bottom: 3px solid $c-primary;
}

%bind-opacity-info {
    opacity: 1;
}

@for $i from 1 through $num-of-slide {
    #slide#{$i}:checked {
        & ~ div div div .slider-wrapper .slider-inner {
            margin-left: percentage(1 - $i);
        }

        & ~ div div div .slider-nav-control label:nth-child(#{$i}) {
            @extend %bind-background-active-dot;
        }

        & ~ div div div .slider-wrapper .slide:nth-child(#{$i}) .info {
            @extend %bind-opacity-info;
        }
    }
}

@for $i from 1 through ($num-of-slide - 1) {
    #slide#{$i}:checked {
        & ~ div div div .slider-prev-next-control label:nth-child(#{$i + 1}) {
            @extend %bind-next-label;

            &::after {
                @extend %bind-next-button;
            }
        }
    }
}

#slide#{$num-of-slide}:checked ~ div div div .slider-prev-next-control label:nth-child(1) {
    @extend %bind-next-label;

    &::after {
        @extend %bind-next-button;
    }
}

@for $i from 2 through $num-of-slide {
    #slide#{$i}:checked {
        & ~ div div div .slider-prev-next-control label:nth-child(#{$i - 1}) {
            @extend %bind-prev-label;

            &::after {
                @extend %bind-prev-button;
            }
        }
    }
}

#slide#{1}:checked ~ div div div .slider-prev-next-control label:nth-child(#{$num-of-slide}) {
    @extend %bind-prev-label;

    &::after {
        @extend %bind-prev-button;
    }
}

.slider-wrapper--bounce-end {
    transform: translate(-24px);
    transition: transform 100ms cubic-bezier(0.77, 0, 0.175, 1);
}

.slider-wrapper--bounce-start {
    transform: translate(24px);
    transition: transform 100ms cubic-bezier(0.77, 0, 0.175, 1);
}

// ==============================================
// Elements to use inside slides
// ==============================================

// ==============================================
// Vars
// ==============================================

$bp-slide-content: 750px !default;


// ==============================================
// Slide content
// ==============================================

.slide-content {
    display: block;
    margin: 0 auto;
}

// ==============================================
// Modifier - Slide content with background
// ==============================================


.slide-content--background {
    height: 350px;
    background-repeat: no-repeat;
    background-size: cover;
}


// ==============================================
// Slide content - Image
// ==============================================

.slide-content_image {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
}


// ==============================================
// Slide content - Body
// ==============================================

.slide > div {
    max-height: 350px !important;
}

.slide-content_body {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
}



// ==============================================
// Slide Body
// ==============================================

.slide-body {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 120px;
}

@media (max-width: $bp-screen-m - 1px) {
    .slide-body {
        padding: 0 90px;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .slide-body {
        padding: 0 30px;
    }
}

.slide-body > *:last-child {
    margin-bottom: 0;
}


// ==============================================
// Slide Body Cols
// ==============================================

.slide-body--cols {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.slide-body--cols > *:last-child {
    margin-left: $space-base;
}

.slide-col_text {
    margin-top: -50px;
}

.slide-col_image--bottom {
    align-self: flex-end;
}

.slide-col_image--top {
    align-self: flex-start;
}

@media (max-width: $bp-screen-s - 1px) {
    .slide-col_image {
        display: none;
    }
}


// ==============================================
// Slide Content -  Title
// ==============================================

.slide-body_title {
    margin-bottom: $space-base;
    font-size: $font-size-xxxxl;
    line-height: $line-height-xxs;
}

// ---------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .slide-body_title {
        font-size: $font-size-xxxl;
    }

}


// ==============================================
// Slide Content -  Text
// ==============================================

.slide-body_text {
    font-size: $font-size-l;
    margin-bottom: $space-base;
}


// ==============================================
// Modifier: Left
// ==============================================

.slide-content--left .slide-content_body {
    justify-content: flex-start;
    text-align: left;
}

.slide-content--left .slide-content_content {
    margin-left: 5%;
}


// ==============================================
// Modifier: Right
// ==============================================

.slide-content--right .slide-content_body {
    justify-content: flex-end;
    text-align: right;
}


// ==============================================
// Modifier: Text Right
// ==============================================

@media (min-width: $bp-slide-content) {

    .slide-content--text-light {
        color: $c-white;
    }
}

.slider > div > div:not(.slider-inner),
.slide-content > div:not(.slide-content_body):not(.slide-content_image) > div > div,
.slider-inner > div:not(.slide) > div {
    padding: 0 !important;
}
