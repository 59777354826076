// Product Columns List View Header.

$list-view-table_font-size: $font-size-base;
$list-view-cell-x-padding: $space-xxsl;

.list-view-columns-header {
    display: flex;
    justify-content: space-around;
    margin-bottom: $space-xxs;
    margin-left: $space-base;

    .list-view-header-column {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
}

// Common styles for header and row cells.

.list-view-header-column,
.product-list-columns-view .product-item-column {
    padding: $space-xs $list-view-cell-x-padding;
    border-right: 1px solid $c-border-light;
    font-size: $list-view-table_font-size;

    &:last-child {
        border-right: none;
    }

    &.table-cell-sticky-wrapper {
        padding: 0;
        border: none;
    }
}

// Table horizontal scrolling styles.

.list-view-header-column.product-item-general:not(.table-cell-sticky-clone) {
    height: 100%;
    width: 100%;
}

.list-view-header-column .table-cell-sticky-clone,
.product-item-column.table-cell-sticky-clone {
    position: absolute;
}

.list-view-header-column.table-cell-sticky-wrapper.sticky-column-hidden,
.product-item-column .table-cell-sticky-wrapper.sticky-column-hidden {
    .table-cell-sticky-clone {
        display: none;
    }
}

.product-list-columns-view .product-item-column {
    word-break: break-word;
}

@media (min-width: $bp-table-view) {
    .algolia-product-list .list .product-list-item.product-list-columns-view {
        background-color: transparent;
        border: none;
    }

    .list-view-header-column {
        min-height: 60px;
    }

    .list-view-header-column,
    .product-list-columns-view .product-item-column {
        background-color: $c-white;

        &.product-item-general {
            min-width: 290px;
        }

        &.product-item-image {
            min-width: 60px;
        }

        &.product-item-actions {
            min-width: 150px;
        }

        &.product-item-inventory {
            min-width: 130px;
        }

        &.product-item-pricing {
            min-width: 120px;
        }

        &.product-item-attribute {
            min-width: 70px;

            &.product-item-attribute-refrigerant {
                min-width: 85px;
            }

            &.product-item-attribute-tier {
                min-width: 105px;
            }
        }
    }

    .list-view-columns-table-content-wrapper {
        margin-bottom: $space-xl;

        &.table-scrollable {
            overflow-x: scroll;
            overflow-y: hidden;
        }
    }
}

// Sticky Elements Styles

.sticky-header-hidden {
    display: none;
}

.sticky-header-visible {
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 1;
    animation: slideDown 200ms ease-in;
}

// Product Columns List View Row

.algolia-product-list .list .product-list-item.product-list-columns-view {
    padding: 0;
    margin-bottom: $space-xxs;
}

.product-list-columns-view .product-item-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.list-view-columns-header-wrapper.header-duplicate {
    .list-view-columns-header {
        margin-left: 0;
    }

    .list-view-header-column {
        border-bottom: 2px solid $c-border-light;
    }
}

// ------------------------------------------------------
// List view table mobile styles.
// ------------------------------------------------------

@media (max-width: $bp-table-view - 1px) {
    .algolia-product-list .product-list-columns-view .product-item-info {
        flex-wrap: wrap;
        padding: $space-xs;
    }

    .algolia-product-list .product-list-columns-view .product-item-column {
        border-right: none;

        &.product-item-general,
        &.product-item-image,
        &.product-item-actions {
            min-width: 100%;
        }

        &.product-item-attribute,
        &.product-item-inventory,
        &.product-item-pricing {
            min-width: 50%;
        }

        .product-item-pricing {
            flex-basis: 100%;
            width: 100%;
        }
    }
}

// ------------------------------------------------------
// List view product actions column.
// ------------------------------------------------------

.product-list-columns-view .product-item-column.product-item-actions {
    align-items: center;

    > .product-item-actions {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .actions-primary {
        flex-basis: unset;
        margin-right: 0;

        .input-text.qty {
            width: 33px;
        }

        .field {
            flex-basis: unset;
        }

        .fieldset {
            flex-direction: row;
            justify-content: center;
            column-gap: $space-xxs;
        }

        .actions {
            order: 2;
            padding-top: $space-xxxs;
        }

        .field.qty {
            order: 1;
            width: auto;
        }
    }

    .button.view-substitutes,
    .action.tocart > div {
        display: block;
        text-align: center;
    }
}

@media (min-width: $bp-table-view) {
    .product-list-columns-view .product-item-column.product-item-actions {
        > .product-item-actions {
            row-gap: $space-xs;
        }

        .action.primary:not(.modal-action).tocart,
        .action-primary.tocart,
        .button.action.tocart {
            padding: $space-xs $space-xs;
            font-size: $list-view-table_font-size;
        }

        .action.primary:not(.modal-action).view-substitutes,
        .action-primary.view-substitutes,
        .button.action.view-substitutes {
            padding: $space-xs $space-xs;
            font-size: $list-view-table_font-size;
        }

        .actions-primary .actions {
            padding-left: 0;
            flex-grow: unset;
        }
    }
}

@media (max-width: $bp-table-view - 1px) {
    .product-list-columns-view .product-item-column.product-item-actions {
        > .product-item-actions {
            row-gap: $space-base;
        }

        .action.primary:not(.modal-action).tocart,
        .action-primary.tocart,
        .button.action.tocart {
            padding: $space-xs $space-base;
            font-size: $list-view-table_font-size;
        }

        .action.primary:not(.modal-action).view-substitutes,
        .action-primary.view-substitutes,
        .button.action.view-substitutes {
            padding: $space-xs $space-base;
            font-size: $list-view-table_font-size;
        }

        .actions-primary {
            width: 100%;

            .actions {
                padding-left: $space-xs;
                max-width: 250px;
            }
        }
    }
}

// Add to cart quantity field is visible on mobile.

@media (max-width: $bp-screen-s - 1px) {
    .algolia-product-list .product-list-columns-view .product-item-actions .actions-primary .field.qty {
        display: block;
    }
}

// My Lists Dropdown.

.product-list-columns-view .product-item-column.product-item-actions {
    .actions-secondary {
        flex-basis: unset;
        flex-grow: unset;
    }

    .my-lists-dropdown-trigger,
    .dropdown-trigger {
        display: inline;
    }
}

@media (min-width: $bp-table-view) {
    .product-list-columns-view .product-item-column.product-item-actions {
        .my-lists-dropdown-content {
            left: unset;
            right: 0;
        }
    }

    // My Lists dropdown horizontal minimal view.
    .list-view-columns-table-content-wrapper.table-scrollable {
        .product-list-columns-view .product-item-column.product-item-actions {
            @include my-lists-minimal-template;

            .my-lists-dropdown-content {
                right: 115%;
                left: unset;
                top: -115px;
            }
        }
    }
}

@media (max-width: $bp-table-view - 1px) {
    .product-list-columns-view .product-item-column.product-item-actions {
        .my-lists-dropdown-content {
            left: -50%;
        }
    }
}

// ------------------------------------------------------
// Product item image column.
// ------------------------------------------------------

.product-list-columns-view .product-item-column.product-item-image {
    align-items: center;

    .product-item-photo {
        flex: unset;
        padding: 0;
        height: unset;
        max-width: 75px;
    }
}

@media (min-width: $bp-table-view) {
    .product-list-columns-view .product-item-column.product-item-image .product-item-photo {
        max-width: 75px;
    }
}

@media (max-width: $bp-table-view - 1px) {
    .product-list-columns-view .product-item-column.product-item-image {
        order: -1;

        .product-item-photo {
            max-width: 130px;
        }
    }
}

// ------------------------------------------------------
// Product item column General.
// ------------------------------------------------------

.product-list-columns-view .product-item-column.product-item-general {
    .product-item-name {
        font-weight: $font-weight-semibold;
    }

    .product-item-item {
        margin-bottom: $space-xxs;

        > a {
            color: $c-text;
            font-weight: $font-weight-semibold;
        }
    }
}

@media (min-width: $bp-table-view) {
    .algolia-product-list .list-view-header-sticky-first-column {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-list-columns-view .product-item-column.product-item-general {
        .product-item-name {
            margin: $space-xxxs 0 $space-xxs;
        }
    }
}

@media (max-width: $bp-table-view - 1px) {
    .product-list-columns-view .product-item-column.product-item-general {
        .product-item-name {
            margin: $space-xs 0;
        }
    }
}

// ------------------------------------------------------
// Product item column Inventory.
// ------------------------------------------------------

@media (min-width: $bp-table-view) {
    .product-list-columns-view .product-item-column.product-item-inventory {
        .product-inventory {
            margin: 0;
            padding: $space-xxs !important;
        }


        .inventory-label {
            margin-bottom: 0;
        }

        .stock {
            margin-top: 0;
        }

        .product-inventory__label {
            display: none;
        }

        .product-inventory_availability {
            margin-left: 0 !important;
            margin-right: auto;
        }

        .product-inventory-component.background-base {
            background: $c-white !important;
            padding: 0 !important;
        }
    }
}

@media (max-width: $bp-table-view - 1px) {
    .product-list-columns-view .product-item-column.product-item-inventory {
        .product-inventory {
            margin: $space-xxs 0;
        }
    }
}

// ------------------------------------------------------
// Product item column Pricing.
// ------------------------------------------------------

.product-list-columns-view .product-item-column.product-item-pricing {
    .price-wrapper {
        align-items: baseline;
        display: flex;
        flex-wrap: wrap;
    }

    .uom-loading-wrapper {
        position: relative;
        top: 1px;
    }

    .price-box {
        margin: 0;
    }
}

@media (min-width: $bp-table-view) {
    .product-list-columns-view .product-item-column.product-item-pricing {
        .price-box .price {
            font-size: $list-view-table_font-size;
        }
    }
}

@media (max-width: $bp-table-view - 1px) {
    .product-list-columns-view .product-item-column.product-item-pricing {
        .price-box .price {
            font-size: $font-size-base;
        }
    }
}

// ------------------------------------------------------
// Product item column Attribute.
// ------------------------------------------------------

@media (min-width: $bp-table-view) {
    .product-list-columns-view .product-item-column.product-item-attribute {
        .label {
            display: none;
        }
    }
}

@media (max-width: $bp-table-view - 1px) {
    .product-list-columns-view .product-item-column.product-item-attribute {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: $space-xxxs;

        .label {
            margin-right: $space-xxs;
        }
    }
}

// ----------------------------------------------
// Table horizontal scrolling buttons.
// ----------------------------------------------

.list-view-columns-prev,
.list-view-columns-next {
    cursor: pointer;
    width: 18px;
    height: 100%;
    position: absolute;

    > i {
        color: $c-white;
        z-index: 1;
        font-size: 45px;
        top: 6px;
        width: 0;
    }
}

.list-view-columns-prev {
    background: linear-gradient(to right, transparent, $c-input-border-bottom);

    &:hover {
        background: linear-gradient(to right, transparent, $c-input-text-fill);
    }
}

.list-view-columns-next {
    background: linear-gradient(to right, $c-input-border-bottom, transparent);

    &:hover {
        background: linear-gradient(to right, $c-input-text-fill, transparent);
    }
}
