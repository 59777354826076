$suggestions_background-color: $gray-50;

.page-layout-1column .content-wrapper.quickorder-wrapper {
    margin: auto;
}

.quickorder-wrapper .restriction-message {
    color: $c-red;
}

.quickorder-search  {
    input {
        border: 1px solid #d3d3d3;
        background: #FFF;
        position: static;
        height: 48px;
        margin: 0;
        padding-right: 8px;
        padding-left: 8px;
        width: 100%;
        -webkit-transition: 0.2s ease all;
        transition: 0.2s ease all;
    }

    input:focus {
        border-bottom-color: $blue-700
    }

    .tabs_nav {
        position: relative;
        top: 1px;
        z-index: 1;
    }

    .tabs_nav-link {
        background: $gray-50;
        border: 1px solid $gray-100;
        border-right: none;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        white-space: normal;
        width: calc(100%/3);

        &:last-of-type {
            border-right: 1px solid $gray-100;
        }

        &:hover {
            color: $c-text;
        }

        &.active {
            background: $c-white;
            border-bottom-color: $c-white;
            color: $c-text;
        }
    }

    .panel_body {
        min-height: 250px;
    }
}

@media (min-width: $bp-screen-m) {
    .quickorder-result {
        position: relative;
        top: 1px;
    }
}

@media (min-width: $bp-screen-l) {
    .quickorder-search {
        min-width: 480px;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .quickorder-search {
        .tabs {
            max-width: 100vw;
        }
    }
}

// ----------------------------------------------
// Suggestions
// ----------------------------------------------

.model-suggestions {
    background: $suggestions_background-color;
    border: 1px solid $c-border;
    margin-top: $space-s;
    max-width: 100%;
    position: relative;
    width: $finder-form-width;

    &:before {
        @include triangle($direction: up, $size: 8px, $color: $c-border);
        top: -8px;
        left: 20px;
    }

    &:after {
        @include triangle($direction: up, $size: 6px, $color: $suggestions_background-color);
        top: -6px;
        left: 22px;
    }
}

.model-suggestions-item {
    border-top: 1px solid $c-border;
    cursor: pointer;
    padding: $space-xs;

    &:hover {
        background-color: $c-library-orange-0;
    }

    &:first-child {
        border-top: none;
    }

    .image-wrapper {
        max-width: 75px;
        min-width: 75px;
    }

    .suggestion-item-details {
        padding: 0 $space-xs;
    }
}

.model-loading {
    padding: $space-xs 0;

    .simple-loading {
        height: 20px;
        margin: 0;
        width: 20px;
    }

    &-text {
        margin-left: $space-s;
    }
}
