// ==============================================
// Variables
// ==============================================

$po-validation-error_color: $c-text-danger;

// ==============================================
// Field
// ==============================================

// [todo] move this pattern to component

.field .control._with-tooltip {
    position: relative;
}

.field.choice .field-tooltip {
    display: inline-block;
    margin-left: $space-base;
    position: relative;
    top: -3px;
}

#checkout-step-notes {
    margin-bottom: $space-xxl;
}

#checkout-step-notes .field {
    margin-bottom: 0;
}

#checkout-step-notes .field .control {
    margin-top: 0;
}

.field .control .notes-to-store-label {
    color: $c-text-muted;
    display: block;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    margin-bottom: $space-xs;
    padding: 0 0 $space-s;
    position: static;
}

.field .control .notes-to-store-wrapper {
    display: inline-block;
    margin-bottom: $space-base;
    position: relative;
}

.field .control textarea.input-text {
    padding: $space-base $space-s 0 $space-s;

    @include placeholder();
}

.modal-content .field .control textarea.input-text,
.modal-component .field .control textarea.input-text {
    padding-top: 26px;
}

.field .control .notes-length-counter {
    bottom: -$space-base;
    color: $c-text-muted;
    font-size: $font-size-s;
    position: absolute;
    right: 0;
}

.field.choice .field-tooltip-action {
    line-height: $checkout-tooltip-icon__font-size;
}

.field._error .control input,
.field._error .control select,
.field._error .control textarea {
    border-color: $form-element-validation__border-error;
}

.validation-error {
    @extend .mage-error;
}

.checkout-ponumber .validation-error {
    &.input-text,
    &.input-text:focus {
        border-color: $po-validation-error_color;
        color: $po-validation-error_color;
    }

    &:focus ~ label {
        color: $po-validation-error_color;
    }
}

.mage-error {
    color: $po-validation-error_color;
}

.checkout-ponumber div.validation-error[generated] {
    border-left: 2px solid $c-orange;
    color: $po-validation-error_color;
    padding-left: $space-xs;

    > span {
        display: block;
        width: 100%;
    }
}
