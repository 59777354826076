$ahri-search-form_width: 500px;
$ahri-search-suggestions_background-color: $gray-50;

.ahri-index-search .breadcrumbs {
    margin-bottom: 0;
}

.ahri-search-form-field {
    max-width: 100%;
    width: $ahri-search-form_width;
}

.ahri-index-search [data-content-type='row'][data-appearance='contained'] {
    max-width: $bp-screen-xl;
}

.MuiFormGroup-root.include-boilers {
    margin-bottom: $space-l;

    .MuiFormHelperText-root {
        font-size: $font-size-s;
        padding-left: $space-xxl;
        margin-top: -$space-xs;
        z-index: -1;
    }
}

.ahri-search-selected-unit {
    margin: $space-xxl 0;

    .image-wrapper {
        margin-right: $space-base;
        min-width: 85px;
        width: 100px;

        img {
            border: 1px solid $c-border;
            padding: $space-xxs;
        }
    }

    .badge-group {
        padding-top: $space-xxs;
    }

    .product-inventory {
        background: $c-lightgray;
        padding: $space-xxs $space-xs;
    }

    .suggestion-item-title a {
        color: $c-text;
    }
}

@media (min-width: $bp-screen-m) {
    .ahri-index-search {
        .ahri-search-app {
            margin: 40px 0;
        }

        .ahri-search-app .table {
            margin-bottom: 0;
        }

        .ahri-table-content {
            padding-right: 1px;
        }
    }
}

@media (max-width: $bp-screen-m - 1) {
    .ahri-index-search {
        .container {
            padding: 0;
        }

        .ahri-search-app {
            margin: $space-xxl 0;
        }

        .MuiTabs-root .MuiTab-root {
            width: calc(100%/3);
        }

        .pp-tab-panel {
            min-height: 70vh;
        }
    }
}

//--------------------------------------
// Search by System Needs tab
//--------------------------------------

.ahri-index-search {
    .MuiMenu-paper.MuiPaper-rounded {
        border-radius: 0;
    }

    .MuiMenuItem-root {
        font-size: $font-size-base;
    }

    .search-loading.flex {
        align-items: center;
    }

    .simple-loading {
        height: $space-xl;
        margin: $space-xs $space-l;
        width: $space-xl;
    }
}

.system-search-filters {
    max-width: 600px;

    .field.required label::after {
        color: $c-danger;
    }

    .MuiSelect-select.MuiSelect-select {
        font-size: $font-size-base;
        padding: $space-xs;
    }

    .system-search-filter-row {
        display: flex;
        margin-bottom: $space-base;
    }

    .system-search-filter-row .label {
        flex: 1;
    }

    .system-search-filter-row .MuiFormControl-root {
        flex: 3;
    }

    .label .MuiTypography-body1 {
        font-size: $font-size-s;
    }
}

@media (min-width: $bp-screen-m) {
    .system-search-filters {
        padding-top: $space-base;

        .system-search-filter-row {
            align-items: center;
        }
    }
}

@media (max-width: $bp-screen-m - 1) {
    .system-search-filters {
        padding-top: $space-xs;

        .system-search-filter-row {
            flex-direction: column;
        }
    }
}

.ahri-index-search {
    .search-units-wrapper {
        margin-top: $space-xxl;

        h2 {
            font-weight: $font-weight-bold;
            margin-bottom: $space-s;
        }
    }

    .search-units {
        margin-top: $space-xs;
    }

    .search-unit-item {
        cursor: pointer;

        &:hover {
            background-color: $c-library-orange-0;
        }

        .image-wrapper {
            background: $c-white;
            border: 1px solid $c-border;
            height: 75px;
            margin-right: $space-xs;
            max-width: 75px;
            min-width: 75px;
            overflow: hidden;
            width: 75px;

            img {
                width: 75px;
            }
        }

        .suggestion-item-title {
            line-height: 1.1;
        }
    }
}

@media (min-width: $bp-screen-m) {
    .ahri-index-search .search-unit-item {
        padding: $space-s $space-xs;
    }
}

@media (max-width: $bp-screen-m - 1) {
    .ahri-index-search .search-unit-item {
        padding: $space-s $space-xxs;
    }
}

// Search suggestion units

.ahri-search-form .search-unit-item {
    .suggestion-item-details {
        flex: 1;
    }

    .suggestion-item-availability {
        margin-left: auto;

        .product-inventory {
            margin-top: 0;
        }
    }

    .suggestion-item-inventory {
        width: 200px;
    }

    .suggestion-item-pricing {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-top: $space-xxxs;
        width: 150px;
    }
}

@media (min-width: $bp-screen-m) {
    .ahri-search-form .search-unit-item {
        .suggestion-item-info {
            margin-right: $space-base;
        }
    }
}

@media (max-width: $bp-screen-m - 1) {
    .ahri-search-form .search-unit-item {
        .suggestion-item-details {
            flex-wrap: wrap;
        }

        .suggestion-item-availability {
            flex-wrap: wrap;
            margin-top: $space-xxs;
            width: 100%;
        }

        .suggestion-item-pricing {
            margin-top: $space-xs;
        }

        .suggestion-item-inventory,
        .suggestion-item-pricing {
            width: 100%;
        }
    }
}

// Ahri Search by System Needs: Reference Only Message
.ahri-index-search {
    .search-unit-item .suggestion-item-info .reference-only-message {
        display: none;
    }

    .ahri-search-selected-unit .suggestion-item-pricing .reference-only-message {
        display: none;
    }

    .ahri-search-selected-unit .suggestion-item-info .reference-only-message {
        margin-top: $space-xxs;
    }
}
