// ==============================================
// Customer Account Company Users Page.
// ==============================================

.company-users-index {
    .content-wrapper {
        padding-top: 0;
    }

    .panel {
        border: none;
    }

    .panel_float {
        position: absolute;
        top: -$space-xxl;
    }

    .panel_body h2 {
        font-weight: $font-weight-light;
    }

    .pending-users-app {
        margin-bottom: $space-xxxl;
    }

    .table--linearize tbody tr.empty-row {
        border-bottom: none;
        padding: 0;

        .data-grid-cell-content {
            background: $c-background;
            color: $c-text-muted;
            padding: $space-base;
            text-align: center;
            width: 100%;
        }

        td {
            border-bottom: none;
            padding: 1px 0 0;
        }
    }
}

@media (min-width: $bp-screen-m) {
    .company-users-index {
        .panel_float {
            right: 0;
        }

        .panel_body {
            padding: $space-base 0;
        }

        .admin__data-grid-outer-wrap,
        .pending-users-app {
            padding-left: $space-base;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .company-users-index {
        .panel_float {
            right: $space-base;
        }
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .company-users-index {
        .panel_float {
            height: auto;
            top: -50px;
        }
    }
}

@media (min-width: $bp-table-linearize) {
    .company-users-index {
        .data-grid-cell-content label {
            display: none;
        }

        .data-grid-wrap.table-wrapper,
        .pending-users-app {
            td {
                width: 12%;
            }

            td.long-text-field {
                width: 26%;
            }
        }
    }
}

@media (max-width: $bp-table-linearize - 1px) {
    .company-users-index {
        .data-grid-cell-content label {
            font-weight: $font-weight-semibold;
            margin-right: $space-xxs;
        }
    }
}
