// Top Filters on category page.

.top-filters {
    margin-bottom: $space-xxsl;
}

.top-filters > .filter-options {
    display: flex;
    gap: $space-xs;

    > .filter-options {
        flex: 1;
        padding: 0;
        position: relative;
    }

    > .filter-options > .toggle-content-wrapper {
        background-color: $c-white;
        top: calc(100% + #{$space-xxs});
        left: 0;
        min-width: 165px;
        opacity: 1;
        padding: $space-xs;
        position: absolute;
        width: 100%;
        z-index: 2;
    }

    .collapsible_title {
        @include button-style(
            $background: $c-white,
            $background--hover: $gray-100,
            $background--active: $c-gray,
            $color: $c-text
        );

        @include lib-icon-font(
            $_icon-font-content: $icon-down,
            $_icon-font-size: $font-size-l,
            $_icon-font-position: after,
            $_icon-font-display: block,
            $_icon-font-margin: 0
        );

        align-items: center;
        border-radius: 5px;
        display: flex;
        height: 100%;
        font-weight: $font-weight-normal;
        justify-content: center;
        margin: 0;
        padding: $space-xs $space-base $space-xs $space-xxs;
        text-align: center;

        &:before {
            content: ''
        }

        &:after {
            position: absolute;
            right: $space-xxxs;
        }
    }

    .collapsible_title.filters-applied {
        @include lib-icon-font(
            $_icon-font-content: $icon-checkbox,
            $_icon-font-size: $font-size-l,
            $_icon-font-position: after,
            $_icon-font-display: block,
            $_icon-font-margin: 0,
            $_icon-font-color: $blue-600
        );

        display: flex;

        &:after {
            position: absolute;
            right: $space-xxxs;
        }
    }

    .collapsible_title--opened {
        @include lib-icon-font(
            $_icon-font-content: $icon-up,
            $_icon-font-size: $font-size-base,
            $_icon-font-position: after,
            $_icon-font-display: block
        );

        align-items: center;
        display: flex;
        justify-content: center;
    }

    .collapsible_content {
        margin-left: 0;
    }
}
