// ==============================================
// PDP Relative/Substitute products app.
// ==============================================

.related-app-wrapper,
.substitute-app-wrapper {
    h2 {
        margin: 0 0 $space-l $space-s;
    }
}

.related-app,
.substitute-app {
    margin-bottom: $space-l;

    .slick-prev,
    .slick-next {
        background: $c-white;
        border: 1px solid $c-border-light;
        padding: 6px 7px;
        top: 50%;
        z-index: 1;

        &:before {
            color: $gray-600;
            font-size: $font-size-xl;
        }

        &:hover {
            background: $c-background;
        }

        &.slick-disabled {
            // Rewrite Page Builder .slick-disabled !important.
            display: block !important;
            cursor: default;

            &:before {
                color: $gray-100;
            }

            &:hover {
                background: $c-white;
            }
        }
    }

    .slick-prev {
        left: -$space-s;
    }

    .slick-next {
        right: -$space-s;
    }

    a.product-item-name {
        margin-bottom: $space-xs;
    }

    .products-grid .product-item-name {
        height: 62px;
    }

    .badge-group {
        min-height: 28px;
    }

    .price-box {
        margin: $space-s 0;

        .price-message.restriction-message {
            display: block;
            margin-top: $space-xxs;
        }
    }

    .product-item-image {
        margin: 0 auto $space-xs;
    }

    .slick-initialized .slick-slide,
    .slick-initialized .slick-slide > div {
        display: flex;
    }

    .slick-slide > div,
    .product-card__root {
        width: 100%
    }

    .slick-list .slick-track {
        display: flex;

        .slick-slide {
            height: inherit;
            flex: 1;
            margin: 0 $space-xxs;
            border: 1px solid $c-border;
        }

        .slick-slide > div {
            width: 100%;
        }
    }

    .slick-list .product-item {
        list-style: none;
        margin: 0 calc($space-l / 2);
    }

    .block-requisition-list .menu_content {
        bottom: $space-xxl;
        top: auto;
    }
}

// Desktop

@media (min-width: $bp-screen-m) {
    .related-app-wrapper,
    .substitute-app-wrapper {
        background: $c-background;
        margin-bottom: $space-l;
        padding: $space-xl $space-l $space-s;
    }
}

// Mobile

@media (max-width: $bp-screen-m - 1) {
    .related-app-wrapper,
    .substitute-app-wrapper {
        padding: $space-base $space-xs $space-l;
    }

    .related-app,
    .substitute-app {
        margin: $space-xs $space-xs $space-base;

        h4 {
            font-weight: $font-weight-semibold;
        }
    }
}
