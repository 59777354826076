// ==============================================
// Vars
// ==============================================

$checkout-sidebar-width: 300px;


// ==============================================
// Checkout Container
// ==============================================

.checkout-container {
    display: flex;
    flex-wrap: nowrap;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    html,
    body {
        overflow-x: hidden;
    }

    body.checkout-index-index {
        max-width: 100vw;
    }

    .checkout-container {
        flex-wrap: wrap;
    }
}

.checkout-container .no-quotes-block {
    margin: $space-l 0;
}


// ==============================================
// OPC Wrapper
// ==============================================

.opc-wrapper .step-content {
    padding: $space-l;
    border: 1px solid $c-border;
}

// ----------------------------------------------

.opc-wrapper {
    flex: 1 1 100%;
    order: 2;
}

@media (min-width: $bp-screen-m) {

    .opc-wrapper {
        flex-basis: auto;
        padding-right: $space-l;
        order: 1;
        max-width: calc(100vw - 300px);
    }

}


// ==============================================
// OPC Sidebar
// ==============================================

.opc-sidebar {
    flex: 0 0 100%;
    order: 1;
    border: 1px solid $c-border;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .opc-sidebar {
        flex-basis: $checkout-sidebar-width;
        order: 2;
        margin-top: -168px;
        margin-right: -20px;
        background: $c-lightestgray;
        border: none;
    }

    .opc-sidebar .action-close {
        display: none;
    }

    .opc-sidebar .modal-inner-wrap {
        transform: none;
        overflow: hidden;
        background: transparent;
    }

}


.opc-sidebar .total-rules{
    th, td {
        padding-top: $space-xxs;
        padding-bottom: $space-xxs;
    }
}

.opc-sidebar .total-rules th.mark {
    padding-left: $space-xl;
}

// ==============================================
// Checkout Control
// ==============================================

.checkout-control {
    margin: 0 0 $space-l 0;
    border: 1px solid $c-border;
    padding: $space-base;
}

// Hide job selector field on checkout
.checkout-control .job-selector.control {
    display: none;
}

// ----------------------------------------------
//  Desktop
// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .checkout-control {
        display: flex;
        width: 100%;

        .company-control {
            flex-grow: 1;
            max-width: calc(100% / 3);
        }

        .job-details .control {
            max-width: none;
        }

        .company-control > div {
            display: flex;

            .control {
                flex: 1;
                margin-right: $space-xs;
            }
        }

        .checkout-ponumber.control {
            padding-right: $space-xxs;
        }

        .checkout-jobname {
            margin-left: $space-xs;
        }

        .checkout-ponumber,
        .checkout-jobname {
            flex-grow: 1;
            max-width: calc(100%/3);
        }
    }

    .checkout-control .job-selector {
        margin-left: 0;
    }

    .checkout-control .label {
        display: block;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .checkout-control {
        width: calc(100% - (#{$checkout-sidebar-width}));
    }

}


// ==============================================
// Checkout PO Number
// ==============================================

.ponumber-tooltip {
    position: absolute;
    right: 0;
    top: 10px;

    .material-icons {
        font-family: $font-family-icon-outlined;
    }
}

.checkout-ponumber .input-text.ponumber {
    padding-right: 22px;
}

// ----------------------------------------------

@media (max-width: $bp-screen-s - 1px) {

    .checkout-ponumber, .checkout-jobname {
        margin-top: 26px;
    }

}

// Hide steps temporarily
//-----------------------------------------

.checkout-container .steps {
    display: none;
}

// Hide Apply Gift Card
//-----------------------------------------

.opc-payment-additional.giftcardaccount {
    display: none;
}

// Job Account Block
//-----------------------------------------

.checkout-index-index .checkout-control-wrapper {
    padding-top: $space-base;
}

// ==============================================
// Checkout Inventory Messages
// ==============================================

.checkout-inventory-notice-message-container {
    margin-top: 12px;
    margin-bottom: 12px;
}

.message.warning.checkout-inventory-warning-message {
    border-left: 6px solid $message-warning-icon__background;
    padding-left: 14px;
}

.message.warning.checkout-inventory-warning-message > *:first-child::before {
    display: none;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .checkout-inventory-notice-message-container {
        margin-top: 0;
    }
}

// ==============================================
// Checkout Job Details Form
// ==============================================

.checkout-control .company-control {
    .label {
        display: none;
    }

    .control {
        margin-top: $space-xxs;
    }

    .control > select {
        margin-top: 0;
    }
}
