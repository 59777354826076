.page-print .page-wrapper {
   max-width: 960px;
    margin: 0 auto;
}

.page-print .page-main {
    display: block;
}

.page-print .columns {
    display: block;
}

.page-print .columns .column.main {
    max-width: 100%;
}

// ----------------------------------------------

@media print {

    @page {
        margin: 1cm;
    }

    // ----------------------------------------------
    // Reset

    * {
        background: transparent !important;
        color: black            !important; // Black prints faster
        border-color: $c-border !important;
        text-shadow: none       !important;
        filter: none            !important;
    }

    // ----------------------------------------------
    // Page Break

    .block-content {
        page-break-before: avoid;
    }

    .box,
    tr,
    img {
        page-break-inside: avoid;
    }

    .block-title,
    h2,
    h3 {
        page-break-after: avoid;
    }

    // ----------------------------------------------
    // Layout

    .column.main {
        width: 100% !important;
    }

    // ----------------------------------------------
    // Hide content

    .page-header,
    .page-footer,
    .nav-sections,
    .breadcrumbs,
    .sidebar,
    .footer-copyright,
    .wrapper-panel > *[class] {
        display: none !important;
    }

    // ----------------------------------------------
    // Image

    img {
        max-width: 100% !important;
    }

    // ----------------------------------------------
    // Table

    thead {
        display: table-header-group;
    }

    .table-wrapper table {
        width: 100%;
        table-layout: fixed;
    }

    .table-wrapper table td {
        width: auto;
    }

    .table-wrapper table tfoot > tr:first-child {
        border-top: 1px solid $c-border;
    }

    // ----------------------------------------------
    // Typography

    a,
    a:visited {
        text-decoration: underline !important;
    }

    pre,
    blockquote {
        border: 1px solid $c-border;
        page-break-inside: avoid;
    }

    .block-content,
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    // ----------------------------------------------
    // Module: Order History

    // Baker: gift messages is not implemented
    /*
    .order-details-items .order-items .order-gift-message:not(.expanded-content) {
        visibility: visible;
        height: auto;
    }
    */

}
