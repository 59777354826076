.requisition-popup,
.stocking-popup {
    .action {
        &.hidden {
            display: none;
        }
    }
}

.requisition-list-requisition-print {
    &.account {
        .column {
            &.main {
                padding-bottom: 0;
            }
        }
    }

    .requisition-grid {
        .actions-toolbar {
            display: none;
        }

        .col {
            &.number {
                width: 1rem;
            }

            &.col-checkbox {
                padding: 0;

                input[type='checkbox'] {
                    display: none;
                }
            }

            &.subtotal {
                padding-right: 0;
            }

            &.price,
            &.qty,
            &.subtotal {
                text-align: right;
            }

            .product-item-name {
                a {
                    pointer-events: none;
                }
            }

            .price {
                font-weight: $font-weight-semibold;
            }

            .price-excluding-tax {
                font-size: 1.1rem;

                .price {
                    font-size: $font-size-xs;
                }
            }

            .input-text {
                border-color: transparent;
                opacity: 1;
                padding-right: 0;
                pointer-events: none;
                text-align: right;
            }
        }

        .product-item-image {
            display: none;
        }

        .product-item-description {
            float: none;
            width: 100%;
        }

        tbody {
            td {
                border-top: 1px solid $c-border;
            }
        }
    }

    .copyright {
        background-color: transparent;
        font-size: $font-size-xs;
        margin-bottom: $space-l;
        padding: $space-base 0 0;
        text-align: left;

        .phone {
            float: right;
        }
    }
}


.add_to_requisition_popup,
.add_to_stocking_popup {
    .fieldset > .field:not(.choice) {
        > .label,
        > .control {
            float: none;
            width: auto;
        }
    }
}

@media print {
    .requisition-list-requisition-print {
        .table-wrapper {
            table {
                table-layout: auto;
            }
        }
    }
}

.requisition_select--mobile {
    margin: $space-base 0 $space-s 34px;
}

@media (min-width: $bp-table-linearize) {
    .requisition_select--mobile {
        display: none;
    }
}

// ==============================================
// Quicklist View
// ==============================================

.quicklist-description {
    word-wrap: break-word;
}

.requisition-grid .ui-sortable-placeholder {
    height: 100px !important;
}

.requisition-grid thead tr {
    border-bottom: 1px solid $c-border;
}

.requisition-grid tbody tr.ui-sortable-placeholder td {
    border-top: 1px solid $c-border;
    &:first-child {
        border-top: none;
    }
}

.requisition-grid .ui-sortable-helper td {
    border-top: none;
    border-bottom: none;
}

.requisition-grid .product-item-image {
    width: 75px;
    min-width: 50px;
}

.requisition-grid .product-item-name .message {
    display: flex;
    margin-bottom: $space-base;
}

.requisition-grid .product-item-name,
.requisition-grid .product-attribute,
.requisition-grid .product-price {
    margin-left: 90px;
}

@media (min-width: $bp-table-linearize) {
    .requisition-grid .col.product {
        width: 100%;
    }
}

@media (max-width: $bp-table-linearize - 1px) {
    .requisition-grid .table--linearize {
         tr,
         th,
         td {
             display: flex;
             flex-wrap: wrap;
         }
    }

    .requisition-grid .col.select {
        width: 30px;
    }

    .requisition-grid .col.product {
        flex-wrap: nowrap;
        width: calc(100% - 30px);
    }

    .requisition-grid .col.subtotal {
        height: 58px;
    }

    .requisition-grid .col.qty {
        flex-direction: column;
        align-items: center;
        width: 120px;
    }

    .requisition-grid .product-item-name,
    .requisition-grid .product-attribute,
    .requisition-grid .product-price {
        margin-left: 0;
    }

    .requisition-grid .product-item-description {
        margin-bottom: $space-xs;
    }

    .requisition-grid .product-price {
        font-weight: $font-weight-semibold;
    }
}


// ==============================================
// Quicklists List
// ==============================================

.quicklists-list .cell-label-line-description {
    color: $c-text-muted;
}

// ==============================================
// Quicklists Actions
// ==============================================

.quicklist-clipboard {
    width: 100%;
    border: $c-border;
    background: $c-lightestgray;
    padding: $space-xs
}

.copy-items_confirm {
    width: 80px;
}

// ==============================================
// Quicklists Badges
// ==============================================

.requisition-grid .badge-group {
    margin-left: 0;
}

.requisition-grid .badge {
    margin: $space-xxs $space-xxs 0 0;
}
