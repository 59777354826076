span.MuiRadio-colorSecondary.Mui-checked {
    color: $blue-600;
}

span.MuiIconButton-colorSecondary,
span.MuiRadio-colorSecondary.Mui-checked {
    &:hover {
        background-color: rgba(51, 128, 239, 0.04);
    }
}
