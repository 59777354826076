.company-control .control > select {
    margin-top: $space-xs;
}

.company-control .control > .label {
    top: 0;
}

.company-control .control select:focus ~ label {
    color: rgba(0, 0, 0, 0.54);
}

.company-control .control {
    display: block;
}

@media (min-width: $bp-screen-m) {
    .company-control .control {
        margin-top: $space-s;
    }
}

@media (min-width: $bp-screen-m) {
    .company-control .control {
        margin-top: $space-s;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .company-control .control {
        margin-top: $space-xs;
    }
}

// ==============================================
// Account Selector
// ==============================================

@media (max-width: $bp-screen-m - 1px) {
    .menu-top .company-control {
        border-top: 1px solid $c-border;
        border-bottom: 1px solid $c-border;
        margin: 10px (-$space-base) 0;
        padding: $space-xs $space-base 2px 43px;

        .job-details > span {
            display: block;
            font-weight: $font-weight-semibold;
            margin-bottom: $space-xxs;
        }
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .company-control {
        max-width: 400px;
    }

    .menu-top .company-control {
        margin-top: $space-xxl;
    }
}


// ==============================================
// Account Selector - Block
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .company-control .block {
        margin-bottom: 0;
    }

    .menu-top > .company-control select {
        color: $c-text;
        border-bottom-color: $c-contrast-input-border-bottom;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23e9e9e9' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e");

        &:focus {
            border-bottom-color: $c-contrast-input-border-focus;
        }
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .company-control .block {
        display: flex;
        margin-bottom: 0;
    }

    .job-details {
        border-bottom: 1px solid $c-border;
        font-size: $font-size-s;
        padding: $space-s;
    }

    .job-details .block {
        display: flex;
    }

    .job-details .control {
        max-width: 48%;

        &:last-of-type {
            margin-left: auto;
        }
    }

    .checkout-index-index {
        .job-details > span {
            display: none;
        }

        .job-details .block {
            width: 100%;
        }

        .job-details {
            border-bottom: none;
            font-size: $font-size-base;
            padding: 14px $space-xxs 14px 0;
        }

        .account-dropdown .job-details {
            display: none;
        }
    }

    .menu-top {
        display: none;
    }

    .account-dropdown .fluid-list {
        margin-bottom: 0;
    }
}
