// ==============================================
// Vars
// ==============================================

$button-box-shadow:         $box-shadow-base !default;
$button-box-shadow-hover:   $box-shadow-hover !default;
$pb-button_height: 50px;

@mixin button  {
    @include button-base;

    @include button-style(
        $background         : $c-library-blue-11,
        $background--hover  : $c-library-blue-10,
        $background--active : $c-library-blue-10,
        $color              : $c-white
    );

    color: $c-white;
    box-shadow: $button-box-shadow;
    white-space: nowrap;

    &:hover, &:focus {
        background: $c-library-blue-10;
        color: $c-white;
        box-shadow: $button-box-shadow-hover;
    }
}

@mixin flat-button {
    @include button-base;

    @include button-style(
        $background         : transparent,
        $background--hover  : $peirceblue-50,
        $background--active : $peirceblue-100,
        $color              : $peirceblue-400
    );
}

@mixin button-style (
    $background         : $peirceblue-400,
    $background--hover  : $peirceblue-500,
    $background--active : $peirceblue-500,
    $color              : $c-white
) {
    background: $background;
    color: $color;

    &:hover, &:focus {
        color: $color;
        background:  $background--hover;
    }

    &:active {
        color: $color;
        background:  $background--active;
    }
}

@mixin button-base {
    height: 38px;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: $font-weight-normal;
    padding: 8px 16px;
    font-size: 14px;
    letter-spacing: $letter-spacing;
    vertical-align: middle;
    text-align: center;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    will-change: background, box-shadow;
    transition: $transition-all;

    &:hover, &:focus {
        text-decoration: none;
    }
}

@mixin button-tocart {
    @include button;

    @include button-style(
        $background         : $c-library-green-6-1,
        $background--hover  : $c-library-green-7,
        $background--active : $c-library-green-7,
        $color              : $c-white
    );

    align-items: baseline;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    height: auto;
    text-transform: none;
}

@mixin button-view-substitutes {
    @include button;

    align-items: baseline;
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
    height: auto;
    text-transform: none;
}

@mixin button-pagebuilder-primary {
    @include button;

    height: $pb-button_height;
    line-height: calc(#{$pb-button_height} - 2 * #{$space-xs});
    padding: $space-xs $space-xl;
}

@mixin button-pagebuilder-secondary {
    @include button-base;

    @include button-style (
        $background         : transparent,
        $background--hover  : $c-blue,
        $background--active : $c-blue,
        $color              : $c-blue
    );

    border: 1px solid $c-blue;
    box-shadow: $button-box-shadow;
    height: $pb-button_height;
    line-height: calc(#{$pb-button_height} - 2 * #{$space-xs});
    padding: $space-xs $space-xl;

    &:hover {
        color: $c-white;
    }
}
