$specifications-border: 1px solid $gray-50;

.data.item.content .table.additional-attributes {
    display: flex;
    flex-wrap: wrap;

    dd,
    dt {
        border-top: $specifications-border;
        font-size: $font-size-s;
        font-weight: $font-weight-semibold;
        line-height: 3;
        padding-left: $space-base;
    }

    dt {
        background: $gray-75;
    }

    .add-border-bottom {
        border-bottom: $specifications-border;
    }
}

// ----------------------------------------------
// Desktop
// ----------------------------------------------

@media (min-width: $bp-screen-l) {
    .data.item.content .table.additional-attributes {
        dt {
            width: 20%;
        }

        dd {
            width: 30%;
        }
    }
}

@media (max-width: $bp-screen-l - 1) {
    .data.item.content .table.additional-attributes {
        dd,
        dt {
            width: 25%;
        }
    }
}

@media (min-width: $bp-screen-m) {
    .data.item.content .table.additional-attributes {
        dd:nth-of-type(2n) {
            border-right: $specifications-border;
        }
    }
}

// ----------------------------------------------
// Mobile
// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .data.item.content .table.additional-attributes {
        dt {
            width: 50%;
        }

        dd {
            border-right: $specifications-border;
            width: 50%;

            &:last-of-type {
                border-bottom: $specifications-border;
            }
        }
    }
}
