$suggestions_background-color: $gray-50;
$search-form-width: 500px;

.search-suggestions-wrapper {
    margin-top: $space-s;
    position: relative;

    &:before {
        @include triangle($direction: up, $size: 8px, $color: $c-border);
        left: 20px;
        top: -7px;
        z-index: 4;
    }

    &:after {
        @include triangle($direction: up, $size: 6px, $color: $suggestions_background-color);
        left: 22px;
        top: -5px;
        z-index: 4;
    }
}

.search-suggestions {
    background: $suggestions_background-color;
    border: 1px solid $c-border;
    max-height: 40vh;
    max-width: 100%;
    overflow-y: scroll;
    position: absolute;
    width: $search-form-width;
    z-index: 4;
}

.search-suggestions-item {
    border-top: 1px solid $c-border;
    cursor: pointer;
    padding: $space-xxs;

    &:hover {
        background-color: $c-library-orange-0;
    }

    &:first-child {
        border-top: none;
    }

    .image-wrapper {
        max-width: 75px;
        min-width: 75px;
        margin-right: $space-xs;
    }
}

.search-loading {
    padding: $space-xs 0;

    .simple-loading {
        height: $font-size-xxl;
        margin: 0;
        width: $font-size-xxl;
    }
}
