// ==============================================
// Product Inventory - container
// ==============================================

.product-inventory {
    margin-top: 10px;
    font-weight: $font-weight-light;
}

.product-inventory_availability {
    color: $c-red;
    font-weight: $font-weight-normal;

    &:focus,
    &:hover {
        color: $c-red;
    }
}

.inventory .restriction-message {
    color: $c-red;
}

// ==============================================
// Inventory - stock
// ==============================================

.stock {
  margin-top: 2px;
}

.stock.available,
.stock.unavailable {
    font-weight: $font-weight-normal;
    vertical-align: top;
}

.stock.available .status {
    color: $c-inventory-available;
}

.stock.out-of-stock .status,
.stock.unavailable .status {
    color: $c-inventory-unavailable;
}

.stock.non-sellable .status,
.stock.non-sellable > span {
    color: $c-red;
}

.stock span {
    font-weight: $font-weight-normal;
}


// ==============================================
// Inventory
// ==============================================

.inventory {
    display: inline-block;
    margin: $space-base 0;
}

.product-info-main .inventory {
    height: 119px;
    width: 100%;
}

.inventory_instore {
    margin-bottom: $space-xxs;
    font-weight: $font-weight-normal;
}

.inventory_stock.available,
.inventory_stock.in-stock {
     color: $c-inventory-available;
}

.inventory_stock.unavailable,
.inventory_stock.out-of-stock{
    color: $c-inventory-unavailable;
}

.inventory_stock.non-sellable {
    color: $c-red;
}

.inventory_nearby .inventory_stock.in-stock {
    color: $c-text;
}

.inventory_store-name {
    @include lib-icon-font(
                    $icon-location,
            $_icon-font-color: $gray-700,
            $_icon-font-size: 16px,
            $_icon-font-margin: -2px 2px 0 -4px
    )
}

.inventory_nearby,
.inventory_all {
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    margin-bottom: $space-xxs;
}

.inventory_all {
    margin-bottom: $space-xxs;
}

.inventory-signout-message {
    font-size: $font-size-s;

    > a {
        color: $c-red;
        margin-right: $space-xxs;
    }
}

.price-box.price-final_price.logged-out-customer + .product-inventory.background-base {
    display: none;
}
