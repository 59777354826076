/**
* Baker Distributing
*/


// ==============================================
// Var
// ==============================================

$pager-next-color:     #929090;
$pager-previous-color: #929090;


// ==============================================
// Pages
// ==============================================

//.pages {}

.pages > .label {
    @include lib-visually-hidden();
}


// ==============================================
// Pages - Items
// ==============================================

//.pages .items {}


// ==============================================
// Pages - Item
// ==============================================

.pages .item {
    display: inline-block;
    margin: 0 $space-xxxs 0 0;
    font-size: 15px;
    line-height: 32px;
}

// ==============================================
// Pages - Button
// ==============================================

.pages nav > button.z-10 {
    color: #FFFFFF;
    background: $gray-700;
}

.pages nav > button {
    border: none;
    background: none;
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
    position: relative;
}

.pages nav > button#pagination__prev-button, .pages nav > button#pagination__next-button {
    color: $gray-700;
}

.pages nav > button > svg {
    height: 2rem;
    width: 2rem;
}

// ==============================================
// Pages - Page
// ==============================================

.pages a.page {
    display: inline-block;
    padding: 0 10px;
    color: $gray-700;
}

.pages strong.page {
    display: inline-block;
    background: $gray-700;
    font-size: 15px;
    letter-spacing: normal;
    line-height: 32px;
    color: #ffffff;
    padding: 0 10px;
}


// ==============================================
// Pages - Item Label
// ==============================================

.pages .item .label {
    @include lib-visually-hidden();
}


// ==============================================
// Pages - Action
// ==============================================

.pages .action {
    width: 34px;
}

.pages .action.previous {
    margin-right: $space-s;

    @include lib-icon-font(
        $_icon-font-content        : $icon-prev,
        $_icon-font                : $font-family-icon,
        $_icon-font-size           : 38px,
        $_icon-font-line-height    : 20px,
        $_icon-font-color          : $pager-previous-color,
        $_icon-font-vertical-align : middle
    );

    &:visited::after {
        color: $pager-previous-color;
    }

    &:active::after {
        color: $pager-previous-color;
    }
}

.pages .action.next {
    margin-left: $space-s;

    @include lib-icon-font(
        $_icon-font-content        : $icon-next,
        $_icon-font                : $font-family-icon,
        $_icon-font-size           : 38px,
        $_icon-font-line-height    : 20px,
        $_icon-font-color          : $pager-next-color,
        $_icon-font-vertical-align : middle
    );

    &:visited::after {
        color: $pager-next-color;
    }

    &:active::after {
        color: $pager-next-color;
    }
}

.pages .action.next > span,
.pages .action.previous > span {
    @include lib-visually-hidden();
}
