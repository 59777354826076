// ==============================================
// Vars
// ==============================================

$toolbar-mode-icon-font-size: 24px;
$toolbar-element-background: $c-lightestgray;


// ==============================================
// Toolbar Wrap
// ==============================================

//.toolbar-wrap {}
//.toolbar-wrap--top {}

.toolbar-wrap--bottom {
    margin-bottom: $space-l;
}

.toolbar-wrap--bottom .toolbar::after {
    @include clearfix;
}


// ==============================================
// Toolbar
// ==============================================

.toolbar {
    display: flex;
    margin-bottom: $space-base;

    .pages {
        order: 1;
    }

    .toolbar-limiter {
        margin-left: auto;
        order: 2;
    }

    .toolbar-amount {
        align-items: center;
        display: flex;
        margin-bottom: 0;
        margin-left: 50px;
        order: 3;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .toolbar {
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .pages {
            display: flex;
            width: 100%;

            > .pages-items {
                margin: 0 auto $space-base;
            }
        }

        .toolbar-limiter {
            margin-left: 0;
        }

        .toolbar-amount {
            margin-left: auto;
        }
    }
}

// ==============================================
// Toolbar Products
// ==============================================

.toolbar-products {
    overflow: hidden;
}

.toolbar-wrap--bottom .toolbar-products {
    margin-bottom: $space-l;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .toolbar-wrap--bottom .toolbar-products {
        margin-top: 10px;
    }

}

// ==============================================
// Filters Mobile Toggle
// ==============================================

.filters-mobile-toggle {
    float: right;
}

.toolbar-wrap--bottom .filters-mobile-toggle {
    display: none;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .filters-mobile-toggle {
        display: none;
    }

}

// ==============================================
// Limiter
// ==============================================

.limiter-options {
    margin: 0 5px 0 7px;
    width: auto;
}

.limiter-label {
    font-weight: 400;
}

.toolbar-limiter .control {
    display: inline-block;
}

// ==============================================
// Pages
// ==============================================

.toolbar-wrap--top .pages {
    display: none;
}

.toolbar-wrap--bottom .pages {
    display: block;
}

// ==============================================
// Toolbar Sorter
// ==============================================

.toolbar-sorter {
    float: left;
}

.toolbar-wrap--bottom .toolbar-sorter {
    display: none;
}

.sorter-options {
    margin: 0 0 0 7px;
    width: auto;
}

.sorter-action {
    padding: 7px 10px;
    border: 1px solid $gray-50;
    border-radius: $radius-base;
    vertical-align: middle;
    transition: $transition-all;

    &:hover {
        text-decoration: none;
        background: $gray-50;
        transition: $transition-all;
    }

    @include lib-icon-font(
        $icon-arrow-up,
        $_icon-font-size        : 18px,
        $_icon-font-color       : $c-primary,
        $_icon-font-color-hover : $c-primary,
        $_icon-font-position    : after,
        $_icon-font-text-hide   : true
    );

    &::after {
        margin-top: -4px;
    }
}

.sort-desc {
    &::after {
        content: $icon-arrow-down;
    }
}

// ----------------------------------------------

@media (max-width: $bp-screen-xs - 1px) {

    .sorter-label {
        display: none;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .toolbar-sorter {
        float: right;
        margin-left: 50px;
    }

}

// -----------------------------------------------

.requisition_list-requisition-index .toolbar {
    .pager {
        display: flex;
        margin-right: $space-l;
    }

    .toolbar-amount {
        margin-left: 0;
    }
}
