button.MuiButton-containedPrimary,
button.MuiButton-containedSecondary {
    border-radius: 0;
    margin: auto;
}

button.MuiButton-containedPrimary {
    background-color: $c-library-blue-11;

    &:hover {
        background-color: $c-library-blue-10;
    }
}

button.MuiButton-containedSecondary {
    background-color: $c-white;
    color: $c-primary;

    &:hover {
        background-color: $c-library-gray-1;
    }
}

button.MuiButton-root {
    font-weight: $font-weight-normal;
}
