// ==============================================
// Edit Account Form
// ==============================================

.form-edit-account .fieldset.password {
    display: none;
}

.customer-account-edit .company-input {
    position: relative;
    top: calc($space-s / 2);

    .readonly {
        border-bottom: none;
        pointer-events: none;
    }
}
