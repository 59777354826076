/**
* Baker Distributing
*/


// ==============================================
// Page Title
// ==============================================

.page-title-wrapper .page-title {
    @include h1();
    min-height: 28px;
}

.page-main > .page-title-wrapper .page-title + .action {
    margin-top: $space-xxl;
}

.app-wrapper > div > div.page-title-wrapper {
    height: auto;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .page-main > .page-title-wrapper .page-title {
        display: inline-block;
    }

    .page-main > .page-title-wrapper .page-title + .action {
        float: right;
        margin-top: $space-l;
    }
}

// ----------------------------------------------
// Back button

.page-title-wrapper .back-button {
    margin-left: -0.8rem;
    padding-top: 2px;
}
