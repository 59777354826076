// ==============================================
// Background
// ==============================================

.background-base {
    background: $c-background !important;
}

// ----------------------------------------------
// Color

.background-blue {
    background: $c-blue !important;
}

.background-gray-dark {
    background: $gray-900 !important;
}

.background-gray-light {
    background: $c-background !important;
}

.background-red {
    background: $c-red !important;
}

// ----------------------------------------------
// Image

.background-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
