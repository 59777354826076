/**
* Baker Distributing
*/


// ==============================================
// Authentication Dropdown
// ==============================================

.authentication-dropdown .modal-inner-wrap {
    padding: $space-l;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .authentication-dropdown {
        background-color: $c-white;
        border: 1px solid $c-border;
        position: absolute;
        text-align: left;
        top: 100%;
        transform: scale(1, 0);
        transform-origin: 0 0;
        transition: transform linear 0.1s, visibility 0s linear 0.1s;
        visibility: hidden;
        width: 100%;

        &._show {
            z-index: $dropdown-list__z-index;
            transform: scale(1, 1);
            transition: transform linear 0.1s, visibility 0s linear 0s;
            visibility: visible;
        }
    }

}


// ==============================================
// Authentication Wrapper
// ==============================================

.authentication-wrapper {
    display: none;
    float: right;
    margin-top: -1.5 * $space-xxxl;
    max-width: 50%;
    position: relative;
    z-index: 1;
}

._has-auth-shown .authentication-wrapper {
    z-index: $z-modal;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .authentication-wrapper {
        text-align: right;
    }

}

// ==============================================
// Block Authentication
// ==============================================

.block-authentication .block-title {
    border-bottom: 0;
    margin-bottom: $space-l;
}

.block-authentication .block-title strong {
    font-weight: $font-weight-light;
}

.block-authentication .field .label {
    font-weight: $font-weight-normal;
}

.block-authentication .actions-toolbar {
    margin-bottom: $space-xs;
}

.block-authentication .actions-toolbar > .secondary {
    padding-top: $space-l;
    text-align: left;
}

.block-authentication .block[class] {
    margin: 0;
}

.block-authentication .block[class] ul {
    list-style: none;
    padding-left: $space-base;
}

.block-authentication .block[class] .field .control,
.block-authentication .block[class] .field .label {
    float: none;
    width: auto;
}

.block-authentication .block[class] + .block {
    position: relative;
    margin-top: $space-xxl;
    padding-top: $space-xxl;
    border-top: 1px solid $c-border;

    &::before {
        content: attr(data-label);
        display: inline-block;
        position: absolute;
        top: 0;
        left: 50%;
        min-width: 36px;
        height: 36px;
        margin: (-(calc(36px/2) + 1px)) 0 0 calc(-1 * 36px / 2);
        padding: 0 0.2rem;
        border: 1px solid $c-border;
        border-radius: 50%;
        background: $c-white;
        color: $gray-300;
        letter-spacing: normal;
        line-height: 36px - 2px;
        text-align: center;
        text-transform: uppercase;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .block-authentication .block-title {
        border-bottom: 0;
        margin-bottom: $space-l;
    }

    .block-authentication .actions-toolbar > .primary {
        display: inline;
        float: right;
        margin-right: 0;
    }

    .block-authentication .actions-toolbar > .primary .action {
        margin-right: 0;
    }

    .block-authentication .actions-toolbar > .secondary {
        float: left;
        margin-right: 2rem;
        padding-top: 1rem;
    }

}


// ==============================================
// Popup Authentication
// ==============================================

@media (min-width: $bp-screen-m) {

    .popup-authentication .modal-inner-wrap {
        min-width: $bp-screen-m;
        width: 60%;
    }

    .popup-authentication .block-authentication {
        display: flex;
        flex-direction: row;
        border-top: 1px solid $c-border;
    }

    .popup-authentication .block[class],
    .popup-authentication .form-login,
    .popup-authentication .fieldset,
    .popup-authentication .block-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .popup-authentication .block[class] {
        float: left;
        padding: $space-base $space-xxl 0 0;
        width: 50%;
    }

    .popup-authentication .block[class] + .block {
        border-left: 1px solid $c-border;
        border-top: 0;
        margin: 0;
        padding: $space-base 0 0 $space-xxl;

        &::before {
            left: 0;
            top: 50%;
        }
    }

    .popup-authentication .actions-toolbar {
        margin-bottom: 0;
        margin-top: auto;
    }

}
