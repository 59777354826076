//
//  Default appearance styles
//  _____________________________________________

div[data-content-type='text'] {
    word-wrap: break-word;

    > p {
        margin-bottom: 0;
    }
}
