.std.parts {
    max-width: 100%;
}

.parts .product-items {
    list-style: none;
}

@media (min-width: $bp-screen-m) {
    .parts .product-item {
        max-width: 243px;
    }
}

.parts-app {
    select[name=models] {
        padding-left: $space-xs;
    }

    .filter-wrapper {
        padding: $space-xs;
        background: $c-library-orange-0;
        border: 1px solid $c-border;
        margin-bottom: $space-l;
        margin-top: $space-s;
    }

    .filter-wrapper .control {
        background: $c-white;
        margin: 0;
        width: 100%;

        @include lib-icon-font(
            $icon-search,
            $_icon-font-size: $font-size-xxxl,
            $_icon-font-position: before
        );

        &:before {
            color: $gray-500;
            left: $space-xxs;
            position: absolute;
            top: $space-xs;
        }
    }

    .accordion-section.MuiPaper-elevation1 {
        box-shadow: none;
    }

    .accordion-details.MuiAccordionDetails-root {
        padding: $space-base 0 0;
    }

    .accordion-summary {
        background: $gray-100;

        &:hover {
            background: $gray-200;
        }

        .products-category {
            display: inline-block;
            font-weight: $font-weight-semibold;
            min-width: 90px;
        }

        .products-count {
            margin-left: $space-base;
        }
    }

    .restriction-message {
        color: $c-red;
    }

    .summary-content {
        font-size: $font-size-base;
    }

    .heading-row,
    .part-row,
    .supersede-row {
        display: flex;
        width: 100%;
    }

    .heading-row {
        font-weight: $font-weight-semibold;
    }

    .heading-row,
    .supersede-row {
        background: $c-background;
    }

    .heading-cell,
    .part-cell,
    .supersede-cell {
        display: flex;
        justify-content: center;

        .price > small {
            display: none;
        }

        .product-inventory {
            margin: 0;
            min-width: 170px;
        }

        &.product-item-actions .p-y-xs {
            padding-bottom: 0 !important;
            padding-top: 0 !important;
        }

        &.product-item-actions .p-x-xs {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .supersede-cell {

        &.part-name a {
            color: $c-darkgray;
        }

        .input-text.qty {
            background: $c-white;
        }

        span.part-inventory {
            text-transform: uppercase;
        }
    }

    .part-cell {
        &.part-name a {
            color: $c-darkgray;
        }

        span.part-inventory {
            text-transform: uppercase;
        }
    }

    .part-replacements {
        font-weight: bold;
        margin-top: 5px;
        text-transform: uppercase;
    }

    .product-item-actions {
        .field.qty {
            display: none;
        }

        .actions {
            padding-left: 0;
        }

        .actions .action.tocart {
            background: $c-green;
        }

        .actions .action.tocart > span {
            font-weight: $font-weight-semibold;
            text-transform: none;
        }
    }

    .price {
        font-weight: $font-weight-normal;
    }

    .show-more-button {
        display: flex;
        margin-top: $space-xxl;
        margin-bottom: $space-base;
    }

    .show-more-button .MuiButton-containedPrimary {
        background-color: $c-library-blue-11;
        border-radius: 0;
        margin: auto;

        &:hover {
            background-color: $c-library-blue-10;
        }
    }

    .parts-summary {
        padding-bottom: $space-xs;
    }

    .input-text {
        border: 1px solid $c-border;
        padding: 0 $space-s 0 $space-xxl;
    }

    .input-text ~ label {
        left: $space-xxl;
    }

    .input-text:focus ~ label,
    .input-text.hasValue ~ label,
    .input-text:not(:empty) ~ label {
        left: 0;
        top: -$space-l;
    }

    .product-inventory .status:before {
        content: '';
    }

    .parts-product-data {
        line-height: 1.8;

        label {
            font-weight: $font-weight-semibold;
            display: inline-block;
            min-width: 60px;
        }

        a {
            color: $c-text;
        }
    }

    .replacement-pointer .pointer-icon {
        position: relative;
        top: 1px;
    }
}

// ----------------------------------------------
// Desktop

@media (min-width: $bp-screen-m) and (max-width: $bp-screen-l - 1px) {
    .parts-component.has-items {
        overflow-x: scroll;
    }
}

@media (min-width: $bp-screen-m) {
    .parts-component .parts-table {
        font-size: $font-size-s;
        min-width: 900px;
     }

    .parts-app {
        .heading-row {
            min-height: 30px;
            position: sticky;
            z-index: 3;
            top: 0;
        }

        .part-row,
        .supersede-row {
            border-top: 1px solid $c-border-light;
            min-height: 50px;
        }

        .supersede-row:first-child {
            border-top-color: transparent;
        }

        .part-row,
        .supersede-row {
            &:hover {
                background: $c-library-orange-0;
            }
        }

        .part-row.row-open:hover .part-cell.part-number:after {
            border-top-color: $c-library-orange-0;
        }

        .heading-cell {
            padding: $space-s $space-xs;
        }

        .part-cell,
        .supersede-cell {
            padding: $space-xxs $space-xs;
        }

        .heading-cell,
        .part-cell,
        .supersede-cell {
            flex: 1;
            flex-direction: column;

            &.part-image {
                max-width: 110px;
                min-width: 80px;
                flex: 0.8;

                .product-image {
                    min-width: 60px;
                    width: 60px;
                }
            }

            &.part-brand {
                flex: 0.8;
                min-width: 80px;
                word-break: break-word;
            }

            &.part-number {
                flex: 1.2;
                padding-left: 12px;
                min-width: 130px;
            }

            &.part-name {
                flex: 1.5;
                min-width: 115px;
                word-break: break-word;
            }

            &.part-inventory {
                flex: 1.5;
                min-width: 200px;
            }

            &.part-price {
                flex: 1;
                min-width: 95px;
            }

            &.product-item-actions {
                flex: 1;
                min-width: 175px;

                .action-message .customer-account-link {
                    color: $c-red;
                    margin-right: $space-xxs;
                }
            }

            &.content-mobile {
                display: none;
            }

            > label,
            a > label,
            span > label,
            .name-wrapper > label {
                display: none;
            }
        }

        .part-row.row-open {
            .part-cell.part-number {
                position: relative;

                &:after {
                    border: 7px solid;
                    bottom: -13px;
                    border-color: $c-white transparent transparent transparent;
                    content: '';
                    display: block;
                    height: 0;
                    left: 33%;
                    position: absolute;
                    width: 0;
                    z-index: 1;
                }
            }
        }

        .part-replacements {
            font-size: $font-size-xs;
            padding-left: $space-s;
        }

        .part-replacements.dropdown-trigger::after {
            line-height: 11px;
            font-size: $font-size-xl;
        }

        section {
            border: 1px solid $c-border-light;
            width: 100%;
        }

        .price {
            font-size: $font-size-s;
        }

        .supersede-row.replacements-loading {
            min-height: 70px;
        }
    }
}

// ----------------------------------------------
// Mobile

@media (max-width: $bp-screen-m - 1px) {
    .parts-app {
        .heading-row {
            display: none;
        }

        .part-cell,
        .supersede-cell {
            flex-direction: row;
            justify-content: left;
            margin-bottom: 2px;
            max-width: 270px;
            padding: 3px;
            width: 100%;

            &.product-item-actions .background-base {
                display: flex;
                flex-direction: column;
            }

            label {
                margin-right: 3px;
            }

            &.flex-column {
                flex-direction: column;
            }
        }

        .part-cell.part-number {
            .part-replacements {
                display: none;
            }
        }

        .part-cell.content-mobile {
            .part-replacements {
                margin-top: 15px;
            }
        }

        .part-row {
            border-bottom: 1px solid $c-border;
        }

        .part-row .old-price {
            margin-left: $space-xs;
        }

        .part-row,
        .supersede-row {
            align-items: center;
            flex-direction: column;
            padding: $space-xl 0 $space-base;
        }

        .part-row.row-open {
            position: relative;

            &:after {
                border: 7px solid;
                bottom: -14px;
                border-color: $c-white transparent transparent transparent;
                content: '';
                display: block;
                height: 0;
                left: calc((100% - 7px) / 2);
                position: absolute;
                width: 0;
                z-index: 1;
            }
        }

        .supersede-row {
            margin: 0 -20px;
            width: calc(100% + 40px);
        }

        .supersede-row:last-child {
            border-bottom: 1px solid $c-border-light;
        }

        .price {
            font-size: $font-size-base;
        }

        .product-image {
            margin: auto auto $space-base;
            max-width: 75%;
        }

        .actions-primary,
        .actions-primary .action.tocart {
            width: 100%;
        }

        .actions-primary {
            margin-top: $space-base;
        }

        section {
            width: 100%;
        }

        .part-replacements.dropdown-trigger::after {
            line-height: 19px;
            font-size: $font-size-xxl;
        }

        .supersede-row.replacements-loading {
            min-height: 150px;
        }
    }
}
