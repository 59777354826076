.s7mixedmediaviewer.s7touchinput .s7fullscreenbutton {
    height: $font-size-xxxll;
    width: $font-size-xxxll;
}

@media (max-width: $bp-screen-m - 1px) {
    .s7mixedmediaviewer.s7touchinput .s7button {
        &.s7fullscreenbutton,
        &.s7zoominbutton,
        &.s7zoomoutbutton,
        &.s7zoomresetbutton {
            height: $font-size-xxxxl;
            width: $font-size-xxxxl;
        }
    }
}
