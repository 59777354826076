// ==============================================
// Page Content Wrapper
// ==============================================

.savecart-index-index .content-wrapper {
    padding-top: $space-xs;
}

@media (max-width: $bp-screen-m - 1px) {
    #customer_saved_cart_app {
        padding: 15px;
    }
}

// ==============================================
// Save Cart Table Cell
// ==============================================

@media (max-width: $bp-table-linearize - 1px) {
    .savecart-index-index {
        .table--linearize tbody td {
            display: flex;

            > span:first-child {
                margin-right: $space-xxs;
            }
        }
    }

    .savecart-index-index,
    .savecart-view-index {
        .table--linearize tbody td {
            &.col--price {
                margin-top: $space-s;
            }
        }
    }
}

// ==============================================
// Save Cart Details Title
// ==============================================

.savecart-index-index,
.savecart-view-index {
    .page-title-wrapper {
        align-items: center;
        display: flex;

        .back-button.action-icon {
            padding-top: 0;

            > i {
                padding: 0 0.8rem;
                margin: 0;
            }
        }

        .page-title {
            margin-bottom: 0;

            .page-title--action {
                align-items: center;
                display: flex;
            }

            .page-title--action .flat-button {
                height: auto;
            }
        }
    }
}

@media (max-width: $bp-table-linearize - 1px) {
    .savecart-index-index,
    .savecart-view-index {
        .page-title-wrapper {
            margin-bottom: $space-s;
        }
    }
}

// ==============================================
// Save Cart Details Actions
// ==============================================

.savecart-index-index,
.savecart-view-index {
    .panel_actions {
        .dropdown-trigger:after {
            line-height: 21px;
        }
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .savecart-index-index,
    .savecart-view-index {
        .panel_actions {
            margin-bottom: $space-l;
            width: 100%;

            .my-lists-dropdown,
            .my-lists-dropdown-content {
                width: 100%;
            }
        }
    }
}

// ==============================================
// Save Cart Loader
// ==============================================

.save-cart-loader {
    .simple-loading {
        margin-left: $space-l;
        height: $font-size-xl;
        width: $font-size-xl;
    }
}

// Table columns styling.

@media (min-width: $bp-screen-s) {
    .savecart-view-index {
        .col.col--price {
            padding: $space-s 0;
            min-width: 120px;
        }
    }
}
