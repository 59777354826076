$pill-button-z: 0;
$pill-button-highlight-z: 1;
$pill-button-selection-z: 2;
$pill-button_background-color: $c-white;
$pill-button-active_background-color: $c-green;
$pill-button_text-color: $c-text;
$pill-button-active_text-color: $c-white;

.pill-button {
    background: $pill-button_background-color;
    border-radius: 13px;
    box-shadow: 0 3px 3px $gray-200;
    color: $pill-button_text-color;
    display: inline-block;
    position: relative;
    z-index: $pill-button-z;

    &-highlight {
        background: $pill-button-active_background-color;
        border-radius: 13px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        transition: all 0.2s;
        z-index: $pill-button-highlight-z;
    }

    &-selection {
        cursor: pointer;
        display: inline-block;
        padding: $space-xxs $space-base;
        position: relative;
        transition: all 0.2s;
        z-index: $pill-button-selection-z;

        &_active {
            background: transparent;
            color: $pill-button-active_text-color;
            display: inline-block;
        }
    }
}
