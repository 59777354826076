.wingman-index-index,
.wingman-case-id {
    .content-wrapper {
        padding-top: $space-xs;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    #wingman_app {
        padding: 0 15px;

        .toolbar.toolbar--bottom {
            padding: 0 15px;
        }
    }
}

@media (max-width: $bp-screen-s - 1px) {
    #wingman_app {
        .field-row--stack .field:last-child {
            margin-bottom: $space-s;
        }
    }
}

