$finder-form-width: 500px;

.bom-form-field {
    max-width: 100%;
    width: $finder-form-width;
}

.bom-suggestions-wrapper .search-suggestions-item {
    padding: $space-s $space-base;
}

.bom-loading {
    padding: $space-xs 0;

    .simple-loading {
        height: $font-size-xxl;
        margin: 0;
        width: $font-size-xxl;
    }

    &-text {
        margin-left: $space-s;
    }
}

.bom-page {
    .products-list .product-item {
        border: none;
        display: flex;
        padding: 0;

        .product-item-name {
            margin: $space-xs 0;
        }
    }

    .products-list .actions-primary {
        flex-basis: auto;
    }

    .price-message > a {
        margin-right: $space-xxs;
    }

    .actions-primary {
        margin-right: $space-xl;
    }

    .parts-app,
    .product-documents-app {
        margin: 0 $space-base;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .bom-page {
        .parts-app {
            margin: 0 $space-s;
        }

        .parts-app .supersede-row {
            margin: 0 (-$space-s);
            width: calc(100% + 2 * #{$space-s});
        }
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .bom-page {
        .MuiTabs-flexContainer .MuiTab-root {
            flex: 1;
        }
    }

    .bom-page .products-list .product-item {
        align-items: center;
        flex-direction: column;

        .product-item-details {
            margin-top: $space-base;
            width: 100%;
        }

        .product-item-photo {
            height: auto;
            padding-right: 0;

            img {
                width: 240px;
            }
        }

        .product-item-npm {
            width: 100%;
        }

        .actions-primary,
        .actions-secondary {
            flex-basis: unset;
            margin: auto;
            text-align: center;
            width: 100%;
        }

        .actions-secondary .block-requisition-list {
            margin: $space-xxs auto;
        }
    }
}
