// Subcategories selection block.

.page-products .subcategories-app .slick-slider {
    padding-left: 28px;
    padding-right: 28px;

    .slick-prev {
        left: 0;
    }

    .slick-next {
        right: 0;
    }

    .slick-prev,
    .slick-next {
        &.slick-disabled {
            display: none !important;
        }
    }

    .slick-list {
        width: 100% !important;
    }

    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        display: flex;
        flex-direction: column;
        height: inherit !important;

        > div,
        > div > div {
            display: flex;
            flex-direction: column;
            flex: 1;
        }
    }
}
