.erporder-history-index {
    .action-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 2rem;
    }

    .action-icon > i {
        padding: 0 0.5rem 0 0;
        margin: 0;
        line-height: 2rem;
    }

    .columns-configuration-label {
        display: inline-block;
        line-height: 2rem;
        padding-right: 0.4rem;
    }

    /* Fix default blue link color when clicking outside of dropdown to close */
    a.action-icon:focus {
        color: $c-icon-base;
    }

    a.action-icon:hover {
        text-decoration: none;
        color: $c-black
    }
}

.erporder-details-view {
    .line-item-image {
        max-width: 100px;
        margin-right: $space-s;
    }
}

// Search Form Input Fields.

.erporder-history-index .input-text--xs {
    &[name=po_num],
    &[name=order_id] {
        max-width: 100%;
    }
}

@media print {
    .erporder-details-view {
        .widget.block {
            display: none;
        }

        .actions-toolbar.order-actions-toolbar {
            display: none;
        }
    }
}
