// -------------------------------------
// Quotes toolbar.
// -------------------------------------
@media (max-width: $bp-screen-m - 1px) {
    .quotes-index-index .toolbar {
        padding: 0 $space-base;
    }
}

// -------------------------------------
// Quote Details Page Title.
// -------------------------------------
.quotes-view-index .page-title-wrapper,
.quotes-index-index .page-title-wrapper {
    align-items: center;
    display: flex;
}

// -------------------------------------
// Quote Submit Form.
// -------------------------------------
.submit-quote-form .simple-loading {
    margin-left: $space-base;
}

@media (max-width: $bp-screen-m - 1px) {
    .quote-table-wrapper {
        padding-left: $space-base;
        padding-right: $space-base;
    }

    .quote-table-wrapper.table-scroll-outer-wrap .table-scroll-wrap--visible:after {
        right: 21px;
    }
}
