// ==============================================
// Breakpoint
// ==============================================

// Breakpoints are always defined as "min-width" values.
// Write "($bp-name - 1px)" to shield a "max-width" query.

$bp-screen-xxl: 1600px;
$bp-screen-xl:  1440px;
$bp-screen-l:   1280px;
$bp-screen-m:   960px;
$bp-screen-md:  768px;
$bp-table-view: 700px;
$bp-screen-s:   600px;
$bp-screen-xs:  480px;
$bp-screen-xxs: 320px;
