// ==============================================
// Current Store
// ==============================================

.header-store {
    display: flex;
    order: 8;
    align-items: center;
    margin: 0 0 0 $space-xxl;
    color: $c-header-text-color;
}

.header-store_toggle.dropdown-trigger {
    display: flex;

    > p.small {
        margin-right: $space-xs;
    }

    .header-store_name {
        font-weight: $font-weight-bold;
    }
}

.header-store_toggle.dropdown-trigger.active {
    display: flex;
}

.header-store_dropdown.dropdown-content {
    margin-top: $space-xs;
}

.header-store_dropdown {
    .button {
        background: $c-library-blue-11;
        border-radius: 0;
        text-transform: capitalize;

        &:hover {
            background: $c-library-blue-10;
        }
    }

    .store-address {
        margin-bottom: $space-xs;
    }

    .store-name {
        align-items: baseline;
        border-bottom: 1px solid $c-border;
        display: flex;

        .store-hours {
            margin-left: auto;
        }
    }

    .store-distance {
        padding-bottom: 0;
    }

    .action-group {
        margin-top: 0;
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store {
        margin: 0;
        width: auto;
    }
}

.header-store_wrapper {
    margin: 0;
}


// ==============================================
// Current Store - Container
// ==============================================

.header-store_container {
    width: auto;
    display: block;
    background: transparent;
    border: none;
}


// ==============================================
// Current Store - Toggle
// ==============================================

.header-store_toggle {
    width: 100%;
    position: relative;
    padding: 0 22px 0 40px;
}

@media (max-width: $bp-screen-m - 1px) {

    .header-store_toggle {
        width: 100%;
        position: relative;
        margin-right: $space-xs;
        padding: 0;

        @include lib-icon-font(
                $_icon-font             : $font-family-icon-outlined,
                $_icon-font-content     : $icon-location,
                $_icon-font-size        : 32px,
                $_icon-font-line-height : 34px,
                $_icon-font-text-hide   : false,
                $_icon-font-position    : before,
                $_icon-font-display     : block,
                $_icon-font-color       : $c-library-blue-11
        );
    }

    .header-store_container .dropdown-trigger:after {
        content: none;
    }

    .header-store_toggle.dropdown-trigger.active:after {
        display: none;
    }

    .header-store_dropdown {
        top: 21px
    }
}

.header-store_toggle p {
    margin: 0;
    font-weight: 400;
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store_toggle p {
        display: none;
    }
}

@media (max-width: $bp-screen-m - 1px) {

    .header-store_toggle::before {
        left: auto;
        top: -15px;
    }

}

.header-store_toggle::after {
    position: absolute;
    right: 0;
}

@media (min-width: $bp-screen-m) {
    .header-store_toggle.dropdown-trigger:after,
    .header-store_toggle.dropdown-trigger.active:after {
        font-size: $font-size-xl;
        line-height: $font-size-xl;
    }
}

// ==============================================
// Current Store - Dropdown
// ==============================================

.header-store_dropdown {
    display: none;
    padding: 20px;
    color: $c-text;
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store_dropdown {
        right: 0;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .header-store_wrapper .dropdown {
        position: static;
    }

    .header-store_wrapper .dropdown-content {
        width: 100%;
        left: 0;
        right: 0;
        top: 44px;
    }
}

.header-store_container.active .header-store_dropdown {
    display: block;
}

.header-store_dropdown.dropdown-content--right {
    right: -24px;
}

.header-store_dropdown::before {
    @include triangle($direction: up, $size: 8px, $color: $c-border);
    top: -8px;
    right: 24px;
}

.header-store_dropdown::after {
    @include triangle($direction: up, $size: 6px, $color: $c-white);
    top: -6px;
    right: 26px;
}

@media (max-width: $bp-screen-m - 1px) {
    .header-store_dropdown::after,
    .header-store_dropdown::before {
        border: none;
    }
}

// ==============================================
// Current Store - Block
// ==============================================

@media (min-width: $bp-screen-m) {
    .header.content .header-store {
        display: none;
    }
}

// ==============================================
// Current Store - Name
// ==============================================

@media (min-width: $bp-screen-m) {
    .store-name {
        white-space: nowrap;
    }
}


// ==============================================
// Current Store - Hours
// ==============================================

.store-hours {
    padding: 10px 0;
}

.store-hours .open {
    color: $green-500;
}

.store-hours .closed {
    color: $gray-700;
}


// ==============================================
// Current Store - Distance
// ==============================================

.store-distance {
    padding-bottom: $space-base;
}


// ==============================================
// Current Store - Address
// ==============================================

.store-address {
    padding: 10px 0 0;
}
