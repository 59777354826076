// ==============================================
// Sortable
// ==============================================

.ui-sortable-helper {
    background: $c-white;
    box-shadow: $box-shadow-thin;
    height: auto !important;
}

$sort-arrow-default_color: $gray-300;
$sort-arrow-active_color: $c-text;
$sort-arrow-hover_color: $gray-650;
$sortable-arrow_font-size: $font-size-xxl;

.sortable {
    cursor: pointer;
    line-height: $sortable-arrow_font-size;
    position: relative;

    @include lib-button-icon(
        $icon-menu-up,
        $_icon-font-color       : $sort-arrow-default_color,
        $_icon-font-color-hover : $sort-arrow-hover_color,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 (-$space-xxs) 0 0,
        $_icon-font-size        : $sortable-arrow_font-size,
        $_icon-font-line-height : $sortable-arrow_font-size,
        $_icon-font-text-hide   : true
    );

    @include lib-button-icon(
        $icon-menu-down,
        $_icon-font-color       : $sort-arrow-default_color,
        $_icon-font-color-hover : $sort-arrow-hover_color,
        $_icon-font-vertical-align : sub,
        $_icon-font-margin      : 0 (-$space-xxs) 0 0,
        $_icon-font-size        : $sortable-arrow_font-size,
        $_icon-font-line-height : $sortable-arrow_font-size,
        $_icon-font-text-hide   : true,
        $_icon-font-position    : after
    );

    &:before,
    &:after {
        position: absolute;
        right: -$space-l;
    }

    &:after {
        bottom: -$space-xxs;
    }

    &:before {
        top: -$space-xxs;
    }
}

.sortable-default-down {
    @extend .sortable;

    &:hover {
        &:before {
            color: $sort-arrow-default_color;
        }

        &:after {
            color: $sort-arrow-hover_color;
        }
    }
}

.sortable-default-up {
    @extend .sortable;

    &:hover {
        &:before {
            color: $sort-arrow-hover_color;
        }

        &:after {
            color: $sort-arrow-default_color;
        }
    }
}

.sortable-up {
    @extend .sortable;

    &:before {
        color: $sort-arrow-active_color;
    }

    &:hover {
        &:after {
            color: $sort-arrow-hover_color;
        }

        &:before {
            color: $sort-arrow-active_color;
        }
    }
}

.sortable-down {
    @extend .sortable;

    &:after {
        color: $sort-arrow-active_color;
    }

    &:hover {
        &:after {
            color: $sort-arrow-active_color;
        }

        &:before {
            color: $sort-arrow-hover_color;
        }
    }
}
