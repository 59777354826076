/**
* Baker Distributing
*/


// ==============================================
// Logo Wrapper
// ==============================================

.logo-wrapper {
    z-index: 5;
}

// ==============================================
// Logo
// ==============================================

.logo img {
    display: block;
}


// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .logo-wrapper {
        left: $space-base;
        margin: 0 auto;
        position: relative;
    }

    .header-logo_baker {
        width: auto;
        height: 50px;
    }

    .header-logo_ice-design,
    .header-logo_baker-fcs {
        max-width: 120px;
        height: 32px;
    }
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .logo-wrapper {
        order: 2;
        flex-grow: 1;
        text-align: left;
    }

    .header-logo_baker {
        width: 165px;
        height: auto;
    }

    .header-logo_baker-fcs {
        width: 165px;
        height: auto;
    }

    .header-logo_ice-design {
        width: 154px;
        height: 40px;
    }
}
