/**
* Baker Distributing
*/


// ==============================================
// Vars
// ==============================================

$account-nav-background: $gray-900;
$account-nav-width:      256px;

$account-nav-icon-size: 24px;

$account-nav-item-background-hover: $gray-800;
$account-nav-item-background-focus: $gray-800;
$account-nav-item-border:           $gray-800;
$account-nav-item-color:            $c-white;
$account-nav-item-color-focus:      $c-white;
$account-nav-item-color-hover:      $c-white;

$account-nav-item-current-background: $gray-800;

$account-nav-sub-item-color:       $c-white;
$account-nav-sub-item-color-focus: $gray-200;
$account-nav-sub-item-color-hover: $c-white;

$account-nav-sub-item-current-color: $c-white;

$account-nav-item-current-background: $gray-800;


// ==============================================
// Account Nav
// ==============================================

.account-nav {
    width: $account-nav-width;
    margin-bottom: 0;
}

.account-nav .title {
    display: none;
}


// ==============================================
// Account Nav - Item
// ==============================================

.account-nav_item {
    border-bottom: 1px solid $account-nav-item-border;
    color: $account-nav-item-color;
    transition: background-color 0.1s linear;

    &:last-child {
        border-bottom: 0;
    }
}


.account-nav_item .flag {
    margin-left: $space-xs;
    position: absolute;
}

// ----------------------------------------------
// Modifier: Current

.account-nav_item--current {
    background: $account-nav-item-current-background;
    color: $c-white;

    &::before {
        color: $c-white;
    }
}


// ==============================================
// Account Nav - Link
// ==============================================

.account-nav_link {
    display: block;
    color: $account-nav-item-color;
    text-decoration: none;

    &:focus {
        background-color: $account-nav-item-background-focus;
        color: $account-nav-item-color-focus;
    }

    &:hover {
        background-color: $account-nav-item-background-hover;
        color: $account-nav-item-color-hover;
    }
}

// ----------------------------------------------
// Parent Modifier: Current

.account-nav_item--current .account-nav_link {
    color: $account-nav-item-color-hover;

    &:hover {
        background-color: $account-nav-item-background-hover;
        transition: background-color 0.1s linear;
    }
}


// ==============================================
// Account Nav - Label
// ==============================================

.account-nav_label {
    padding: $space-xxs $space-base;

    &::before {
        padding-right: 10px;
    }
}


// ==============================================
// Account Nav - Dropdown
// ==============================================

.account-nav_dropdown {
    margin-bottom: $space-s;
}


// ==============================================
// Account Nav - Dropdown Item
// ==============================================

.account-nav_dropdown a,
.account-nav_dropdown strong {
    display: block;
    padding: 10px 0 10px 53px;
    line-height: $line-height-xs;
    text-decoration: none;
}

.account-nav_dropdown a {
    color: $account-nav-sub-item-color;

    &:focus {
        color: $account-nav-sub-item-color-focus;
    }

    &:hover {
        background: $account-nav-item-background-hover;
        color: $account-nav-sub-item-color-hover;
    }
}

.account-nav_dropdown strong {
    color: $account-nav-sub-item-current-color;
    font-weight: normal;
    background: $account-nav-item-current-background;
}


// ==============================================
// Icons
// ==============================================

// Dashboard
.account-nav_item.dashboard span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-house,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// Quotes
.account-nav_item.quotes span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-quotes,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// Orders
.account-nav_item.orders span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-orders,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// Requisition
.account-nav_item.requisition span,
.account-nav_item.buying-tools span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-quicklists,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// Stocking Lists
.account-nav_item.stocking-list span {
    display:  $icon-font__display;
    text-decoration: none;

    &:before {
        margin-right: 10px;
        padding-right: 0;
        display: $icon-font__display;
        content: "";
        background-repeat: no-repeat;
        background-image: $icon-qr-code;
        background-size: $account-nav-icon-size;
        width: $account-nav-icon-size;
        height: $account-nav-icon-size;
        line-height: $account-nav-icon-size;
        font-size: $account-nav-icon-size;
        vertical-align: $icon-font__vertical-align;
        overflow: hidden;
    }
}

// Invoices
.account-nav_item.invoices span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-invoices,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// Company
.account-nav_item.company span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-company,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// My Account
.account-nav_item.my-account span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-users,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

// Resources
.account-nav_item.tools span {
    @include lib-icon-font(
        $_icon-font-content:      $icon-build,
        $_icon-font-size:         $account-nav-icon-size,
        $_icon-font-line-height:  $account-nav-icon-size,
        $_icon-font-color:        $account-nav-item-color,
        $_icon-font-color-hover:  $account-nav-item-color-hover,
        $_icon-font-color-active: $account-nav-item-color
    );
}

.account-nav {
    .account-nav_item {
        padding: 0;
    }

    .account-nav_link {
        margin: 10px 0;
        padding: calc($space-s / 2) $space-base;

        > .account-nav_label {
            margin: 0;
            padding: 0;
        }
    }

    .account-nav_label {
        display: block;
        margin: $space-s 0;
        padding: $space-xxs $space-base;
        width: 100%;
    }

    .account-nav_item span::before {
        bottom: 2px;
        position: relative;
    }

    .nav.item {
        padding: $space-xxxs 0;
    }

    .account-nav_item.external .account-nav_link,
    .nav.item .external {
        position: relative;
        width: 100%;

        @include lib-icon-font(
            $_icon-font-content:      $icon-open-external,
            $_icon-font-size:         $font-size-xl,
            $_icon-font-line-height:  $account-nav-icon-size,
            $_icon-font-color:        $account-nav-item-color,
            $_icon-font-color-hover:  $account-nav-item-color-hover,
            $_icon-font-color-active: $account-nav-item-color,
            $_icon-font-position:     "after"
        );

        &:after {
            bottom: $space-xs;
            position: absolute;
            right: $space-l;
        }
    }

    .account-nav_dropdown {
        border-top: 1px solid $account-nav-item-border;
        padding-top: $space-s;
    }
}

// Dashboard / Resources
.technical-guides-frame {
    height: 100%;
    width: 100%;
}
