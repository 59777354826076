
// ==============================================
// Border
// ==============================================

.b-base { border: 1px solid $c-border !important; }

.b-t-base { border-top: 1px solid $c-border !important; }
.b-b-base { border-bottom: 1px solid $c-border !important; }
.b-r-base { border-right: 1px solid $c-border !important; }
.b-l-base { border-left: 1px solid $c-border !important; }

.b-0 { border: 0 !important; }

.b-t-0 { border-top:    0 !important; }
.b-r-0 { border-right:  0 !important; }
.b-b-0 { border-bottom: 0 !important; }
.b-l-0 { border-left:   0 !important; }

.b-base-hover {
    &:hover {
        border-color: $c-border-light !important;
    }

    &:active {
        border-color: $c-overlay !important;
    }
}
