.data-loading {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    .simple-loading {
        margin: $space-xs;
    }
}

.product-documents-app {
    .parts-summary {
        padding-bottom: $space-xs;
    }

    .filter-wrapper {
        padding: $space-xs;
        background: $c-library-orange-0;
        border: 1px solid $c-border;
        margin-bottom: $space-l;
        margin-top: $space-s;
    }

    .filter-wrapper .control {
        background: $c-white;
        margin: 0;
        width: 100%;

        @include lib-icon-font(
                $icon-search,
            $_icon-font-size: $font-size-xxxl,
            $_icon-font-position: before
        );

        &:before {
            color: $gray-500;
            left: $space-xxs;
            position: absolute;
            top: $space-xs;
        }
    }

    .documents-summary {
        padding-bottom: $space-xs;
    }

    .input-text {
        border: 1px solid $c-border;
        padding: 0 $space-s 0 $space-xxl;
    }

    .input-text ~ label {
        left: $space-xxl;
    }

    .input-text:focus ~ label,
    .input-text.hasValue ~ label,
    .input-text:not(:empty) ~ label {
        left: 0;
        top: -$space-l;
    }

    .model-col {
        max-width: 250px;
        min-width: 150px;
        width: 20%;
    }

    .accordion-section.MuiPaper-elevation1 {
        box-shadow: none;
    }

    .accordion-details.MuiAccordionDetails-root {
        padding: $space-base 0 0;
    }

    .accordion-summary {
        background: $gray-100;

        &:hover {
            background: $gray-200;
        }

        .documents-category {
            display: inline-block;
            font-weight: $font-weight-semibold;
            min-width: 150px;
        }

        .documents-count {
            margin-left: $space-base;
        }
    }

    .summary-content {
        font-size: $font-size-base;
    }
}


// ----------------------------------------------
// Mobile
// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .product-documents-app {
        .summary-content {
            display: flex;
            flex-direction: column;
        }

        .accordion-summary .documents-count {
            margin-left: 0;
        }
    }
}
