.form-stocking-list-settings {
    textarea.input-text:focus ~ label,
    textarea.input-text.hasValue ~ label {
        font-size: $font-size-s;
        left: $space-xxsl;
        padding: $space-xxs;
        top: -$space-s;
        width: auto;
    }

    textarea.input-text:focus ~ label {
        color: $c-primary-dark;
    }
}

#stocking-list-typeahead .algolia-search-bar #stocking-list-search-input {
    height: auto;
}
