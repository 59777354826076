.pp-zendesk-app {
    z-index: 15;
}

.pp-zendesk-area {
    bottom: $space-l;
    position: fixed;
    right: $space-s;
    z-index: 2;

    .action.primary {
        border: 1px solid $c-white;
        border-radius: 50%;
        height: 64px;
        width: 64px;

        &:active {
            background: $c-library-blue-6;
        }
    }

    .pp-zendesk-icon {
        width: 100%;
    }

    .pp-zendesk-close {
        font-size: 28px;
        line-height: 1.5;
    }

    .pp-zendesk-window-close {
        border-radius: 50%;
        cursor: pointer;
        margin-left: auto;
        padding: $space-xxs;

        &:hover {
            background: $c-library-blue-10;
        }

        &:active {
            background: $c-library-blue-11;
        }
    }

    .pp-zendesk-chat {
        background: white;
        box-shadow: $box-shadow-base;
        bottom: 75px;
        min-width: 350px;
        position: absolute;
        right: 0;
        z-index: 2;

        &-header {
            align-items: center;
            background: $c-library-blue-11;
            color: $c-white;
            display: flex;
            font-size: $font-size-xl;
            font-weight: $font-weight-semibold;
            height: 64px;
            padding: $space-xl $space-l $space-xl $space-xl;
        }

        &-content {
            min-height: 350px;
            padding: $space-xl;
        }
    }
}

#launcher {
    @extend .hide-print;
}
