// ==============================================
// Block Search
// ==============================================

.block-search {
    order: 5;
    flex-grow: 20;
    margin-bottom: 0;
}

.block-search .block-title {
    display: none;
}

.block-search .block-content {
    margin-bottom: 0;
    padding-bottom: $space-xs;
}

.block-search .control {
    clear: both;
    border-top: 0;
    margin: 0;
}

.block-search .nested {
    display: block;
    padding-top: 5px;
    position: absolute;
}

.block-search .field {
    margin: 0;
}

.block-search input {
    border: 1px solid $c-border;
    background: $c-white;
    position: static;
    height: 48px;
    margin: 0;
    padding-right: 85px;
    padding-left: 8px;
    width: 100%;
    transition: 0.2s ease all;

    @include placeholder();
}

.block-search input:focus {
    border-bottom-color: $c-input-border-focus;
}

.block-search .action.search {
    position: relative;
    display: inline-block;
    float: right;
    padding: 5px 8px;
}

.block-search .action.search {
    height: 40px;
    width: 70px;

    @include lib-button-icon(
        $_icon-font-content: $icon-search,
        $_icon-font-text-hide: true,
        $_icon-font-color: $c-white,
        $_icon-font-color-hover: $c-white,
        $_icon-font-color-active: $c-white,
        $_icon-font-size: 26px,
        $_icon-font-line-height: 26px
    );
    @include lib-button-reset();
    background: $c-library-blue-11;

    &:hover {
        background: $c-library-blue-10;
    }
}

@media (min-width: $bp-screen-m) {
    .block-search .action.search {
        height: 48px;
    }
}

.search-autocomplete {
    max-width: 100%;
}

.search-autocomplete .restriction-message-block {
    color: $c-red;
    display: flex;
    font-size: $font-size-s;
    font-weight: $font-weight-semibold;
    flex-direction: column;
    justify-content: center;
    max-width: 25%;
    padding: $space-xs;
    text-align: center;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {
    .form.minisearch {
        margin-top: $space-s;
    }

    .block-search {
        left: 0;
        width: 100%;
        margin: $space-xs 0;
    }

    .block-search .block-content {
        margin-bottom: 0;
    }

    .block-search input {
        padding-right: 60px;
        height: 42px;
    }

    .block-search .action.search {
        width: 50px;

        &::before {
            top: 5px;
        }
    }

    .block-search .control {
        padding: 0;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {
    .block-search {
        z-index: 110; // [todo] z-index
    }

    .block-search .control {
        padding: 0 0 10px;
    }
}


// ==============================================
// Search Autocomplete
// ==============================================

.search-autocomplete {
    position: absolute;
    z-index: 100; // [todo] z-index
    overflow: hidden;
    box-shadow: 0 3px 3px black(0.15); // [todo] use default
}

.search-autocomplete ul {
    overflow: auto;
    max-height: 400px;
    border: 1px solid $c-border;
    background: $c-white;
}

// ----------------------------------------------

@media (max-width: $bp-screen-m - 1px) {

    .search-autocomplete ul {
        margin-top: -15px;
        margin-bottom: $space-base;
        border: 0;
        border-top: 1px solid $c-border;
        border-bottom: 1px solid $c-border;
    }

}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    .search-autocomplete {
        margin-top: 0;
    }

}


// ==============================================
// Typeahead Result
// ==============================================

.typeahead-result {
    margin: 0;

    &:hover {
        background: $blue-50;
    }
}

.typeahead-result a {
    align-items: center;
    color: $c-text;
    display: flex;
    flex: 1;
    padding: $space-xs $space-base;
    text-decoration: none;

    &:hover,
    &.ui-state-focus {
        background: $blue-50;
        color: $c-text;
        text-decoration: none;
    }
}

.typeahead-result_category a {
    padding-left: $space-xxl;
    color: $c-link
}

.typeahead-result_product {
    border-top: 1px solid $c-border-light;
    display: flex;
    justify-content: center;
}

.typeahead-result_image {
    margin-right: 10px;
    min-width: 24px;
    width: 24px;
    height: 24px;
}

.typeahead-result .button.tocart {
    @include button-tocart;

    > span {
        pointer-events: none;
    }
}

.typeahead-result_actions {
    margin: auto $space-base auto auto;
}


// ==============================================
// Search . Summary
// ==============================================

// [todo] where is this used?
.search.summary {
    margin-bottom: $space-base;
}
